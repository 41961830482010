import React from "react";
import { DescriptionCard } from "../DescriptionCard";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

export type DescriptionCardsProps = {
  cards?: Array<typeof DescriptionCard | JSX.Element>;
  showPaddingTopContainer?: boolean;
  showPaddingBottomContainer?: boolean;
};

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme) => ({
    cardContainer: {
      flex: "0 1 400px",
    },
    descriptionCardsRoot: {
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0
      ),
      "@media (max-width:600px)": {
        marginLeft: -theme.spacing(2),
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0
        ),
      },
    },
  }))();

export const DescriptionCards: React.FC<DescriptionCardsProps> = ({
  cards = [],
  showPaddingTopContainer = false,
  showPaddingBottomContainer = false,
}) => {
  const classes = useStyles(
    showPaddingTopContainer,
    showPaddingBottomContainer
  );

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      data-testid="descriptionCardsRoot"
      className={classes.descriptionCardsRoot}
    >
      {cards.map((card, i) => (
        <Grid key={i} item className={classes.cardContainer}>
          <>{card}</>
        </Grid>
      ))}
    </Grid>
  );
};
