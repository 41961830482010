import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Link } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    textDecoration: "underline",
    lineHeight: "21px",
    display: "inline-flex",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:focus, &:active": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[700],
    },
    "& span": {
      display: "flex",
    },
    "& a": {
      fontFamily: theme.typography.subtitle1.fontFamily,
      display: "flex",
      alignItems: "center",
      "& svg": {
        width: "0.8em",
        height: "1em",
        marginLeft: 3,
        "& path": {
          fill: "currentcolor !important",
        },
      },
    },
  },
  secondaryLink: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.subtitle1.fontFamily,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:focus, &:active": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.grey[700],
    },
  },
  inherit: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.subtitle1.fontFamily,
    "&:hover": {
      color: theme.palette.common.white,
    },
    "&:focus, &:active": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  disabled: {
    opacity: 0.3,
    cursor: "not-allowed",
    "&:hover, &:focus, &:active": {
      color: theme.palette.text.primary,
    },
    "&$secondaryLink": {
      "&:hover, &:focus, &:active": {
        color: theme.palette.secondary.main,
      },
    },
  },
  underlineNone: {
    textDecoration: "none",
    "&:hover, &:focus, &:active": {
      textDecoration: "none",
    },
  },
}));

export type Colors = "primary" | "secondary" | "inherit";
export type Underlines = "primary" | "secondary";

export type FHLinkProps = {
  color?: Colors;
  URL?: string;
  title: string;
  hasDefaultIcon?: boolean;
  disabled?: boolean;
  underlineNone?: boolean;
  onLinkClick?: () => void;
  isBold?: boolean;
  className?: string;
  customIcon?: JSX.Element;
};

export const FHLink: React.FC<PropsWithChildren<FHLinkProps>> = ({
  color = "primary",
  URL = "#",
  title,
  hasDefaultIcon,
  disabled,
  underlineNone,
  onLinkClick,
  isBold,
  className,
  customIcon,
}: PropsWithChildren<FHLinkProps>) => {
  const classes = useStyles();

  return (
    <Link
      href={URL !== "#" && URL.length > 1 ? URL : "javascript: void(0)"}
      className={classNames(className, classes.root, {
        [classes.secondaryLink]: color === "secondary",
        [classes.inherit]: color === "inherit",
        [classes.disabled]: disabled,
        [classes.underlineNone]: underlineNone,
      })}
      color={color}
      data-testid="fhLink"
      onClick={onLinkClick}
    >
      <a>
        <span>{isBold ? <strong>{title}</strong> : title}</span>
        {!!customIcon ? (
          <span>{customIcon}</span>
        ) : (
          hasDefaultIcon && (
            <span>
              <ChevronRightIcon />
            </span>
          )
        )}
      </a>
    </Link>
  );
};
export default FHLink;
