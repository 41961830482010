import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FullWidthDivider from "../FullWidthDivider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    minHeight: "100vh",
  },
  container: {
    textAlign: "center",
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    marginBottom: theme.spacing(3),
  },
  button: {
    maxWidth: theme.spacing(35),
    width: "100%",
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.h4.fontSize,
  },
  error: {
    marginTop: theme.spacing(8.5),
    color: theme.palette.action.active,
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
}));

export type NotFound404Props = {
  Logo?: React.FC;
  title: string;
  subtitle: string;
  buttonText: string;
  buttonUrl: string | undefined;
};

export const NotFound404: React.FC<NotFound404Props> = ({
  Logo,
  subtitle,
  title,
  buttonText,
  buttonUrl,
}: PropsWithChildren<NotFound404Props>) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <Grid item>{Logo && <Logo />}</Grid>

        <FullWidthDivider className={classes.divider}></FullWidthDivider>

        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>

        <Typography className={classes.subtitle}>{subtitle}</Typography>

        <Button
          variant="contained"
          href={buttonUrl}
          color="secondary"
          className={classes.button}
        >
          {buttonText}
        </Button>

        <FullWidthDivider className={classes.divider}></FullWidthDivider>
      </Container>
    </div>
  );
};
