import React from "react";

import { pickValuesAsString, pickValuesAsBoolean } from "../../utils/common";
import { AbTestIdAndPaddingContainer } from "../../utils/AbTestIdAndPaddingContainer";
import { withContent } from "../../utils/TemplateWithContent";
import { DynamicFeefoServiceReviewComponent } from "./DynamicComponents";
import { FeefoServiceReviewEntity } from "src/graphql/generated-strapi/types";

const makeCmsData = (content: any) => ({
  ...pickValuesAsString(content, ["ABTestId"]),
  ...pickValuesAsBoolean(content, ["showPaddingTop", "showPaddingBottom"]),
});

export type CmsData = ReturnType<typeof makeCmsData>;

export const FeefoServiceReviewComponent: React.FC<FeefoServiceReviewEntity> = (
  props: FeefoServiceReviewEntity
): JSX.Element => {
  const cmsLabels: CmsData = makeCmsData(props?.attributes);

  return (
    <AbTestIdAndPaddingContainer
      showPaddingBottom={cmsLabels?.showPaddingBottom || false}
      showPaddingTop={cmsLabels?.showPaddingTop || false}
      abTestId={cmsLabels.ABTestId || ""}
    >
      <div
        id="feefo-service-review-widgetId"
        className="feefo-review-widget-service"
      ></div>
    </AbTestIdAndPaddingContainer>
  );
};

const FeefoServiceReviewTemplate = (props) => {
  const content = props?.content?.feefoService?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    DynamicFeefoServiceReviewComponent,
    content,
    undefined,
    pageImgQuality
  );
};

export default FeefoServiceReviewTemplate;
