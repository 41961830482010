import React, { useEffect, useState } from "react";
import { FHInlineSvg } from "../../components/SemanticTheme/FHInlineSvg";
import { getBrowserDataForPaymentByCard } from "../../components/Utils";
import {
  useClientIPAddress,
  useCustomerAddressDetails,
} from "../../hooks/hooks";
import { makeHtml } from "../../utils/common";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { withContent } from "../../utils/TemplateWithContent";
import SnapBarComponent from "./SnapBar";
import {
  successfulPaymentMessageOnClose,
  createAddCardReset,
  createAddCard,
  getRemoveCardsAction,
  getScheduledPaymentAction,
  getPaymentCardsAction,
} from "@/store/actions";
import {
  useLoginSelector,
  useAddCardStateSelector,
  useGetPaymentCardssStateSelector,
  useGetRemoveCardssStateSelector,
  useGetScheduledPaymentsStateSelector,
} from "@/store/selectors";
import { ManagePaymentCard } from "src/graphql/generated-strapi/types";
import { DynamicPaymentCards } from "./DynamicComponents";

type PaymentCards = {
  cardTokenId?: number;
  cardType?: string;
  cardTypeImage: string;
  expiryDate: string;
  last4Digits: number;
  defaultCard: boolean;
};

type ManageCardComponentProps = {
  pageImgQuality?: number;
} & ManagePaymentCard;

const ManagePaymentCard: React.FC<ManageCardComponentProps> = (
  props
): JSX.Element => {
  const dispatch = useDispatch();
  const { data: customerDetails } = useLoginSelector();
  const { data: paymentCards, loading } = useGetPaymentCardssStateSelector();
  const { data: scheduledPayments } = useGetScheduledPaymentsStateSelector();
  const { data: removedCard } = useGetRemoveCardssStateSelector();
  const { address } = useCustomerAddressDetails();
  const {
    data: saveCard,
    loading: isSaveCardLoading,
    errorMessage: saveCardError,
    hasError: saveCardHasError,
  } = useAddCardStateSelector();
  const { clientIPAddress } = useClientIPAddress();
  const [snapBar, setSnapBar] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);
  const imgQuality = props?.ImageQuality || props?.pageImgQuality;

  const cardsList =
    paymentCards &&
    paymentCards?.tokenisedCards &&
    paymentCards?.tokenisedCards.length > 0
      ? (paymentCards?.tokenisedCards as unknown)
      : [];

  const resetSuccessfulFailedPaymentValue = () =>
    dispatch(successfulPaymentMessageOnClose({}));

  useEffect(() => {
    if (customerDetails?.customer.id) {
      dispatch(
        getPaymentCardsAction({
          customerId: customerDetails?.customer?.id,
        })
      );
      resetSuccessfulFailedPaymentValue();
    }
  }, [customerDetails, isAddCard]);

  useEffect(() => {
    if (!!customerDetails?.customer?.id) {
      dispatch(
        getScheduledPaymentAction({
          customerId: customerDetails.customer.id,
        })
      );
    }
  }, [customerDetails]);

  useEffect(() => {
    setSnapBar(removedCard?.success || false);
  }, [removedCard]);

  useEffect(() => {
    setSnapBar((saveCard?.success && isAddCard) || false);
  }, [saveCard, isAddCard]);

  const svgIcon = (iconURl: string) => {
    return iconURl ? <FHInlineSvg src={iconURl} size="24px" /> : undefined;
  };

  const parsedText = (text: string) => {
    return text ? (
      <div dangerouslySetInnerHTML={makeHtml(text as string)} />
    ) : undefined;
  };

  const getSnapBar = () => {
    return isAddCard
      ? props?.AddCardSnapBar?.data?.attributes
      : props?.RemoveCardSnapBar?.data?.attributes;
  };

  return (
    <>
      <Typography variant="h2">{props?.Title}</Typography>
      <DynamicPaymentCards
        imgQuality={imgQuality}
        onSuccessPayment={() => {
          setIsAddCard(true);
        }}
        resetSaveCard={() => {
          dispatch(createAddCardReset());
        }}
        onSubmit={(cardData) => {
          setIsAddCard(false);
          dispatch(
            createAddCard({
              bookingChannelId: process.env.NEXT_PUBLIC_BOOKING_CHANNEL_ID,
              customerId: customerDetails?.customer.id,
              isDefaultCard: cardData.saveCard,
              cardTokenisationRequest: {
                cardHolder: cardData.cardHolder,
                cardType: cardData.cardType,
                cardNumber: cardData.cardNumber.replace(/(\D|\s)+/g, ""),
                expiryDateMMYY: cardData.expiryDateMMYY.replace(
                  /(\D|\s)+/g,
                  ""
                ),
                cardVerificationValue: cardData.cardVerificationValue,
                billingAddress1: address?.addressLine1,
                billingAddress2: address?.addressLine2,
                billingCity: address?.city,
                billingPostCode: address?.postCode,
                billingCountry: address?.country,
                browserData: getBrowserDataForPaymentByCard(clientIPAddress),
              },
            })
          );
        }}
        saveCardProps={{
          data: saveCard,
          errorMessage: saveCardError,
          hasError: saveCardHasError,
          loading: isSaveCardLoading,
        }}
        cards={cardsList as PaymentCards[]}
        loading={loading}
        addCardIcon={svgIcon(
          props?.AddCardIcon?.data?.attributes?.url as string
        )}
        actionMenuIcon={svgIcon(
          props?.ActionMenuIcon?.data?.[0]?.attributes?.url
        )}
        removeCard={props?.RemoveCardLabel}
        makeDefaultCard={props?.MakeDefaultLabel}
        defaultCardLabel={props?.DefaultCardLabel}
        addNewCardLabel={props?.AddNewCard}
        showAddCardButton={props?.ShowAddCardButton}
        modalTitle={props?.AddCardModalTitle}
        cardNumberFieldLabel={props?.AddCardModalCardNumberLabel}
        nameFieldLabel={props?.AddCardModalCardNameLabel}
        expiryDateFieldLabel={props?.AddCardModalCardExpiryDateLabel}
        CVVFieldLabel={props?.AddCardModalCardCVVLabel}
        setDefaultCardLabel={props?.AddCardModalDefaultLabel}
        saveButtonLabel={props?.AddCardModalSaveButtonLabel}
        cancelButtonLabel={props?.AddCardModalCancelButtonLabel}
        closeIcon={svgIcon(
          props?.AddCardModalCloseIcon?.data?.attributes?.url as string
        )}
        failureMessage={parsedText(props?.AddCardModalFailureMessage)}
        removeCardContent={parsedText(props?.RemoveCardContent)}
        removeScheduledCardContent={parsedText(
          props?.RemoveScheduledCardContent
        )}
        removeCardAction={(cardTokenId) => {
          setIsAddCard(false);
          dispatch(
            getRemoveCardsAction({
              cardTokenId,
              customerId: customerDetails?.customer?.id,
            })
          );
        }}
        scheduledPayments={
          scheduledPayments?.scheduledPayments?.length
            ? scheduledPayments.scheduledPayments
            : []
        }
        setSelectedCardLabel="Selected"
      />
      {snapBar && (
        <SnapBarComponent
          content={getSnapBar()}
          overrideProps={{
            showBar: snapBar,
          }}
        />
      )}
    </>
  );
};

const ManagePaymentCardsTemplate = (props): JSX.Element => {
  const content = props?.content?.paymentCards?.data?.[0]?.attributes;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(ManagePaymentCard, content, undefined, pageImgQuality);
};
export default ManagePaymentCardsTemplate;
