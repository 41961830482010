import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Container, Grid } from "@mui/material";
import ContentArea from "../../utils/ContentArea";
import {
  TabbedNav,
  TabbedNavLink,
} from "../../components/SemanticTheme/TabbedNav";
import { withContent } from "../../utils/TemplateWithContent";
import { MappedTemplateExperimentType } from "src/types/Content";
import {
  BasicPagePrimaryContentDynamicZone,
  ContentTabContainer,
} from "src/graphql/generated-strapi/types";

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    tabContent: {
      margin: theme.spacing(5) + " 0",
      "& .MuiContainer-root": {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  }))();

export const ContentTabContainerComponent: React.FC<ContentTabContainer> = (
  props: ContentTabContainer & MappedTemplateExperimentType,
): JSX.Element => {
  const classes = useStyles();
  const tabContent = props?.ContentTabs?.data;

  const makeInitialTabs = (): TabbedNavLink[] => {
    return (
      tabContent &&
      tabContent.map((tab, tabIndex) => {
        return {
          primaryLabel: tab?.attributes?.TabTitle as string,
          active: tabIndex === 0,
          onTabClick: () => handleTabChange(tabIndex),
        };
      })
    );
  };

  const [tabs, setTabs] = useState<TabbedNavLink[]>(makeInitialTabs);

  const components =
    tabContent &&
    tabContent.map((content, i) => {
      return (
        !!content?.attributes?.Components?.length && (
          <ContentArea
            content={
              content?.attributes
                ?.Components as BasicPagePrimaryContentDynamicZone[]
            }
            key={`tabContent-${i}`}
            additionalProps={(props?.experiments as {}) || undefined}
          />
        )
      );
    });

  const getTab = () => {
    const activeTab =
      tabs && tabs.findIndex((tab: TabbedNavLink) => tab.active);
    return components[activeTab];
  };

  const handleTabChange = (index: number) => {
    setTabs(
      tabs.map((tab: TabbedNavLink, i: number) =>
        index === i
          ? {
              ...tab,
              active: true,
            }
          : {
              ...tab,
              active: false,
            },
      ),
    );
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TabbedNav
            links={tabs}
            mobileText={props?.MobileText}
            mobileDropdown
          />
          <div className={classes.tabContent}>{getTab()}</div>
        </Grid>
      </Grid>
    </Container>
  );
};

const ContentTabContainerTemplate = (props) => {
  const content = props?.content?.contentTab?.data?.[0]?.attributes;
  const additionalProps = props?.experiments || null;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    ContentTabContainerComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
  );
};

export default ContentTabContainerTemplate;
