import React from "react";
import {
  ImageCard,
  ImageGallery,
  ImageGallerySize,
} from "../../components/SemanticTheme/ImageGallery";
import {
  maxImgWidth,
  useGenerateResponsiveImageUrl,
} from "../../components/Utils";
import { Container } from "@mui/material";
import { withContent } from "../../utils/TemplateWithContent";
import { ImageGalleryEntity } from "src/graphql/generated-strapi/types";

export type ImageGalleryComponentOverrideProps = {
  sizes: {
    xs?: ImageGallerySize;
    md?: ImageGallerySize;
    preferredDesktopRatio?: { x: number; y: number };
    preferredMobileRatio?: { x: number; y: number };
  }[];
};

type ImageGalleryComponent = {
  pageImgQuality?: number;
  additionalProps?: { [key: string]: string | boolean };
} & ImageGalleryEntity;

const ImageGalleryComponent: React.FC<ImageGalleryComponent> = (
  props: ImageGalleryComponent,
) => {
  const imgQuality = props?.attributes?.ImageQuality || props?.pageImgQuality;
  const additionalProps = props?.additionalProps;
  const maxWidths = {
    xs: 400,
    sm: 550,
    md: 550,
    lg: 550,
    xl: 550,
  };

  const generateImageUrl = (
    url: string | undefined,
    maxWidths?: maxImgWidth,
  ) => {
    if (!!url) {
      return useGenerateResponsiveImageUrl(url, maxWidths, imgQuality, true);
    }
  };
  const contentImages = props?.attributes?.GalleryImages?.data;

  const images = contentImages?.map((contentItem, i) => {
    const fullImageResp = generateImageUrl(contentItem?.attributes?.url);
    const fullImageUrl = !!fullImageResp ? fullImageResp : "";
    const thumbnailImageResp = generateImageUrl(
      contentItem?.attributes?.url,
      maxWidths,
    );
    const thumbnailUrl = !!thumbnailImageResp ? thumbnailImageResp : "";
    return {
      altText: contentItem?.attributes?.alternativeText as string,
      fullUrl: fullImageUrl,
      thumbnailUrl: thumbnailUrl,
    };
  });

  return (
    <Container>
      <ImageGallery images={images} />
    </Container>
  );
};

const ImageGalleryComponentTemplate = (props) => {
  const content = props?.content?.images?.data;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;
  return withContent(
    ImageGalleryComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
  );
};
export default ImageGalleryComponentTemplate;
