import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren, useState } from "react";
import { ImgProps, USP } from ".";
import classNames from "classnames";
import ScrollContainer from "react-indiana-drag-scroll";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2),
    },
    "&:after": {
      position: "absolute",
      content: '""',
      top: 0,
      right: 0,
      height: "100%",
      width: 40,
      zIndex: 9,
      background: `transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF99 30%, #FFFFFFB3 62%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
      transform: "matrix(0, -4, 1, 0, 0, 0)",
    },
  },
  scrollEnd: {
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2),
    },
    "&:after": {
      display: "none",
    },
  },
  cardsWarp: {
    display: "flex",
    gap: "20px",
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
      paddingBottom: 0,
    },
  },
  showPaddingTop: {
    paddingTop: theme.spacing(1.5),
  },
  showPaddingBottom: {
    paddingBottom: theme.spacing(1.5),
  },
  scrollContainer: {
    overflowY: "hidden",
  },
  addUSPSpacing: {
    paddingTop: theme.spacing(6.4),
    paddingBottom: theme.spacing(4.4),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2.4),
    },
  },
}));

type USPContainerProps = {
  UPSCards: {
    isImage?: boolean;
    title?: string;
    image?: ImgProps;
    linkUrl?: string;
    isBold?: boolean;
    underlineNone?: boolean;
    onLinkClick?: () => void;
  }[];
  showPaddingTop?: boolean;
  showPaddingBottom?: Boolean;
  addUSPSpacing?: boolean;
};

export const USPContainer: React.FC<PropsWithChildren<USPContainerProps>> = ({
  UPSCards,
  showPaddingTop,
  showPaddingBottom,
  addUSPSpacing,
}: PropsWithChildren<USPContainerProps>) => {
  const classes = useStyles();
  const [isScrollEnd, setisScrollEnd] = useState<Boolean>(false);
  return (
    <div
      className={
        UPSCards?.length > 3
          ? classNames(isScrollEnd ? classes.scrollEnd : classes.root, {
              [classes.showPaddingTop]: showPaddingTop,
              [classes.showPaddingBottom]: showPaddingBottom,
              [classes.addUSPSpacing]: addUSPSpacing,
            })
          : classNames(classes.scrollEnd, {
              [classes.showPaddingTop]: showPaddingTop,
              [classes.showPaddingBottom]: showPaddingBottom,
              [classes.addUSPSpacing]: addUSPSpacing,
            })
      }
    >
      <ScrollContainer
        horizontal
        hideScrollbars={UPSCards?.length > 3 ? false : true}
        onEndScroll={() => {
          setisScrollEnd(true);
        }}
        onScroll={() => {
          setisScrollEnd(false);
        }}
        className={classes.scrollContainer}
      >
        <div className={classes.cardsWarp}>
          {UPSCards?.map((card, index) => (
            <USP
              key={`key-${index}`}
              isImage={card?.isImage}
              title={card?.title}
              image={card?.image}
              linkUrl={card?.linkUrl}
              underlineNone={card?.underlineNone}
              isBold={card?.isBold}
              onLinkClick={card?.onLinkClick}
            />
          ))}
        </div>
      </ScrollContainer>
    </div>
  );
};
