import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import { withContent } from "../../utils/TemplateWithContent";
import { ThemeProvider } from "@mui/material/styles";
import SemanticTheme from "../../themes/SemanticTheme";
import { VWODataReference } from "../../utils/AbTestIdAndPaddingContainer";
import FHButton from "../../components/SemanticTheme/FHButton";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { makeHtml } from "../../utils/common";
import { Grid } from "@mui/material";
import ContentContainer from "../../components/SemanticTheme/ContentContainer";
import { ButtonsContainerEntity } from "src/graphql/generated-strapi/types";
import { updateAbsoluteLink, processRichText } from "@components/Utils";

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme) => ({
    buttonContainer: {
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0,
      ),
      "@media (max-width:600px)": {
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0,
        ),
      },
    },
    children: {
      paddingTop: theme.spacing(3.5),
      "@media (max-width:600px)": {
        paddingTop: theme.spacing(2.5),
      },
    },
    showPaddingTop: {
      paddingTop: `${theme.spacing(3.5)}px !important`,
      "@media (max-width:600px)": {
        paddingTop: `${theme.spacing(2.5)}px !important`,
      },
    },
    showPaddingBottom: {
      paddingBottom: `${theme.spacing(3.5)}px !important`,
      "@media (max-width:600px)": {
        paddingBottom: `${theme.spacing(2.5)}px !important`,
      },
    },
  }))();

const ButtonContainer: React.FC<ButtonsContainerEntity> = (
  props,
): JSX.Element => {
  const classes = useStyles(
    props?.attributes?.showPaddingTop || false,
    props?.attributes?.showPaddingBottom || false,
  );

  return (
    <ThemeProvider theme={SemanticTheme}>
      <div className={classes.buttonContainer}>
        <VWODataReference id={props?.attributes?.ABTestId || ""}>
          <ContentContainer
            title={props?.attributes.Title}
            titleVariant="h3"
            description={
              <RichContentArea>
                <div
                  dangerouslySetInnerHTML={makeHtml(
                    processRichText(props?.attributes.Description),
                  )}
                />
              </RichContentArea>
            }
          >
            <Grid container spacing={2} className={classes.children}>
              {!!props?.attributes?.PrimaryCTAButton &&
                !!props?.attributes?.PrimaryCTAButton?.data?.attributes
                  ?.CallToActionLink?.data?.attributes?.PageRoute &&
                !!props?.attributes?.PrimaryCTAButton?.data?.attributes
                  ?.CallToActionText && (
                  <Grid
                    item
                    xs={12}
                    className={classNames({
                      [classes.showPaddingTop]:
                        props?.attributes.PrimaryCTAButton?.data?.attributes
                          ?.showPaddingTop?.valueOf,
                      [classes.showPaddingBottom]:
                        props?.attributes.PrimaryCTAButton?.data?.attributes
                          ?.showPaddingBottom?.valueOf,
                    })}
                  >
                    <VWODataReference
                      id={
                        props?.attributes.PrimaryCTAButton?.data?.attributes
                          ?.ABTestId || ""
                      }
                    >
                      <FHButton
                        href={updateAbsoluteLink(
                          props?.attributes.PrimaryCTAButton?.data?.attributes
                            ?.CallToActionLink?.data?.attributes?.PageRoute,
                        )}
                        fullWidth
                      >
                        {
                          props?.attributes.PrimaryCTAButton?.data?.attributes
                            ?.CallToActionText
                        }
                      </FHButton>
                    </VWODataReference>
                  </Grid>
                )}
              {props?.attributes?.SecondaryButtons?.data?.length > 0 &&
                props?.attributes?.SecondaryButtons?.data?.map((button) => (
                  <>
                    {!!button &&
                      !!button?.attributes?.CallToActionLink?.data?.attributes
                        ?.PageRoute &&
                      !!button?.attributes?.CallToActionText && (
                        <Grid
                          item
                          xs={12}
                          className={classNames({
                            [classes.showPaddingTop]:
                              button?.attributes?.showPaddingTop,
                            [classes.showPaddingBottom]:
                              button?.attributes?.showPaddingBottom,
                          })}
                        >
                          <VWODataReference
                            id={button?.attributes?.ABTestId || ""}
                          >
                            <FHButton
                              fhStyle="secondary"
                              href={updateAbsoluteLink(
                                button?.attributes?.CallToActionLink?.data
                                  ?.attributes?.PageRoute,
                              )}
                              fullWidth
                            >
                              {button?.attributes?.CallToActionText}
                            </FHButton>
                          </VWODataReference>
                        </Grid>
                      )}
                  </>
                ))}
            </Grid>
          </ContentContainer>
        </VWODataReference>
      </div>
    </ThemeProvider>
  );
};

const ButtonContainerComponentTemplate = (props) => {
  const content = props?.content?.buttonContainer?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(ButtonContainer, content, undefined, pageImgQuality);
};

export default ButtonContainerComponentTemplate;
