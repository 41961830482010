import React from "react";
import { makeStyles } from "@mui/styles";
import { Drawer, SwipeableDrawer } from "@mui/material";
import { WrapperProps, Wrapper } from "./Wrapper";

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    "& .MuiDrawer-paper": {
      width: "80%",
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    "& $paperRoot": {
      width: "80%",
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    "& .MuiCardHeader-root": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  mobileDrawerContent: {
    display: "flex",
    height: "100%",
    justifyContent: "flex-end",
    "& .MuiPaper-root": {
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.background.default,
    },
    "& .MuiDrawer-paper": {
      backgroundColor: "transparent",
    },
  },
  paperRoot: {},
}));

export type MobileDrawerCardProps = {
  open: boolean;
  onOpen: (event: React.SyntheticEvent<{}, Event>) => void;
  isSwipeableDrawer?: boolean;
} & WrapperProps;

export const MobileDrawerCard: React.FC<MobileDrawerCardProps> = ({
  children,
  open,
  onOpen,
  onClose,
  isSwipeableDrawer = true,
  ...rest
}: MobileDrawerCardProps) => {
  const classes = useStyles({});
  return (
    <>
      {isSwipeableDrawer ? (
        <SwipeableDrawer
          anchor="right"
          open={open}
          onClose={onClose}
          onOpen={onOpen}
          className={classes.mobileDrawer}
          classes={{
            paper: classes.paperRoot,
          }}
        >
          <div className={classes.mobileDrawerContent}>
            <Wrapper {...{ children, onClose, onOpen, ...rest }} />
          </div>
        </SwipeableDrawer>
      ) : (
        <Drawer
          anchor="right"
          open={open}
          onClose={onClose}
          className={classes.mobileDrawer}
          classes={{
            paper: classes.paperRoot,
          }}
        >
          <div className={classes.mobileDrawerContent}>
            <Wrapper {...{ children, onClose, onOpen, ...rest }} />
          </div>
        </Drawer>
      )}
    </>
  );
};
