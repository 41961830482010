import { makeStyles } from "@mui/styles";
import React from "react";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { makeHtml } from "../../components/Utils";
import { useTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import SemanticTheme from "../../themes/SemanticTheme";

const useStyles = makeStyles((theme) => ({
  themeWhite: {
    "& > div *": {
      color: theme.palette.background.paper,
    },
  },
  themeBlack: {
    "& > div *": {
      color: theme.palette.text.primary,
    },
  },
}));

const RichText: React.FC<RichTextProps> = ({
  contentId,
  contentValue,
  color,
}): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();

  //   if (contentValue.propertyDataType !== "PropertyXhtmlString")
  //     throw new Error(
  //       `ERROR: Unable to render '${contentId}'. <RichText/> cannot render '${contentValue.propertyDataType}' content types. Expected type of 'PropertyXhtmlString'.`
  //     );

  return (
    <ThemeProvider theme={SemanticTheme}>
      <RichContentArea className={color ? classes[color] : undefined}>
        <div dangerouslySetInnerHTML={makeHtml(contentValue)} />
      </RichContentArea>
    </ThemeProvider>
  );
};

interface RichTextProps {
  contentId: string;
  contentValue: string;
  color?: "themeWhite" | "themeBlack";
}

export default RichText;
