import { Container, Paper, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren } from "react";
import { FHNextImage, ImageLoader } from "../FHNextImage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    padding: theme.spacing(0),
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  imgWrapper: {
    position: "relative",
    width: "100%",
  },
  skeleton: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  img: {
    width: "100%",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  caption: {
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
      textAlign: "center",
    },
    padding: "15px 30px",
  },
}));

export type ImageWithCaptionProps = {
  imageUrl: string;
  altText: string;
  caption?: string;
  imageLoader?: ImageLoader;
};

export const ImageWithCaption: React.FC<ImageWithCaptionProps> = ({
  imageUrl,
  altText,
  caption,
  imageLoader,
}: PropsWithChildren<ImageWithCaptionProps>) => {
  const classes = useStyles();
  const [isImgLoading, setIsImgLoading] = React.useState(true);

  const handleImageLoad = () => {
    setIsImgLoading(false);
  };

  return (
    <Container className={classes.container}>
      <Paper className={classes.root}>
        <div className={classes.imgWrapper}>
          <picture>
            {isImgLoading && (
              <Skeleton
                variant="rectangular"
                animation="wave"
                className={classes.skeleton}
              />
            )}
            <FHNextImage
              src={imageUrl}
              alt={altText}
              width={800}
              height={534}
              loading="lazy"
              loader={imageLoader}
              onLoad={handleImageLoad}
              className={classes.img}
            />
          </picture>
        </div>
        <div className={classes.caption}>
          <Typography variant="body1">{caption ? caption : altText}</Typography>
        </div>
      </Paper>
    </Container>
  );
};
