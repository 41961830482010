import { makeStyles } from "@mui/styles";
import { useRouter } from "next/router";
import {
  makeHtml,
  processRichText,
  useGenerateResponsiveImageUrl,
} from "../../components/Utils";
import React from "react";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { NormalizedContainer, NormalizedGrid } from "../../muiOverrides";
import { createPathFromUrl, defaultBookingWidths } from "../../utils";
import { Grid } from "@mui/material";
import { LocationBrochureCard } from "../../components/SemanticTheme/BrochureCard";
import { CarouselImageProps } from "../../components/SemanticTheme/ImageGalleryCarousel";
import { useLocationsBlockInfoSelector } from "@/store/selectors";
import { defaultMaxWidths, maxImgWidth } from "src/utils/common";
import { LocationBrochureCardEntityResponse } from "src/graphql/generated-strapi/types";

const useStyles = makeStyles((theme) => ({
  titlePadding: {
    marginTop: 20,
  },
  grid: {
    padding: theme.spacing(3.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5),
    },
  },
}));

type LocationBrochureCardComponentProps = {
  pageImgQuality?: number;
  additionalProps?: { [key: string]: string | boolean };
} & LocationBrochureCardEntityResponse;

const LocationBrochureCardsComponent: React.FC<
  LocationBrochureCardComponentProps
> = (props) => {
  const classes = useStyles();
  const history = useRouter();
  const { data: allLocations } = useLocationsBlockInfoSelector();

  const generateImageUrl = (
    url: string | undefined,
    maxWidths?: maxImgWidth,
  ) => {
    return !!url
      ? useGenerateResponsiveImageUrl(url, maxWidths, undefined, true)
      : undefined;
  };

  return (
    <NormalizedContainer>
      {/* {overrideProps?.title && (
        <NormalizedGrid container justify={"center"}>
          <Typography variant={"h3"} className={classes.titlePadding}>
            {overrideProps?.title}
          </Typography>
        </NormalizedGrid>
      )} */}
      <NormalizedGrid container>
        {!!allLocations?.length &&
          allLocations.map((location, index) => {
            const locationDetailsPageUrl = createPathFromUrl(
              location.primaryCallToActionRef || "",
            );
            return (
              <Grid
                className={classes.grid}
                item
                xs={12}
                key={`location-index-${index}`}
              >
                <LocationBrochureCard
                  imageGalleryCarouselProps={{
                    images: (location.images || []).map(
                      (image) =>
                        ({
                          imageUrl: generateImageUrl(
                            `${image?.url}`,
                            defaultMaxWidths,
                          ),
                          smallImageUrl: generateImageUrl(
                            `${image?.url}`,
                            defaultBookingWidths,
                          ),
                          altText: image?.name,
                        } as CarouselImageProps),
                    ),
                  }}
                  title={location.locationTitle || ""}
                  subtitle={location.locationSubTitle || ""}
                  description={location.pageDescription || undefined}
                  features={
                    <RichContentArea>
                      <div
                        dangerouslySetInnerHTML={makeHtml(
                          processRichText(location.locationSummary),
                        )}
                      />
                    </RichContentArea>
                  }
                  ctaText={location.primaryCallToActionText || undefined}
                  onCtaClick={() => {
                    if (location.primaryCallToActionRef) {
                      history.push(locationDetailsPageUrl);
                    }
                  }}
                  maxWidth={1200}
                  centered={true}
                  activities={location.features?.map(({ url, label }: any) => ({
                    label,
                    svgIconUrl: url || "",
                  }))}
                  locationDetailsPageUrl={locationDetailsPageUrl}
                />
              </Grid>
            );
          })}
      </NormalizedGrid>
    </NormalizedContainer>
  );
};

export default LocationBrochureCardsComponent;
