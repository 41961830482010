import React from "react";
import {
  makeHtml,
  processRichText,
  useGenerateResponsiveImageUrl,
} from "../../components/Utils";
import { Container } from "@mui/material";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { withContent } from "../../utils/TemplateWithContent";
import {
  Alignment,
  MediaBlock,
} from "../../components/SemanticTheme/MediaBlock";
import {
  defaultMaxWidths,
  defaultMediaBlockMaxWidths,
  maxImgWidth,
} from "src/utils/common";
import { MediaBlockEntity } from "src/graphql/generated-strapi/types";

type MediaBlockComponent = {
  pageImgQuality?: number;
} & MediaBlockEntity;

type CMSData = ReturnType<typeof makeCmsData>;
const makeCmsData = (content: any) => ({
  title: content?.["Title"] as string,
  subtitle: content?.["Subtitle"] as string,
  description: content?.["Description"] as string,
  autoplay: content?.["Autoplay"] as boolean,
  border: content?.["Border"] as boolean,
  videoUrl: content?.["Video"]?.data?.attributes?.url as string,
  imageUrl: content?.["MediaBlockImage"]?.data?.attributes?.url as string,
  videoPausedImageUrl: content?.["VideoPausedImage"]?.data?.attributes
    ?.url as string,
  ctaUrl: content?.["PrimaryCallToActionRef"]?.data?.attributes
    ?.PageRoute as string,
  align: content?.["Align"] as Alignment,
  ctaLabel: content?.["PrimaryCallToActionText"] as string | undefined,
  youtubeUrl: content?.["YoutubeUrl"] as string | undefined,
});

const MediaBlockComponent: React.FC<MediaBlockComponent> = (
  props: MediaBlockComponent,
): JSX.Element => {
  const cmsData: CMSData = makeCmsData(props?.attributes);
  const imgQuality = props?.attributes?.ImageQuality || props?.pageImgQuality;
  const imageMaxWidth =
    cmsData?.align === "left" || cmsData?.align === "right"
      ? defaultMediaBlockMaxWidths
      : defaultMaxWidths;

  if (
    cmsData?.autoplay &&
    cmsData?.youtubeUrl &&
    cmsData?.youtubeUrl?.includes("?autoplay=1")
  ) {
    cmsData.youtubeUrl = cmsData?.youtubeUrl?.replace(
      "?autoplay=1",
      "?autoplay=0",
    );
  } else if (
    cmsData?.youtubeUrl &&
    !cmsData?.youtubeUrl?.includes("?autoplay=0")
  ) {
    cmsData.youtubeUrl = cmsData?.youtubeUrl + "?autoplay=0";
  }

  const generateImageUrl = (
    url: string | undefined,
    maxWidths: maxImgWidth,
  ) => {
    return !!url
      ? useGenerateResponsiveImageUrl(url, maxWidths, imgQuality, true)
      : undefined;
  };

  const updatedCmsData = () => {
    return {
      ...cmsData,
      imageUrl: generateImageUrl(cmsData?.imageUrl, imageMaxWidth),
      videoPausedImageUrl: generateImageUrl(
        cmsData?.videoPausedImageUrl,
        imageMaxWidth,
      ),
    };
  };

  return (
    <Container>
      <MediaBlock {...updatedCmsData()}>
        <RichContentArea>
          <div
            dangerouslySetInnerHTML={makeHtml(
              processRichText(cmsData?.description),
            )}
          />
        </RichContentArea>
      </MediaBlock>
    </Container>
  );
};
const MediaBlockTemplate = (props) => {
  const content = props?.content?.mediaBlocks?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(MediaBlockComponent, content, undefined, pageImgQuality);
};
export default MediaBlockTemplate;
