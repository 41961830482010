import React, { useEffect } from "react";
import {
  Typography,
  SwipeableDrawer,
  Button,
  Card,
  CardContent,
  CardHeader,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SubNavLinkItem } from "./";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import classNames from "classnames";

const useStyles = () =>
  makeStyles((theme) => ({
    root: { position: "relative" },
    smallNav: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid " + theme.palette.divider,
      borderRadius: 10,
      padding: theme.spacing(2),
    },
    closeButton: {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
      padding: theme.spacing(0.3),
      minWidth: "auto",
      margin: theme.spacing(0, 0, 0, 1),
      "&:hover": {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        borderWidth: 2,
      },
    },
    cardRoot: {
      display: "flex",
      flexDirection: "column",
    },
    cardContentContainer: {
      position: "relative",
      overflow: "hidden",
    },
    cardContent: {
      overflowY: "auto",
      overflowX: "hidden",
      padding: 0,
      width: "100%",
      height: "100%",
      "&:last-child": {
        padding: 0,
      },
    },
    cardActions: {
      alignItems: "flex-end",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "auto",
      borderTop: `1px solid ${theme.palette.divider}`,
      "& > button:not(:last-child)": {
        margin: theme.spacing(0, 0, 1, 0),
      },
    },
    title: {
      marginBottom: 0,
      color: theme.palette.secondary.main,
      textAlign: "center",
      fontSize: "18px",
    },
    smallMenuLink: {
      border: "1px solid " + theme.palette.divider,
      width: "100%",
      minWidth: 225,
      padding: theme.spacing(0.2, 0.5),
      position: "relative",
      "& .MuiButton-label": {
        justifyContent: "space-between",
      },
      "& .actual-label": {
        width: "100%",
        fontSize: "18px",
        color: theme.palette.text.primary,
      },
      "& svg": {
        borderLeft: "1px solid " + theme.palette.action.active,
        boxSizing: "content-box",
        fill: theme.palette.action.active,
        padding: theme.spacing(0, 0.8),
        position: "absolute",
        right: 0,
      },
    },
    mobileDrawer: {
      zIndex: 1300,
      "& .MuiDrawer-paper": {
        minWidth: 400,
        [theme.breakpoints.down("sm")]: {
          minWidth: "80%",
        },
        borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
      },
      "& .MuiCardHeader-root": {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1.6, 5),
      },
      "& .MuiCardHeader-action": {
        position: "absolute",
        left: "100%",
        marginLeft: -53,
        marginTop: -3,
      },
    },
    mobileDrawerContent: {
      display: "flex",
      height: "100%",
      justifyContent: "flex-end",
      "& .MuiPaper-root": {
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.background.default,
      },
      "& .MuiDrawer-paper": {
        backgroundColor: "transparent",
        overflowY: "hidden",
      },
    },
    linkItem: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      "& a:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "& a": {
        textDecoration: "none",
        color: theme.palette.secondary.main,
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
        padding: theme.spacing(2, 3),
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: 600,
      },
    },
    linkItemBack: {
      background: `linear-gradient(to top, ${theme.palette.divider} 0%, #fcfcfc 100%)`,
      "& svg": {
        position: "absolute",
        marginLeft: theme.spacing(-2.5),
      },
    },
    drilledInCardContent: {
      position: "absolute",
      top: 0,
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    drilledLink: {
      "& a": {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
      },
    },
    header: {
      minHeight: 62,
    },
  }))();

export type SmallSubNavigationBarProps = {
  linkList: SubNavLinkItem[];
  title: string;
  activePageName: string;
};
export const SmallSubNavigationBar = ({
  linkList,
  title,
  activePageName,
}: SmallSubNavigationBarProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [drillIn, setDrillIn] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<SubNavLinkItem>();
  const drillTimeout = React.useRef<number>();

  useEffect(() => {
    return () => {
      // on unmount
      const timeoutId = drillTimeout.current;
      if (timeoutId) {
        if (drillTimeout) {
          drillTimeout.current = undefined;
        }
        clearTimeout(timeoutId);
      }
    };
  }, [linkList]);

  const renderListItem = (item: SubNavLinkItem) => {
    const link = item.link;
    if (typeof link === "string") {
      return (
        <a href={link} data-testid="subNavBarLink">
          {item.text}
        </a>
      );
    } else if (typeof link === "function") {
      return (
        <a
          href="#"
          data-testid="subNavBarLink"
          onClick={(e) => {
            e.preventDefault();
            link();
          }}
        >
          {item.text}
        </a>
      );
    } else {
      return (
        <a
          data-testid="subNavBarLink"
          onClick={(e) => {
            e.preventDefault();
            updateSelectedItem(item);
          }}
        >
          {item.text} <ChevronRightIcon />
        </a>
      );
    }
  };

  const renderLinkList = (items: SubNavLinkItem[], drilled: boolean) => (
    <>
      {items.map((item, i) => (
        <Typography
          key={i}
          className={classNames(classes.linkItem, {
            [classes.drilledLink]: drilled,
          })}
        >
          {renderListItem(item)}
        </Typography>
      ))}
    </>
  );

  const updateSelectedItem = (item: SubNavLinkItem | undefined) => {
    if (item) {
      setSelectedItem(item);
      setDrillIn(true);
    } else {
      const timeoutId = drillTimeout.current;
      if (timeoutId && drillTimeout) {
        drillTimeout.current = undefined;
        clearTimeout(timeoutId);
      }
      setDrillIn(false);
      if (typeof window !== "undefined") {
        drillTimeout.current = window.setTimeout(() => {
          setSelectedItem(undefined);
        }, 250);
      }
    }
  };
  return (
    <div className={classes.smallNav} data-testid="smallSubNavBarRoot">
      <Typography variant="body1" className={classes.title} gutterBottom>
        <strong>{title}</strong>
      </Typography>
      <Typography variant="body1">
        <Button
          data-testid="subNavMenuButton"
          className={classes.smallMenuLink}
          onClick={() => setOpen(true)}
        >
          <span className="actual-label">{activePageName}</span>{" "}
          <ExpandMoreIcon />
        </Button>
      </Typography>
      <SwipeableDrawer
        className={classes.mobileDrawer}
        anchor="right"
        open={open}
        onOpen={() => {}}
        onClose={() => setOpen(false)}
      >
        <div className={classes.mobileDrawerContent}>
          <Card className={classes.cardRoot}>
            <CardHeader
              action={
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.closeButton}
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon />
                </Button>
              }
              title={
                <Typography
                  variant="body1"
                  className={classes.title}
                  gutterBottom
                >
                  <b>{title}</b>
                </Typography>
              }
              className={classes.header}
            />
            <div className={classes.cardContentContainer}>
              <Slide in={!drillIn} direction="right" appear={false}>
                <CardContent className={classes.cardContent}>
                  {renderLinkList(linkList, false)}
                </CardContent>
              </Slide>
              <Slide in={!!drillIn} direction="left" appear={false}>
                <div className={classes.drilledInCardContent}>
                  <Typography
                    className={classNames(
                      classes.linkItem,
                      classes.linkItemBack,
                    )}
                  >
                    <b>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          updateSelectedItem(undefined);
                        }}
                      >
                        {selectedItem?.text} <ChevronLeftIcon />
                      </a>
                    </b>
                  </Typography>
                  <CardContent className={classes.cardContent}>
                    {selectedItem &&
                      typeof selectedItem?.link !== "string" &&
                      typeof selectedItem?.link !== "function" &&
                      renderLinkList(selectedItem.link, true)}
                  </CardContent>
                </div>
              </Slide>
            </div>
            {/* <CardActions className={classes.cardActions}>buttons</CardActions> */}
          </Card>
        </div>
      </SwipeableDrawer>
    </div>
  );
};
