import React from "react";
import { withContent } from "src/utils/TemplateWithContent";
import { USPContainer } from "@components/SemanticTheme/USP/Container";
import { UspContainerEntity } from "src/graphql/generated-strapi/types";

type USPContainerComponentProps = {
  pageImgQuality?: number;
  additionalProps: { [key: string]: string | boolean };
} & UspContainerEntity;

const USPContainerComponent: React.FC<USPContainerComponentProps> = (
  props: USPContainerComponentProps,
): JSX.Element => {
  const { ImageQuality, USPs, showBottomPadding, showTopPadding } =
    props?.attributes;
  const imgQuality = ImageQuality ?? props?.pageImgQuality;

  const USPContent = !!USPs?.data?.length
    ? USPs.data.map((USPItem) => {
        const { CTAText, CTARef, Image, ImageQuality } = USPItem?.attributes;
        return {
          title: CTAText,
          linkUrl: CTARef,
          image: {
            url: Image?.data?.attributes?.url,
            altText: Image?.data?.attributes?.alternativeText as string,
            height: 60,
            width: 60,
            quality: ImageQuality ?? imgQuality,
          },
        };
      })
    : [];

  return (
    <USPContainer
      UPSCards={USPContent}
      showPaddingTop={showTopPadding}
      showPaddingBottom={showBottomPadding}
      addUSPSpacing={!!props?.additionalProps?.addUSPSpacing}
    />
  );
};

const USPContainerComponentTemplate = (props) => {
  const content = props?.content?.USPContainers?.data;
  const additionalProps = props?.additionalProps;
  return withContent(
    USPContainerComponent,
    content,
    undefined,
    undefined,
    additionalProps,
  );
};

export default USPContainerComponentTemplate;
