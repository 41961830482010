import React from "react";
import {
  updateAbsoluteLink,
  defaultTileWidths,
  useGenerateResponsiveImageUrl,
} from "../../components/Utils";
import { Tile } from "../../components/SemanticTheme/Tile";
import { SimilarLocationsContainer } from "../../components/SemanticTheme/SimilarLocationsContainer";
import { withContent } from "../../utils/TemplateWithContent";
import { SimilarLocationEntity } from "src/graphql/generated-strapi/types";

type SimilarLocationsComponent = {
  pageImgQuality?: number;
} & SimilarLocationEntity;

const SimilarLocationsComponent: React.FC<SimilarLocationsComponent> = (
  props: SimilarLocationsComponent,
): JSX.Element => {
  const primaryContent = props?.attributes;
  const cardsContentValue = primaryContent?.Cards?.data;
  const title = primaryContent?.Title as string;
  const subtitle = primaryContent?.SubTitle as string;
  const callToActionText =
    (primaryContent?.PrimaryCallToActionText as string) ?? undefined;
  const callToActionUrl = primaryContent?.PrimaryCallToActionRef?.data
    ?.attributes?.PageRoute
    ? updateAbsoluteLink(
        primaryContent?.PrimaryCallToActionRef?.data?.attributes?.PageRoute,
      )
    : undefined;
  const imgQuality = props?.attributes?.ImageQuality || props?.pageImgQuality;

  const generateImageUrl = (url: string | undefined, imgQuality?: number) => {
    if (url) {
      return useGenerateResponsiveImageUrl(
        url,
        defaultTileWidths,
        imgQuality,
        true,
      );
    }
  };

  const expandedValues = cardsContentValue.map((contentItem, i) => ({
    backgroundImageUrl: generateImageUrl(
      contentItem?.attributes?.Image?.data?.attributes?.url,
      contentItem?.attributes?.ImageQuality || imgQuality,
    ),
    title: contentItem?.attributes?.Title as string,
    subtitle: contentItem?.attributes?.Subtitle as string,
    link: contentItem?.attributes?.PrimaryCallToActionRef?.data?.attributes
      ?.PageRoute
      ? contentItem?.attributes?.PrimaryCallToActionRef?.data?.attributes
          ?.PageRoute
      : undefined,
    responsive: true,
  }));

  const cards = expandedValues.map((card, i) => (
    <Tile
      responsive={card.responsive}
      textAlignment={"bottom"}
      backgroundImageUrl={card.backgroundImageUrl}
      title={card.title}
      subtitle={card.subtitle}
      link={card.link}
    />
  ));

  return (
    <SimilarLocationsContainer
      title={title}
      subtitle={subtitle}
      cards={cards}
      ctaLabel={callToActionText}
      ctaUrl={callToActionUrl}
    />
  );
};

const SimilarLocationsTemplate = (props) => {
  const content = props?.content?.similarLocation?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    SimilarLocationsComponent,
    content,
    undefined,
    pageImgQuality,
  );
};

export default SimilarLocationsTemplate;
