import React from "react";
import { NotFound404 } from "src/components/SemanticTheme/NotFound404";
import logoSvg from "src/components/assests/fh-404-150.svg";

const NotFound = ({ content }) => {
  return (
    <NotFound404
      Logo={() => <img src={logoSvg?.src} alt="Forest Holidays logo" />}
      title={"Page not found"}
      subtitle={`We ran into trouble loading your page with status code: ${content} . Please try your link again or return to the HomePage...`}
      buttonText={"Back to homepage"}
      buttonUrl={"/"}
    />
  );
};

export default NotFound;
