import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Paper, useMediaQuery, useTheme } from "@mui/material";

import classNames from "classnames";
import { SmallSubNavigationBar } from "./SmallNavBar";

export type SubNavLinkItem = {
  text: string;
  link: string | SubNavLinkItem[] | Function;
  isActive: boolean;
  mobileCard?: SubNavLinkItem[];
  isMobileTabbedContent?: boolean;
};

export type SubNavigationBarProps = {
  linkList: SubNavLinkItem[];
  title: string;
  activePageName: string;
};

const useStyles = () =>
  makeStyles((theme) => ({
    root: { position: "relative" },
    linkContainer: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid " + theme.palette.divider,
      borderRadius: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(2),
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    link: {
      borderRight: "1px solid " + theme.palette.action.active,

      "&:last-child": {
        border: "none !important",
      },
      "& .top-nav": {
        position: "relative",
        cursor: "pointer",
        display: "block",
        padding: theme.spacing(2, 4),
        textDecoration: "none",
        color: theme.palette.grey[600],
        whiteSpace: "nowrap",
        "& > svg": {
          fill: theme.palette.grey[400],
          verticalAlign: "top",
        },
      },
      "& .top-nav.dropdown": {
        zIndex: 3000,
      },
    },
    activeLink: {},
    linkItem: {
      transition: "none",
      margin: theme.spacing(-1, 1),
      position: "relative",
    },
    linkItemSelected: {
      "&::after": {
        position: "absolute",
        zIndex: 4000,
        content: '" "',
        width: "100%",
        marginTop: -5,
        height: 10,
        background: "#FFF",
      },
    },
    linkContent: {
      position: "absolute",
      zIndex: 3000,
      padding: theme.spacing(2),
      minWidth: "100%",
      boxSizing: "border-box",
      "& a": {
        whiteSpace: "nowrap",
        textDecoration: "none",
        color: theme.palette.secondary.main,
      },
      "& a:hover": {
        textDecoration: "underline",
      },
      "& p:not(:last-child)": {
        marginBottom: 25,
      },
    },
    linkBackdrop: {
      zIndex: 2999,
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  }))();

const defaultprops: SubNavigationBarProps = Object.assign({
  linkList: [],
});

export const handleShowSmallForSubNavigationBarResize = (
  totalLinkItemWidth: number,
  containerWidth: number
) => {
  // If links don't fit in the bar, switch to small Menu, even if it is on desktop
  return totalLinkItemWidth > containerWidth;
};

export const SubNavigationBar = ({
  linkList,
  title,
  activePageName,
}: SubNavigationBarProps = defaultprops) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [showSmall, setShowSmall] = React.useState<boolean>();
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const totalLinkItemWidth =
      linkList &&
      linkList
        .map((_item, i) => ref.current?.children[i].clientWidth || 0)
        .reduce((a, b) => a + b);

    const handleResize = () => {
      if (!isMobile) {
        setShowSmall(
          handleShowSmallForSubNavigationBarResize(
            totalLinkItemWidth,
            ref.current?.offsetWidth || 0
          )
        );
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref.current, linkList, isMobile]);

  const handleDeselect = () => {
    setSelectedIndex(-1);
  };

  const renderListItem = (item: SubNavLinkItem, i: number) => {
    const link = item.link;
    if (typeof link === "string") {
      return (
        <a href={link} data-testid="subNavBarLink" className={"top-nav"}>
          {item.text}
        </a>
      );
    } else if (typeof link === "function") {
      return (
        <a
          href="#"
          data-testid="subNavBarLink"
          className={"top-nav"}
          onClick={(e) => {
            e.preventDefault();
            link();
          }}
        >
          {item.text}
        </a>
      );
    } else {
      return (
        <a
          data-testid="subNavBarLink"
          className={classNames("dropdown top-nav", {
            selected: selectedIndex === i,
          })}
          onClick={(e) => {
            e.preventDefault();
            setSelectedIndex(selectedIndex === i ? -1 : i);
          }}
        >
          {item.text}{" "}
          {selectedIndex === i ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </a>
      );
    }
  };

  return (
    <div className={classes.root}>
      {!isMobile && selectedIndex !== -1 && showSmall === false && (
        <div
          className={classes.linkBackdrop}
          onClick={handleDeselect}
          data-testid="subNavBarBackdrop"
        ></div>
      )}
      {(isMobile || showSmall === true) && (
        <SmallSubNavigationBar {...{ linkList, title, activePageName }} />
      )}
      <div
        className={classes.linkContainer}
        style={{
          visibility: showSmall === true ? "hidden" : "visible",
        }}
        data-testid="subNavBarRoot"
        ref={ref}
      >
        {linkList &&
          linkList.map((item, i) => (
            <div className={classes.link} key={item.text}>
              <Paper
                elevation={selectedIndex === i ? 1 : 0}
                className={classNames(classes.linkItem, {
                  [classes.linkItemSelected]: selectedIndex === i,
                })}
              >
                <Typography>
                  <b>{renderListItem(item, i)}</b>
                </Typography>
                {typeof item.link !== "string" &&
                  typeof item.link !== "function" &&
                  !!item.link.map &&
                  selectedIndex === i && (
                    <Paper elevation={1} className={classes.linkContent}>
                      {item.link.map((subLink, subIteration) => (
                        <Typography key={subIteration}>
                          <b>
                            <a
                              href={subLink.link as string}
                              data-testid="subNavSubLink"
                            >
                              {subLink.text}
                            </a>
                          </b>
                        </Typography>
                      ))}
                    </Paper>
                  )}
              </Paper>
            </div>
          ))}
      </div>
    </div>
  );
};
