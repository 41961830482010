import { makeStyles } from "@mui/styles";
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ContentCard } from "../SlidingContentCard";
import { ContentCardProps } from "../SlidingContentCard/ContentCard";
import { LabeledIcon } from "../LabeledIcon/index";
import SingleBedIcon from "@mui/icons-material/SingleBed";
import GroupIcon from "@mui/icons-material/Group";
import { useMediaQuery, useTheme } from "@mui/material";
import classNames from "classnames";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme) => ({
    root: {
      height: "100%",
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0,
      ),
      "@media (max-width:600px)": {
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0,
        ),
      },
    },
    overlay: {
      display: "flex",
      height: "100%",
      alignItems: "flex-end",
    },
    overlayRoot: {
      alignItems: "flex-start",
      flexDirection: "column",
    },
    overlayInfo: {
      padding: theme.spacing(2),
      color: theme.palette.background.paper,
      width: "100%",
      background: `linear-gradient(to top, rgba(0,0,0,0.6) 5%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%)`,
      "& svg": {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(-0.75),
        width: 16,
      },
    },
    overlayInfoRoot: {
      marginTop: "auto",
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    logos: {
      marginTop: theme.spacing(2),
    },
    overlayInfoTop: {
      padding: theme.spacing(1.2, 1.5),
      color: theme.palette.background.paper,
      width: "100%",
      background: `linear-gradient(to top, rgba(0,0,0,0.01) 5%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.7) 100%)`,
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(-0.65),
        width: 16,
      },
      "& span": {
        lineHeight: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1.2, 1),
      },
    },
  }))();

export type FeaturedIcon = {
  icon: JSX.Element;
  label: string;
};

export type CabinCardProps = {
  title: string;
  imgUrl: string;
  imageCaption?: string;
  description: JSX.Element;
  occupancyLabel: string;
  bedroomLabel: string;
  logos?: FeaturedIcon[];
  callToActionText: string;
  callToActionUrl?: string;
  showPaddingTop?: boolean;
  showPaddingBottom?: boolean;
  locationPinOnLandingPageCards?: boolean;
};

export const CabinCard: React.FC<CabinCardProps> = ({
  title,
  imgUrl,
  imageCaption,
  description,
  occupancyLabel,
  bedroomLabel,
  logos,
  callToActionText,
  callToActionUrl,
  showPaddingTop = false,
  showPaddingBottom = false,
  locationPinOnLandingPageCards,
}: CabinCardProps) => {
  const classes = useStyles(showPaddingTop, showPaddingBottom);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const contentCardProps: ContentCardProps = {
    media: {
      imgUrl,
      overlayContent: (
        <Typography
          component="div"
          className={classNames(classes.overlay, {
            [classes.overlayRoot]: locationPinOnLandingPageCards,
          })}
          data-testid="cabin-overlay"
        >
          {locationPinOnLandingPageCards && imageCaption && (
            <div className={classes.overlayInfoTop}>
              <span>
                <LocationOnIcon />
              </span>
              <span>{imageCaption}</span>
            </div>
          )}
          <div
            className={classNames(classes.overlayInfo, {
              [classes.overlayInfoRoot]: locationPinOnLandingPageCards,
            })}
          >
            <Grid container spacing={{ xs: 0.5, sm: 2, md: 3 }}>
              <Grid item>
                <GroupIcon />
                <span data-testid="cabin-guests">{occupancyLabel} guests</span>
              </Grid>
              <Grid item>
                <SingleBedIcon />
                <span data-testid="cabin-bedrooms">
                  {bedroomLabel} bedrooms
                </span>
              </Grid>
            </Grid>
          </div>
        </Typography>
      ),
    },
    callToAction: {
      label: callToActionText,
      url: callToActionUrl,
    },
  };
  return (
    <div className={classes.root}>
      <ContentCard {...contentCardProps} data-testid="root">
        <Typography variant="h5" gutterBottom data-testid="cabin-title">
          {title}
        </Typography>
        {description}
        {logos && (
          <div className={classes.logos} data-testid="cabin-logos">
            <Grid
              container
              spacing={isMobile ? 1 : 3}
              justifyContent="center"
              data-testid="cabin-logos-container"
            >
              {logos.map((logo, i) => (
                <Grid key={i} item>
                  <LabeledIcon text={logo.label} icon={logo.icon} />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </ContentCard>
    </div>
  );
};
