import { FHInlineSvg } from "../../components/SemanticTheme/FHInlineSvg";
import React from "react";
import RichText from "./RichText";
import { UsefulInfoBanner } from "../../components/SemanticTheme/UsefulInfoBanner";
import {
  updateAbsoluteLink,
  defaultMaxWidths,
  getResponsiveBannerImageUrl,
  useGenerateBannerImage,
} from "../../components/Utils";
import { withContent } from "../../utils/TemplateWithContent";
import { UsefulInfoBannerEntity } from "src/graphql/generated-strapi/types";

type UsefulInfoBannerComponent = {
  pageImgQuality?: number;
} & UsefulInfoBannerEntity;

const UsefulInfoBannerComponent: React.FC<UsefulInfoBannerComponent> = (
  props: UsefulInfoBannerComponent,
): JSX.Element => {
  const title: string = props?.attributes?.Title as string;
  const ctaLink = updateAbsoluteLink(
    props?.attributes?.Link?.data?.attributes?.PageRoute,
  );
  const imgQuality = props?.attributes?.ImageQuality || props?.pageImgQuality;

  const icon = props?.attributes?.Icon?.data ? (
    <FHInlineSvg
      src={props?.attributes?.Icon?.data?.attributes?.url}
      size={"24px"}
    />
  ) : undefined;

  let ctaUrl = undefined;
  let cta = undefined;

  if (ctaLink) {
    ctaUrl = ctaLink;
    cta = props?.attributes?.LinkText;
  }

  const mobileBannerImage =
    props?.attributes?.MobileBannerImage?.data?.attributes?.url;
  const bannerImage = props?.attributes?.BannerImage?.data?.attributes?.url;
  const imgUrl = getResponsiveBannerImageUrl(bannerImage, mobileBannerImage);

  const showPaddingTop = (props?.attributes?.ShowTopPadding ||
    false) as boolean;

  const showPaddingBottom = (props?.attributes?.ShowBottomPadding ||
    false) as boolean;

  return (
    <div style={{ padding: "60px 0" }}>
      <UsefulInfoBanner
        icon={icon}
        backgroundImageUrl={useGenerateBannerImage(
          imgUrl as string,
          defaultMaxWidths,
          imgQuality,
          true,
        )}
        callToActionUrl={ctaUrl}
        title={title}
        callToAction={cta}
        description={
          <RichText
            contentId="descriptionText"
            contentValue={props?.attributes?.Description}
            color={undefined}
          />
        }
        showPaddingTop={showPaddingTop}
        showPaddingBottom={showPaddingBottom}
      />
    </div>
  );
};

const UsefulInfoBannerComponentTemplate = (props) => {
  const content = props?.content?.infoBanners?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    UsefulInfoBannerComponent,
    content,
    undefined,
    pageImgQuality,
  );
};
export default UsefulInfoBannerComponentTemplate;
