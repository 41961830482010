import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren, Children, ReactNode } from "react";
import { ContentCardProps, ContentCard } from "./ContentCard";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
export * from "./ContentCard";

const useStyles = makeStyles((theme) => ({
  root: (props: SlidingContentCardsStyleProps) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    margin: "0 auto",
    maxWidth: props.maxWidth,
  }),
  slideRoot: {
    width: "100%",
  },
  gridList: {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    transform: "translateZ(0)",
    padding: 0,
    margin: 0,
    marginRight: -11,
  },
  gridListTile: {
    flexShrink: 0,
    padding: theme.spacing(1.1),
    "& > div": {
      overflow: "visible",
    },
  },
}));

type SlidingContentCardsStyleProps = {
  maxWidth?: string | number;
};

export type SlidingContentCardsProps = {
  contentCardProps?: ContentCardProps[];
  className?: string;
} & SlidingContentCardsStyleProps;

export const SlidingContentCards: React.FC<
  PropsWithChildren<SlidingContentCardsProps>
> = ({
  contentCardProps,
  maxWidth,
  children,
  className,
}: PropsWithChildren<SlidingContentCardsProps>) => {
  const classes = useStyles({ maxWidth });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const getColumns = () => {
    const amountOfCards =
      (contentCardProps && contentCardProps.length) ||
      (children && Children.count(children)) ||
      1;

    if (isMobile) {
      if (amountOfCards > 1) {
        return 1.2;
      }
    } else {
      if (amountOfCards > 3) {
        return 3.2;
      } else {
        return amountOfCards;
      }
    }
    return 1;
  };

  const wrapAround = (childOrProp: ReactNode, key: number) => (
    <ImageListItem
      style={{ width: `${100 / getColumns()}%` }}
      key={key}
      className={classes.gridListTile}
    >
      {childOrProp}
    </ImageListItem>
  );

  const wrappedChildren =
    children && Children.map(children, (child, i) => wrapAround(child, i));

  const wrappedContentCards =
    contentCardProps &&
    contentCardProps.map((cardProps, i) =>
      wrapAround(<ContentCard {...cardProps} />, i)
    );

  return (
    <div
      className={classNames(classes.root, classes.slideRoot, className)}
      data-testid="sliding-root"
    >
      <ImageList
        rowHeight={"auto"}
        className={classes.gridList}
        cols={getColumns()}
        gap={0}
        data-testid="sliding-grid-list"
      >
        <>{wrappedChildren ? wrappedChildren : wrappedContentCards}</>
      </ImageList>
    </div>
  );
};
