import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren } from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { FHButton } from "../FHButton";
import classNames from "classnames";
import { Skeleton } from "@mui/material";
import { FHNextImage, ImageLoader } from "../FHNextImage";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardActions: {
    marginTop: "auto",
    padding: theme.spacing(3),
    paddingTop: 0,
    "& .MuiButton-root": {
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
  },
  cardContent: {
    padding: theme.spacing(3),
    "& p": {
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  btn: {
    width: "100%",
  },
  telephone: {
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: 600,
  },
  image: {
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 0,
    paddingTop: "56.25%", // 16:9
    overflow: "hidden",
    position: "relative",
  },
  skeleton: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  imageContent: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  mediaOverlayContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    zIndex: 1000,
    top: 0,
    pointerEvents: "none",
  },
  mediaContainer: {
    position: "relative",
    color: theme.palette.background.default,
  },
}));

export type ContentCardProps = {
  media: {
    imgUrl?: string;
    overlayContent?: JSX.Element;
    title?: string;
  };
  content?: JSX.Element;
  callToAction?: {
    label: string;
    url?: string;
    telephone?: string;
  };
  className?: string;
  imageLoader?: ImageLoader;
};

export const ContentCard: React.FC<PropsWithChildren<ContentCardProps>> = ({
  media,
  content,
  callToAction,
  children,
  className,
  imageLoader,
}: PropsWithChildren<ContentCardProps>) => {
  const classes = useStyles();
  const [isImgLoading, setIsImgLoading] = React.useState(true);

  const handleImageLoad = () => {
    setIsImgLoading(false);
  };

  return (
    <Card
      className={classNames(classes.card, className)}
      data-testid="content-card-root"
    >
      {media.imgUrl && (
        <>
          <div className={classes.mediaContainer}>
            {media.overlayContent && (
              <div
                className={classes.mediaOverlayContainer}
                data-testid="media-overlay"
              >
                {media.overlayContent}
              </div>
            )}
            <div
              className={classes.imageContent}
              data-testid="card-image-content"
            >
              <div className={classes.image}>
                {isImgLoading && (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    className={classes.skeleton}
                  />
                )}
                <FHNextImage
                  src={media.imgUrl}
                  alt={media.title}
                  layout="fill"
                  objectFit="cover"
                  loading="lazy"
                  onLoad={handleImageLoad}
                  loader={imageLoader}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <CardContent data-testid="card-content" className={classes.cardContent}>
        {content || children}
      </CardContent>
      {callToAction && callToAction?.label && (
        <CardActions className={classes.cardActions} data-testid="card-cta">
          <FHButton
            fullWidth={true}
            className={classNames({
              [classes.telephone]: !!callToAction.telephone,
            })}
            fhStyle={callToAction.telephone ? "tertiary" : "secondary"}
            disabled={!callToAction.url && !callToAction.telephone}
            href={
              callToAction.telephone
                ? `tel:${callToAction.telephone.replace(" ", "")}`
                : callToAction.url
            }
            data-testid="card-cta-button"
          >
            {callToAction.label}
            {callToAction.telephone && <>&nbsp;{callToAction.telephone}</>}
          </FHButton>
        </CardActions>
      )}
    </Card>
  );
};
