import React from "react";
import Typography from "@mui/material/Typography";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { makeStyles } from "@mui/styles";
import { TypographyVariant } from "@mui/material";
import classNames from "classnames";
import { useTheme } from "@mui/material";

export type CallToActionProps = {
  callToAction: string;
  callToActionSubheader?: string;
  callToActionUrl: string;
  textClassName?: string;
  subheaderTextClassName?: string;
  chevronClassName?: string;
  showChevron?: boolean;
  showUnderline?: boolean;
  variant?: TypographyVariant;
  usePrimaryLightColor?: boolean;
};

const defaultProps = {
  callToAction: "Call To Action",
  showChevron: true,
  variant: "h4",
  showUnderline: true,
  usePrimaryLightColor: false,
};

const useStyles = makeStyles((theme) => ({
  callToAction: {
    color: theme.palette.text.primary,
    display: "inline-block",
    "& svg": {
      fill: "currentColor",
    },
    "&:hover": {
      color: theme.palette.primary.main,
      "& $text, & $subHeading": {
        color: theme.palette.primary.main,
      },
    },
    "&:focus, &:active": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[700],
      "& $text, & $subHeading": {
        color: theme.palette.text.primary,
      },
    },
    "& $text, & $subHeading": {
      color: theme.palette.text.primary,
    },
  },
  callToActionPrimary: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      "& $text, & $subHeading": {
        color: theme.palette.primary.main,
      },
    },
    "&:focus, &:active": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.grey[700],
      "& $text, & $subHeading": {
        color: theme.palette.secondary.main,
      },
    },
    "& $textPrimary, & $subHeadingPrimary": {
      color: theme.palette.secondary.main,
    },
  },
  alignChevron: {
    display: "flex",
    placeContent: "center center",
    alignItems: "center",
  },
  text: {},
  textPrimary: {},
  subHeading: {},
  subHeadingPrimary: {},
}));

export const CallToAction: React.FC<CallToActionProps> = (props) => {
  const {
    callToAction,
    callToActionSubheader,
    callToActionUrl,
    showChevron,
    showUnderline,
    variant,
    textClassName,
    subheaderTextClassName,
    chevronClassName,
    usePrimaryLightColor,
  } = Object.assign({}, defaultProps, props);

  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {callToActionUrl ? (
        <a
          href={callToActionUrl}
          className={classNames(classes.callToAction, {
            [classes.callToActionPrimary]: usePrimaryLightColor,
          })}
          style={{ textDecoration: showUnderline ? "underline" : "none" }}
        >
          <div className={classes.alignChevron}>
            <div>
              <Typography
                variant={variant}
                className={classNames(classes.text, textClassName, {
                  [classes.textPrimary]: usePrimaryLightColor,
                })}
              >
                <strong>{callToAction}</strong>
              </Typography>
              {callToActionSubheader && (
                <Typography
                  className={classNames(
                    classes.subHeading,
                    subheaderTextClassName,
                    {
                      [classes.subHeadingPrimary]: usePrimaryLightColor,
                    }
                  )}
                  style={{
                    margin: "0",
                  }}
                >
                  {callToActionSubheader}
                </Typography>
              )}
            </div>
            {showChevron && <ChevronRight className={chevronClassName} />}
          </div>
        </a>
      ) : (
        <>
          <Typography
            variant={variant}
            className={textClassName}
            style={{
              color: usePrimaryLightColor ? theme.palette.secondary.main : "",
            }}
          >
            {callToAction}
          </Typography>
          {callToActionSubheader && (
            <p
              className={subheaderTextClassName}
              style={{
                margin: "0",
              }}
            >
              {callToActionSubheader}
            </p>
          )}
        </>
      )}
    </>
  );
};
