import { makeStyles } from "@mui/styles";
import React from "react";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { makeHtml, processRichText } from "../../components/Utils";
import { Container, Grid } from "@mui/material";
import { withContent } from "../../utils/TemplateWithContent";
import { FaqComponentEntity } from "src/graphql/generated-strapi/types";

const useStyles = () =>
  makeStyles(() => ({
    richTextAreaRoot: {
      "& img": {
        maxWidth: "100%",
      },
    },
  }))();
interface AdditionalProps {
  additionalProps?: { [key in string]: string | boolean };
}
const FAQComponent: React.FC<FaqComponentEntity & AdditionalProps> = (
  props: FaqComponentEntity & AdditionalProps,
): JSX.Element => {
  const FAQQuestion = processRichText(props?.attributes?.Question);
  const FAQAnswer = processRichText(props?.attributes?.Answer);

  const classes = useStyles();

  return (
    <Container>
      <Grid className={classes.richTextAreaRoot}>
        <RichContentArea>
          <div dangerouslySetInnerHTML={makeHtml(FAQQuestion)} />
          <div dangerouslySetInnerHTML={makeHtml(FAQAnswer)} />
        </RichContentArea>
      </Grid>
    </Container>
  );
};

const FAQComponentTemplate = (props) => {
  const content = props?.content?.faq_comp?.data;
  const additionalProps = props?.additionalProps;

  return withContent(FAQComponent, content, additionalProps);
};
export default FAQComponentTemplate;
