import { withStyles } from "@mui/styles";
import { Container, Grid } from "@mui/material";

export const NormalizedContainer = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}))(Container);

export const NormalizedGrid = withStyles((theme) => ({
  container: {
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100%",
      margin: "0",
    },
  },
}))(Grid);
