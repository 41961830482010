import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren } from "react";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const useStyles = makeStyles((theme) => ({
  root: (props: TeamReviewSnippetStyleProps) => ({
    maxWidth: props.maxWidth,
  }),
  cta: {
    color: theme.palette.secondary.main,
  },
  teamReviewAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  teamReviewTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  teamReviewReview: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  teamReviewCta: {
    textDecoration: "none",
    fontSize: "18px",
    color: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

type TeamReviewSnippetStyleProps = {
  maxWidth?: string | number;
};

export type TeamReviewSnippetProps = {
  imageUrl?: string;
  title?: string;
  subTitle?: string;
  quote?: string;
  cta?: string;
  ctaLink?: string;
} & TeamReviewSnippetStyleProps;

export const TeamReviewSnippet: React.FC<TeamReviewSnippetProps> = ({
  imageUrl,
  title,
  subTitle,
  quote,
  cta,
  ctaLink,
  maxWidth,
}: PropsWithChildren<TeamReviewSnippetProps>) => {
  const classes = useStyles({ maxWidth });

  return (
    <Container className={classes.root}>
      <Grid container data-testid="teamReviewSnippetRoot">
        <Grid>
          <Avatar
            src={imageUrl}
            className={classes.teamReviewAvatar}
            data-testid="teamReviewSnippetAvatarImg"
          />
        </Grid>

        <Grid>
          <div>
            <Typography
              variant={"body1"}
              className={classes.teamReviewTitle}
              data-testid="teamReviewSnippetTitle"
            >
              {title}
            </Typography>
          </div>
          <div>
            <Typography
              variant={"body1"}
              data-testid="teamReviewSnippetSubtitle"
            >
              {" "}
              {subTitle}{" "}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant={"h4"}
            className={classes.teamReviewReview}
            data-testid="teamReviewSnippetQuote"
          >
            {quote}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            <a
              className={classes.teamReviewCta}
              href={ctaLink}
              data-testid="teamReviewSnippetCta"
            >
              <strong>
                {cta}
                {ctaLink && <NavigateNextIcon />}
              </strong>
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
