import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import FHButton from "../FHButton";
import FHLink from "../FHLink";
import { Chip, ChipBackground } from "../Chip";
import classnames from "classnames";
import { FHNextImage, ImageLoader } from "../FHNextImage";
import { useIsClient } from "src/hooks/hooks";

const useStyles = (
  bannerColor?: string,
  overlayOpacity?: number,
  richTextColor?: string,
  textAlignment?: "left" | "center" | "right",
  ctaLinkAlignment?: "left" | "center" | "right",
  showChip?: boolean,
  isImageAsPlaceholder?: boolean,
) =>
  makeStyles((theme) => ({
    root: {
      position: "relative",
      width: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      minHeight: 160,
      boxSizing: "border-box",
      padding: theme.spacing(2.5, 3),
      paddingTop:
        textAlignment === "left" && showChip
          ? theme.spacing(6)
          : theme.spacing(2.5),
      color: theme.palette.common.white,
      backgroundColor: bannerColor ? bannerColor : theme.palette.primary.dark,
      display: "flex",
      alignItems: "center",
      justifyContent:
        (textAlignment === "left" && "left") ||
        (textAlignment === "center" && "center") ||
        (textAlignment === "right" && "right"),

      [theme.breakpoints.down("md")]: {
        justifyContent: "left",
        paddingTop: showChip ? theme.spacing(6) : theme.spacing(2.5),
      },
      "&:before": {
        content: "''",
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: `rgba(0, 0, 0, ${
          overlayOpacity ? overlayOpacity : 0
        }%)`,
      },
    },
    cardContainer: {
      position: "relative",
      zIndex: 1,
      width:
        textAlignment === "center"
          ? "100%"
          : "50%" && isImageAsPlaceholder
          ? "100%"
          : "50%",
      textAlign: textAlignment === "center" ? "center" : "left",
      color: richTextColor ? richTextColor : theme.palette.common.white,
      display: textAlignment === "center" ? "block" : "flex",
      alignItems: "center",
      justifyContent: textAlignment === "center" ? "center" : "left",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        display: isImageAsPlaceholder ? "flex" : "block",
        textAlign:
          textAlignment === "center" && isImageAsPlaceholder
            ? "left"
            : "left" && textAlignment === "center" && !isImageAsPlaceholder
            ? "center"
            : "left",
      },
      "& p": {
        marginBottom: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
        color: richTextColor ? richTextColor : theme.palette.common.white,
      },
      "& h1, & h2, & h3, & h4": {
        fontFamily: theme.typography.h1.fontFamily,
        color: richTextColor ? richTextColor : theme.palette.common.white,
      },
      "& h3": {
        marginTop: theme.spacing(0.5),
      },
    },
    logo: {
      minWidth: 61,
      marginRight: textAlignment === "center" ? 0 : theme.spacing(3),
      marginBottom: textAlignment === "center" ? theme.spacing(1) : 0,
      display: isImageAsPlaceholder ? "none" : "block",
      [theme.breakpoints.down("md")]: {
        marginRight:
          textAlignment === "center" && isImageAsPlaceholder
            ? theme.spacing(3)
            : 0,
        display: "block",
        marginBottom: 0,
      },
    },
    content: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: ctaLinkAlignment === "center" ? "center" : "left",
      "& button": {
        marginTop: theme.spacing(1),
      },
    },
    richContent: {
      flexBasis: "100%",
    },
    richContentCenter: {
      "& >div": {
        "& >div": {
          "& >*": {
            display: "flex !important",
            alignItems: "center",
            justifyContent: "center",
          },
        },
      },
      "& img": {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
      "& p": {
        display: "flex !important",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    ctaLink: {
      fontFamily: theme.typography.h2.fontFamily,
      marginLeft: ctaLinkAlignment === "right" ? "auto" : 0,
      marginRight: ctaLinkAlignment === "left" ? "auto" : 0,
    },
    ctaLinkPrimary: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
    showPaddingTop: {
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(3.5),
      },
    },
    showPaddingBottom: {
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(3.5),
      },
    },
  }))();

type ImageBannerProps = {
  bannerImage?: string;
  bannerColor?: string;
  richContent?: JSX.Element;
  richTextColor?: string;
  overlayOpacity?: number;
  textAlignment?: "left" | "center" | "right";
  logo?: string;
  ctaButtonLink?: string;
  ctaButtonLabel?: string;
  buttonStyle?: "primary" | "secondary" | "tertiary";
  ctaLink?: string;
  ctaLinkLabel?: string;
  ctaLinkStyle?: "primary" | "secondary" | "inherit";
  showChip?: boolean;
  chipLabel?: string;
  chipTextColour?: string;
  chipColor?: string;
  chipBackground?: ChipBackground;
  isImageAsPlaceholder?: boolean;
  ctaLinkIcon?: JSX.Element;
  ctaUnderlineNone?: boolean;
  ctaLinkAlignment?: "left" | "center" | "right";
  showPaddingTop?: boolean;
  showPaddingBottom?: boolean;
  imageLoader?: ImageLoader;
};

export const ImageBanner: React.FC<ImageBannerProps> = ({
  bannerImage,
  bannerColor,
  richContent,
  richTextColor,
  overlayOpacity,
  textAlignment = "left",
  logo,
  ctaButtonLink,
  ctaButtonLabel,
  buttonStyle,
  ctaLink,
  ctaLinkLabel,
  ctaLinkStyle,
  showChip,
  chipLabel,
  chipTextColour,
  chipBackground,
  chipColor,
  isImageAsPlaceholder,
  ctaLinkIcon,
  ctaUnderlineNone,
  ctaLinkAlignment = "left",
  showPaddingTop = false,
  showPaddingBottom = false,
  imageLoader,
}: PropsWithChildren<ImageBannerProps>) => {
  const classes = useStyles(
    bannerColor,
    overlayOpacity,
    richTextColor,
    textAlignment,
    ctaLinkAlignment,
    showChip,
    isImageAsPlaceholder,
  );
  const isClient = useIsClient();

  return (
    <div
      className={classnames(classes.root, {
        [classes.showPaddingTop]: showPaddingTop,
        [classes.showPaddingBottom]: showPaddingBottom,
      })}
    >
      {bannerImage && isClient && (
        <FHNextImage
          src={bannerImage}
          alt="Image Banner"
          layout="fill"
          objectFit="cover"
          loading="lazy"
          loader={imageLoader}
        />
      )}
      {showChip && (
        <Chip
          pinned
          label={chipLabel}
          textColourOverride={chipTextColour}
          background={chipBackground}
          colour={chipColor}
        />
      )}
      <div className={classes.cardContainer}>
        {logo && isClient && (
          <div className={classes.logo}>
            <FHNextImage
              src={logo}
              alt="Certified logo"
              width={61}
              height={103}
              loading="lazy"
              loader={imageLoader}
            />
          </div>
        )}
        <div className={classes.content}>
          <div
            className={classnames(
              classes.richContent,
              isImageAsPlaceholder && classes.richContentCenter,
            )}
          >
            {richContent}
          </div>
          {ctaButtonLabel && ctaButtonLink && (
            <FHButton fhStyle={buttonStyle} fhSize="md" href={ctaButtonLink}>
              {ctaButtonLabel}
            </FHButton>
          )}
          {ctaLinkLabel && ctaLink && (
            <FHLink
              className={classnames(
                classes.ctaLink,
                ctaLinkStyle === "primary" && classes.ctaLinkPrimary,
              )}
              customIcon={ctaLinkIcon}
              title={ctaLinkLabel}
              underlineNone={ctaUnderlineNone}
              color={ctaLinkStyle}
              URL={ctaLink}
            />
          )}
        </div>
      </div>
    </div>
  );
};
