import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

export enum ChipBackground {
  colour = "Colour",
  white = "White",
  clear = "Clear",
}

const useStyles = (
  pinned: boolean,
  colour: string,
  background: ChipBackground,
  enhanceBorderRadius: boolean,
  isStartIconClickable: boolean,
  isEndIconClickable: boolean,
  top?: number,
  left?: number,
  uppercase?: boolean,
  textColourOverride?: string,
  iconColour?: string,
) =>
  makeStyles((theme) => ({
    chip: {
      textAlign: "center",
      textTransform: uppercase ? "uppercase" : "none",
      zIndex: 2,
      border: `1px solid ${
        background === ChipBackground.colour ? undefined : colour
      }`,
      backgroundColor:
        background === ChipBackground.colour
          ? colour
          : background === ChipBackground.white
            ? theme.palette.common.white
            : "transparent",
      color: !!textColourOverride
        ? textColourOverride
        : background === ChipBackground.colour
          ? theme.palette.common.white
          : colour,
      borderRadius: enhanceBorderRadius
        ? pinned
          ? "0px 20px 20px 0px"
          : "20px"
        : pinned
          ? "0px 16px 16px 0px"
          : "16px",
      opacity: 1,
      padding: theme.spacing(0.7, 1.5),
      top: pinned ? (!!top ? top : 15) : undefined,
      left: pinned ? (!!left ? left : -3) : undefined,
      whiteSpace: "nowrap",
      fontSize: "14px",
      lineHeight: "18px",
      position: pinned ? "absolute" : undefined,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    startChipIcon: {
      cursor: isStartIconClickable ? "pointer" : "default",
      paddingRight: theme.spacing(1),
      "& svg": {
        verticalAlign: "middle",
        color: iconColour ?? "currentColor",
        width: 20,
        opacity: !!iconColour ? undefined : 0.6,
      },
    },
    endChipIcon: {
      marginLeft: "auto",
      cursor: isEndIconClickable ? "pointer" : "default",
      paddingLeft: theme.spacing(0.5),
      "& svg": {
        verticalAlign: "middle",
        color: iconColour ?? "currentColor",
        width: 20,
        opacity: !!iconColour ? undefined : 0.6,
      },
    },
  }))();

export type ChipProps = {
  label: string | JSX.Element;
  top?: number;
  left?: number;
  colour?: string;
  pinned?: boolean;
  background?: ChipBackground;
  uppercase?: boolean;
  textColourOverride?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  onClickStartIcon?: () => void;
  onClickEndIcon?: () => void;
  isBold?: boolean;
  enhanceBorderRadius?: boolean;
  iconColour?: string;
};

export const Chip: React.FC<ChipProps> = ({
  label,
  top,
  left,
  colour = "#22A672",
  pinned = false,
  background = ChipBackground.white,
  uppercase = false,
  textColourOverride,
  startIcon,
  endIcon,
  isBold = true,
  onClickEndIcon,
  onClickStartIcon,
  enhanceBorderRadius = false,
  iconColour,
}: ChipProps) => {
  const classes = useStyles(
    pinned,
    colour,
    background,
    enhanceBorderRadius,
    !!onClickStartIcon,
    !!onClickEndIcon,
    top,
    left,
    uppercase,
    textColourOverride,
    iconColour,
  );

  return (
    <Typography component="div" className={classes.chip} data-testid="chipRoot">
      {startIcon && (
        <div
          className={classes.startChipIcon}
          onClick={!!onClickStartIcon ? onClickStartIcon : undefined}
        >
          {startIcon}
        </div>
      )}
      {typeof label === "string" && isBold ? <strong>{label}</strong> : label}
      {endIcon && (
        <div className={classes.endChipIcon} onClick={onClickEndIcon}>
          {endIcon}
        </div>
      )}
    </Typography>
  );
};
