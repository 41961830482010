import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SnapBar } from "../../components/SemanticTheme/SnapBar";
import {
  createAddCardReset,
  changeCardForUpcomingPaymentsResetAction,
  resetRemovedCardAction,
} from "@/store/actions";
import { FHNextImage } from "@components/SemanticTheme/FHNextImage";
import { SnapBar as SnapBarProps } from "src/graphql/generated-strapi/types";

export type SnapBarOverrideProps = {
  showBar?: boolean;
};

interface FHSnapBarProps extends SnapBarOverrideProps {
  content: SnapBarProps;
  overrideProps?: SnapBarOverrideProps;
}

const SnapBarComponent: React.FC<FHSnapBarProps> = ({
  content,
  overrideProps,
}): JSX.Element => {
  const dispatch = useDispatch();

  const snapBarCloseIcon = (
    <FHNextImage
      src={content?.CloseIcon?.data?.attributes?.url || ""}
      alt="close-icon"
      width={20}
      height={20}
    />
  );

  const [showBar, setShowBar] = useState(false);

  useEffect(() => {
    overrideProps?.showBar && setShowBar(overrideProps?.showBar || false);
    if (content?.MessageDisplayInSec) {
      setTimeout(() => {
        setShowBar(false);
        dispatch(createAddCardReset());
        dispatch(changeCardForUpcomingPaymentsResetAction());
        dispatch(resetRemovedCardAction());
      }, Number(content?.MessageDisplayInSec) * 1000);
    }
  }, [overrideProps?.showBar]);

  return (
    <SnapBar
      title={content?.ActionMessage}
      message={content?.Summary}
      actionButton={snapBarCloseIcon}
      show={showBar}
      onCloseButtonClick={(showBar: boolean) => setShowBar(!showBar)}
    />
  );
};

export default SnapBarComponent;
