import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

const useStyles = makeStyles((theme) => ({
  root: (props: CardInformationStyleProps) => ({
    margin: theme.spacing(props.spacing || 0, 0),
  }),
  icon: {
    position: "absolute",
  },
  lineContainers: {
    paddingLeft: 34,
    "& p": {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
}));

type CardInformationStyleProps = {
  spacing?: string | number;
};

type CardInformationTextProps = {
  Icon?: React.FC;
};

export type CardInformationTextStyleProps = CardInformationStyleProps &
  CardInformationTextProps;

export const CardInformationText: React.FC<
  PropsWithChildren<CardInformationTextStyleProps>
> = ({
  Icon,
  children,
  spacing = 4,
}: PropsWithChildren<CardInformationTextStyleProps>) => {
  const classes = useStyles({ spacing });
  return (
    <div data-testid="card-info-root" className={classes.root}>
      <Typography component="div" variant="body1">
        <span data-testid="icon-container" className={classes.icon}>
          {Icon && <Icon />}
        </span>

        <div
          data-testid="children-container"
          className={classes.lineContainers}
        >
          {children && children}
        </div>
      </Typography>
    </div>
  );
};
