import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import { withContent } from "../../utils/TemplateWithContent";
import { makeContentItemAbTestIdAttribute } from "../../utils/AbTestIdAndPaddingContainer";
import {
  updateAbsoluteLink,
  makeHtml,
  processRichText,
  useGenerateResponsiveImageUrl,
} from "../../components/Utils";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { defaultEventCardWidths } from "../../utils/common";
import {
  EventCardProps,
  EventCards,
} from "../../components/SemanticTheme/EventCard";
import { EventCardsContainerEntity } from "src/graphql/generated-strapi/types";

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme) => ({
    root: {
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0,
      ),
      "@media (max-width:600px)": {
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0,
        ),
      },
    },
  }))();

const makeEventCardCmsData = (content: any) => ({
  title: content?.["Title"] as string,
  description: (
    <RichContentArea>
      <div
        {...makeContentItemAbTestIdAttribute(content)}
        dangerouslySetInnerHTML={makeHtml(
          processRichText(content?.["Description"]),
        )}
      />
    </RichContentArea>
  ),
  imgUrl: content?.["Image"]?.data?.attributes?.url as string,
  isoDateFrom: content?.["StartDate"] as string,
  isoDateTo: content?.["EndDate"] as string,
  callToActionText: content?.["PrimaryCallToActionText"] as string,
  callToActionUrl: content?.["PrimaryCallToActionRef"]?.data?.attributes
    ?.PageRoute
    ? (content?.["PrimaryCallToActionRef"]?.data?.attributes
        ?.PageRoute as string)
    : undefined,
  callToActionTelephone: content?.["PrimaryCallToActionTelephoneNumber"]
    ? (content?.["PrimaryCallToActionTelephoneNumber"] as string)
    : undefined,
  showPaddingTop: (content?.["showPaddingTop"] || false) as boolean,
  showPaddingBottom: (content?.["showPaddingBottom"] || false) as boolean,
  imageQuality: content?.["ImageQuality"] as number,
});

type CmsData = ReturnType<typeof makeCmsData>;
const makeCmsData = (content: any) => ({
  title: content?.["Title"] as string,
  description: content?.["SubTitle"]?.replace(
    /(<p[^>]+?>|<p>|<\/p>)/gim,
    "",
  ) as string,
  maxWidth: content?.["MaxWidth"] as number,
  callToAction:
    content?.["PrimaryCallToActionText"] &&
    content?.["PrimaryCallToActionRef"]?.data?.data?.attributes?.PageRoute
      ? {
          text: content?.["PrimaryCallToActionText"],
          url: updateAbsoluteLink(
            content?.["PrimaryCallToActionRef"]?.data?.attributes
              ?.PageRoute as string,
          ),
          fullWidth: content?.["PrimaryCallToActionFullWidth"],
        }
      : undefined,
  events: content?.["Cards"]?.data
    ? (makeEventCardCmsData(
        content?.["Cards"]?.data?.attributes,
      ) as EventCardProps)
    : undefined,
  showPaddingBottom: content?.["showPaddingBottom"] as boolean,
  showPaddingTop: content?.["showPaddingTop"] as boolean,
  imageQuality: content?.["ImageQuality"] as number,
});

type EventCardsContainerProps = {
  pageImgQuality?: number;
} & EventCardsContainerEntity;

const EventCardsContainerComponent: React.FC<EventCardsContainerProps> = (
  props: EventCardsContainerProps,
): JSX.Element => {
  const { attributes, pageImgQuality } = props;
  const cmsData: CmsData = makeCmsData(attributes);
  const classes = useStyles(
    cmsData?.showPaddingTop,
    cmsData?.showPaddingBottom,
  );
  const imgQuality = cmsData?.imageQuality || pageImgQuality;

  const generateImageUrl = (url: string | undefined, imgQuality: number) => {
    if (url) {
      return useGenerateResponsiveImageUrl(
        url,
        defaultEventCardWidths,
        imgQuality,
        true,
      );
    }
  };

  const getEvents = !!cmsData?.events
    ? [cmsData?.events]?.map((eventItem) => {
        return {
          ...eventItem,
          imgUrl: generateImageUrl(
            eventItem?.imgUrl,
            eventItem?.imageQuality || imgQuality,
          ),
        };
      })
    : [];

  return (
    <Container className={classes.root}>
      <EventCards
        title={cmsData?.title}
        description={cmsData?.description}
        events={getEvents}
        maxWidth={cmsData?.maxWidth}
        callToAction={cmsData?.callToAction}
      />
    </Container>
  );
};

const EventCardsContainerTemplate = (props) => {
  const content = props?.content?.eventCards?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    EventCardsContainerComponent,
    content,
    undefined,
    pageImgQuality,
  );
};
export default EventCardsContainerTemplate;
