import { makeStyles } from "@mui/styles";
import React from "react";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { makeHtml, processRichText } from "../../components/Utils";
import { Container, Grid } from "@mui/material";
import { withContent } from "../../utils/TemplateWithContent";
import { RichTextAreaEntity } from "src/graphql/generated-strapi/types";
import UKHolidayParksImg from "../../../src/components/assests/UK-HOLIDAY-PARKS.png";
import { updateImagesAndAddSpace } from "src/utils";

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme) => ({
    richTextAreaRoot: {
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0,
      ),
      "@media (max-width:600px)": {
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0,
        ),
      },
      "& img": {
        maxWidth: "100%",
      },
    },
  }))();
interface AdditionalProps {
  // cmsData: RichTextAreaEntity;
  additionalProps?: { [key in string]: string | boolean };
  isHomePage?: boolean;
}
const RichTextAreaComponent: React.FC<RichTextAreaEntity & AdditionalProps> = (
  props: RichTextAreaEntity & AdditionalProps,
): JSX.Element => {
  const showWhichIconFeaturesHomepage =
    props?.additionalProps?.showWhichIconFeaturesHomepage;
  const isHomePage = props?.isHomePage;
  const primaryContent = props?.attributes;
  const classes = useStyles(
    primaryContent?.ShowTopPadding || false,
    primaryContent?.ShowBottomPadding || false,
  );

  // New image to be added
  const newImageHtml = `
  &nbsp;&nbsp; <!-- Double black space -->
  <img src=${UKHolidayParksImg?.src} alt="uk-holiday-parks-logo" height="150" />
`;

  const expRitcText =
    showWhichIconFeaturesHomepage &&
    isHomePage &&
    primaryContent?.MainText?.includes("As featured in")
      ? updateImagesAndAddSpace(primaryContent?.MainText, newImageHtml)
      : primaryContent?.MainText;

  const richText =
    showWhichIconFeaturesHomepage &&
    isHomePage &&
    primaryContent?.MainText?.includes("As featured in")
      ? processRichText(expRitcText)
      : processRichText(primaryContent?.MainText);

  return (
    <Container>
      <Grid className={classes.richTextAreaRoot}>
        <RichContentArea styleH1AsH2={primaryContent?.StyleH1asH2 || false}>
          <div dangerouslySetInnerHTML={makeHtml(richText)} />
        </RichContentArea>
      </Grid>
    </Container>
  );
};

const RichTextAreaComponentTemplate = (props) => {
  const content = props?.content?.textAreas?.data;
  const pageImgQuality = props?.pageImgQuality;
  const isHomePage = props?.isHomePage;
  const additionalProps = props?.additionalProps;

  return withContent(
    RichTextAreaComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
    undefined,
    isHomePage,
  );
};
export default RichTextAreaComponentTemplate;
