import React from "react";
import { CabinCard } from "../../components/SemanticTheme/CabinCard";
import { FHInlineSvg } from "../../components/SemanticTheme/FHInlineSvg";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import {
  updateAbsoluteLink,
  defaultMaxWidths,
  makeHtml,
  useGenerateResponsiveImageUrl,
  processRichText,
} from "../../components/Utils";
import { cardImageWidths } from "../../utils/common";
import { CabinCard as CabinsCard } from "src/graphql/generated-strapi/types";

type CabinCardComponentProps = {
  pageImgQuality?: number;
  containerImgQuality?: number;
  locationPinOnLandingPageCards?: boolean;
} & CabinsCard;

const CabinCardComponent: React.FC<CabinCardComponentProps> = (
  props: CabinCardComponentProps,
): JSX.Element => {
  const cmsData = {
    title: props?.Title as string,
    ctaText: props?.PrimaryCallToActionText as string,
    ctaUrl: props?.PrimaryCallToActionRef?.data?.attributes?.PageRoute
      ? updateAbsoluteLink(
          props?.PrimaryCallToActionRef?.data?.attributes?.PageRoute,
        )
      : undefined,
    occupancyLabel: props?.OccupancyLabel as string,
    bedroomLabel: props?.BedroomLabel as string,
    showPaddingTop: (props?.showPaddingTop || false) as boolean,
    showPaddingBottom: (props?.showPaddingBottom || false) as boolean,
    imageCaption: props?.ImageCaption as string,
  };
  const imgQuality =
    props?.ImageQuality || props?.containerImgQuality || props?.pageImgQuality;
  const descriptionRichText = (
    <RichContentArea>
      <div
        dangerouslySetInnerHTML={makeHtml(
          processRichText(props?.Description as string),
        )}
      />
    </RichContentArea>
  );
  const logos = props?.Icons?.data;
  const imageUrl = props?.Image?.data?.attributes?.url;
  const generatedImage = imageUrl
    ? useGenerateResponsiveImageUrl(imageUrl, cardImageWidths, imgQuality, true)
    : "";

  const icons = logos
    ? logos.map((logo) => {
        const svgUrl = logo?.attributes?.Icon?.data?.attributes?.url as any;
        return {
          icon: <FHInlineSvg src={svgUrl} size="40px" />,
          label: logo?.attributes?.Label as string,
        };
      })
    : [];

  return (
    <CabinCard
      title={cmsData?.title}
      imgUrl={generatedImage}
      imageCaption={cmsData?.imageCaption}
      description={descriptionRichText}
      occupancyLabel={cmsData?.occupancyLabel}
      bedroomLabel={cmsData?.bedroomLabel}
      logos={icons}
      callToActionText={cmsData?.ctaText}
      callToActionUrl={cmsData?.ctaUrl}
      showPaddingTop={cmsData?.showPaddingTop}
      showPaddingBottom={cmsData?.showPaddingBottom}
      locationPinOnLandingPageCards={props?.locationPinOnLandingPageCards}
    />
  );
};

export default CabinCardComponent;
