import React from "react";
import { makeStyles } from "@mui/styles";
import {
  defaultMaxWidths,
  useGenerateResponsiveImageUrl,
  makeHtml,
  maxImgWidth,
  updateAbsoluteLink,
} from "../../components/Utils";
import { NavigationContainer } from "../../components/SemanticTheme/NavigationContainer";
import { LocationCard } from "../../components/SemanticTheme/LocationCard";
import { CallToActionCard } from "../../components/SemanticTheme/CallToActionCard";
import { Container, Grid } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InfoIcon from "@mui/icons-material/Info";
import ExploreIcon from "@mui/icons-material/Explore";
import { withContent } from "../../utils/TemplateWithContent";
import { makeContentItemAbTestIdFromValue } from "../../utils/AbTestIdAndPaddingContainer";
import { cardImageWidths } from "src/utils/common";
import { PlanYourVisitCardContainerEntity } from "src/graphql/generated-strapi/types";

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme) => ({
    root: {
      paddingTop: theme.spacing(showPaddingTop ? 3.5 : 0),
      paddingBottom: theme.spacing(showPaddingBottom ? 3.5 : 0),
      "@media (max-width:600px)": {
        paddingTop: theme.spacing(showPaddingTop ? 2.5 : 0),
        paddingBottom: theme.spacing(showPaddingBottom ? 2.5 : 0),
      },
    },
    cardsContainer: {
      maxWidth: "none",
    },
    address: {
      "& a": {
        color: theme.palette.success.light,
        textDecoration: "underline",
        "&:active": {
          color: theme.palette.secondary.dark,
        },
      },
    },
  }))();

type PlanYourVisitCardComponent = {
  pageImgQuality?: number;
  additionalProps?: { [key: string]: string | boolean };
} & PlanYourVisitCardContainerEntity;

const PlanYourVisitCardContainerComponent: React.FC<
  PlanYourVisitCardComponent
> = (props): JSX.Element => {
  const primaryContent = props?.attributes;
  const showPaddingTop = (primaryContent?.showPaddingTop || false) as boolean;
  const showPaddingBottom = (primaryContent?.showPaddingBottom ||
    false) as boolean;
  const classes = useStyles(showPaddingTop, showPaddingBottom);

  const title = primaryContent?.Title as string;
  let subtitle = primaryContent?.SubTitle as string;
  const imgQuality = props?.attributes?.ImageQuality || props?.pageImgQuality;
  const cardsMaxWidths = {
    xs: 600,
    sm: 900,
    md: 500,
    lg: 500,
    xl: 500,
  };

  const generateImageUrl = (
    url: string | undefined,
    maxWidths: maxImgWidth,
    imgQuality?: number,
  ) => {
    if (!!url) {
      return useGenerateResponsiveImageUrl(url, maxWidths, imgQuality, true);
    }
  };

  const mapImageResp = generateImageUrl(
    primaryContent?.MapImage?.data?.attributes?.url,
    defaultMaxWidths,
    imgQuality,
  );

  const mapImageUrl = primaryContent?.MapImage?.data?.attributes?.url || "";
  const addressHtmlString = primaryContent?.Address as string;
  const Address: React.FC = () => (
    <div
      className={classes.address}
      dangerouslySetInnerHTML={makeHtml(addressHtmlString)}
    />
  );

  const informationItems = primaryContent?.InformationItems?.data
    ? primaryContent?.InformationItems?.data?.map((contentItem, i) => ({
        Icon: InfoIcon,
        line1: contentItem?.attributes?.Line1 as string,
        line2: contentItem?.attributes?.Line2 as string,
        key: `informationItem-${i}`,
      }))
    : undefined;

  const gpsCoordItems = primaryContent?.GpsCoordinateItems?.data
    ? primaryContent?.GpsCoordinateItems?.data?.map((contentItem, i) => ({
        Icon: ExploreIcon,
        latitude: contentItem?.attributes?.DecimalLatitude,
        longitude: contentItem?.attributes?.DecimalLongitude,
        key: `gpsCoordItem-${i}`,
      }))
    : undefined;

  const cards = primaryContent?.Cards?.data
    ? primaryContent?.Cards?.data?.map((contentItem, i) => ({
        title: contentItem?.attributes?.CardTitle as string,
        imageUrl: generateImageUrl(
          contentItem?.attributes?.CardImage?.data?.attributes?.url,
          cardsMaxWidths,
          contentItem?.attributes?.ImageQuality || imgQuality,
        ),
        textHtmlString: contentItem?.attributes?.CardText as string,
        callToActionText: contentItem?.attributes?.CallToActionText as string,
        callToActionUrl:
          contentItem?.attributes?.CallToActionLink?.data?.attributes
            ?.PageRoute,
        abTestId: contentItem?.attributes?.ABTestId,
        showPaddingTop: (contentItem?.attributes?.ShowTopPadding ||
          false) as boolean,
        showPaddingBottom: (contentItem?.attributes?.ShowBottomPadding ||
          false) as boolean,
        key: `planYourVisit-primaryContentCard-${i}`,
      }))
    : undefined;

  const callToActionText = primaryContent?.PrimaryCallToActionText as string;
  const callToActionUrl = primaryContent?.Primaryact_Ref?.data?.attributes
    ?.PageRoute
    ? updateAbsoluteLink(
        primaryContent?.Primaryact_Ref?.data?.attributes?.PageRoute,
      )
    : undefined;

  return (
    <div className={classes.root}>
      <Container>
        <NavigationContainer
          ctaText={callToActionText}
          ctaURL={callToActionUrl}
          title={title}
          subTitle={subtitle}
          titleUrl={callToActionUrl}
        >
          <Grid item xs={12}>
            <LocationCard
              AddressIcon={LocationOnIcon}
              images={{
                imageUrl: generateImageUrl(
                  mapImageUrl,
                  defaultMaxWidths,
                  imgQuality,
                ),
                smallImageUrl: generateImageUrl(
                  mapImageUrl,
                  cardImageWidths,
                  imgQuality,
                ),
              }}
              Address={Address}
              gpsItems={gpsCoordItems}
              informationItems={informationItems}
            />
          </Grid>
        </NavigationContainer>
      </Container>
      <Container>
        <Grid
          className={classes.cardsContainer}
          container
          item
          xs={12}
          data-testid="content"
          spacing={2}
          justifyContent={"center"}
          alignItems={"stretch"}
          direction="row"
        >
          {cards
            ? cards.map((card, i) => (
                <Grid
                  item
                  xs={12}
                  md={cards.length > 2 ? 4 : 5}
                  key={`card-${i}`}
                >
                  <CallToActionCard
                    title={card.title}
                    imgUrl={card.imageUrl}
                    ctaLabel={card.callToActionText}
                    ctaUrl={card.callToActionUrl}
                    showPaddingTop={card.showPaddingTop}
                    showPaddingBottom={card.showPaddingBottom}
                  >
                    <div
                      {...makeContentItemAbTestIdFromValue(card.abTestId)}
                      dangerouslySetInnerHTML={makeHtml(card.textHtmlString)}
                    />
                  </CallToActionCard>
                </Grid>
              ))
            : ""}
        </Grid>
      </Container>
    </div>
  );
};

const PlanYourVisitCardContainerTemplate = (props) => {
  const content = props?.content?.planVisitCard?.data;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;
  return withContent(
    PlanYourVisitCardContainerComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
  );
};

export default PlanYourVisitCardContainerTemplate;
