import React, { PropsWithChildren, useEffect } from "react";

import { ImageModalLink } from "../ImageModalLink";
import { ImagePaper } from "../ImagePaper";
import { ModalImageProps } from "../ImageModalLink/index";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Theme,
} from "@mui/material";
import { mapIndividualImagesInEachRowToHaveSpecifiedRatio } from "./imageGalleryUtil";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: ImageGalleryStyleProps) => ({
    flexGrow: 1,
    maxWidth: props.maxWidth,
    margin: props.centered ? "0 auto" : "",
  }),
  label: {
    backgroundColor: "white",
    position: "absolute",
    right: 0,
    padding: theme.spacing(1),
    maxWidth: "80%",
    color: theme.palette.background.paper,
    textAlign: "right",
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  },
  paper: {
    backgroundSize: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  ratioForDesktopNotMobileContainer: {
    [theme.breakpoints.up("xs")]: {
      height: "0 !important",
      paddingTop: "100%",
    },
  },
  ratioForMobileNotDesktopContainer: {
    [theme.breakpoints.down("sm")]: {
      height: "0 !important",
      paddingTop: "100%",
    },
  },
  flexibleRatioContainer: {
    height: "100%",
    overflow: "hidden",
    position: "relative",
  },
  ratioContent: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
  },
  imagePaper: {
    cursor: "pointer",
  },
}));

export type ImageGallerySize = 1 | 2 | 6 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12;

export type ImageCard = {
  thumbnailUrl: string;
  fullUrl: string;
  altText: string;
  label?: string;
  xs?: ImageGallerySize;
  md?: ImageGallerySize;
  /*
  Setting up preferred ratios will set the rest of the the 
  ImageCards in that particular row with a flexible ratio
  */
  preferredDesktopRatio?: { x: number; y: number };
  preferredMobileRatio?: { x: number; y: number };
};

type ImageGalleryStyleProps = {
  maxWidth?: string | number;
  centered?: boolean;
};

export type ImageGalleryProps = {
  images: ImageCard[];
  xs?: ImageGallerySize;
  md?: ImageGallerySize;
  imagePaperClassName?: string;
} & ImageGalleryStyleProps;

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  xs = 6,
  md = 3,
  maxWidth,
  centered = true,
  imagePaperClassName,
}: PropsWithChildren<ImageGalleryProps>) => {
  const classes = useStyles({ maxWidth, centered });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileRatioMapping, setMobileRatioMapping] = React.useState<boolean[]>(
    [],
  );
  const [desktopRatioMapping, setDesktopRatioMapping] = React.useState<
    boolean[]
  >([]);
  const [clickedImagePosition, setClickedImagePosition] = React.useState<
    null | number
  >(null);

  const modalImages: ModalImageProps[] =
    images &&
    images.map((imgs) => {
      return { imageUrl: imgs.fullUrl || imgs.thumbnailUrl };
    });

  useEffect(() => {
    if (images?.length > 0) {
      setDesktopRatioMapping(
        mapIndividualImagesInEachRowToHaveSpecifiedRatio(images, "md", md),
      );

      setMobileRatioMapping(
        mapIndividualImagesInEachRowToHaveSpecifiedRatio(images, "xs", xs),
      );
    }
  }, [images, xs, md]);

  const getRatioContainerClass = (index: number) => {
    return `${
      desktopRatioMapping[index]
        ? classes.ratioForDesktopNotMobileContainer
        : ""
    } ${
      mobileRatioMapping[index] ? classes.ratioForMobileNotDesktopContainer : ""
    }`;
  };

  const calculateRatioStyle = (index: number) => {
    const ratio = isMobile
      ? images[index].preferredMobileRatio
      : images[index].preferredDesktopRatio;
    if (ratio) {
      return { paddingTop: `${(ratio.y / ratio.x) * 100}%` };
    }
    return;
  };

  return (
    <div className={classes.root} data-testid="root">
      <Grid container spacing={3}>
        {images &&
          images.map((image, indexKey) => {
            return (
              <Grid
                item
                {...{ xs: image.xs || xs, md: image.md || md }}
                key={indexKey + "_img"}
                className={classes.paper}
                data-testid="image"
              >
                <div
                  className={`${
                    classes.flexibleRatioContainer
                  } ${getRatioContainerClass(indexKey)} `}
                  style={calculateRatioStyle(indexKey)}
                  data-testid="ratio-container"
                >
                  <div className={classes.ratioContent}>
                    <ImageModalLink
                      modalImages={modalImages}
                      position={indexKey}
                      setOnClickImage={() => {
                        setClickedImagePosition(null);
                      }}
                      clickedImagePosition={clickedImagePosition}
                    >
                      <ImagePaper
                        imageUrl={image.thumbnailUrl}
                        altText={image.altText}
                      >
                        {image.label ? (
                          <div className={classes.label}>
                            <Typography variant="body1">
                              {image.label}
                            </Typography>
                          </div>
                        ) : null}
                      </ImagePaper>
                    </ImageModalLink>
                  </div>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};
