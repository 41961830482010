export enum JourneyTypes {
  bookingJourney = 'BOOKING',
  extrasJourney = 'EXTRAS',
  remainingBalanceJourney = 'REMAINING-BALANCE',
  gvJourney = 'GIFT-VOUCHER',
}
export enum JourneyTypesStepperKeys {
  bookingStepperKey = 'bookingJourneyStepper',
  extrasStepperKey = 'extrasJourneyStepper',
  remainingBalanceStepperKey = 'payRemeiningJourneyStepper',
  gvStepperKey = 'gvJourneyStepper',
}
