import React, { forwardRef } from "react";
import classNames from "classnames";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& fieldset": {
      border: `2px solid ${theme.palette.action.disabled}`,
      borderRadius: theme.shape.borderRadius,
      padding: "0 14px",
      "& legend": {
        fontSize: 16,
      },
    },
    "&:hover": {
      "& fieldset": {
        border: `2px solid ${theme.palette.grey[300]}`,
      },
    },
    "&:focus": {
      "& fieldset": {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    "& $primary": {
      overflow: "hidden",
    },
    "& $secondary": {
      overflow: "initial",
    },
    "& $ghost": {
      overflow: "initial",
    },
    "& $inverted": {
      overflow: "initial",
    },
    "& $information": {
      overflow: "initial",
    },
    "& .MuiInputBase-root, & .MuiInputBase-multiline": {
      "& $primary": {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.secondary.main,
      },
      "& $secondary": {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.background.default,
      },
      "& $ghost": {
        color: theme.palette.background.default,
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      },
      "& $inverted": {
        color: theme.palette.secondary.main,
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      },
      "& $information": {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        "& $primary": {
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        "& $secondary": {
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        "& $ghost": {
          border: `2px solid ${theme.palette.background.default}`,
        },
        "& $inverted": {
          border: `0px solid ${theme.palette.action.active}`,
        },
        "& $information": {
          border: `2px solid ${theme.palette.grey[400]}`,
        },
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        "& $primary": {
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        "& $secondary": {
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        "& $ghost": {
          border: `2px solid ${theme.palette.background.default}`,
        },
        "& $inverted": {
          border: `0px solid ${theme.palette.action.active}`,
        },
        "& $information": {
          border: `2px solid ${theme.palette.primary.main}`,
        },
      },

      "& .MuiSvgIcon-root": {
        "& $primary": {
          fill: theme.palette.background.paper,
        },
        "& $secondary": {
          fill: theme.palette.secondary.main,
        },
        "& $ghost": {
          fill: theme.palette.background.default,
        },
        "& $inverted": {
          fill: theme.palette.secondary.main,
        },
        "& $information": {
          fill: theme.palette.text.primary,
        },
      },
    },
    "& .MuiFormLabel-root": {
      "& $primary": {
        color: theme.palette.background.paper,
      },
      "& $secondary": {
        color: theme.palette.secondary.main,
      },
      "& $ghost": {
        color: theme.palette.background.default,
      },
      "& $inverted": {
        color: theme.palette.secondary.main,
      },
      "& $information": {
        color: theme.palette.text.primary,
      },
      "&.Mui-focused": {
        "& $primary": {
          color: theme.palette.background.paper,
        },
        "& $secondary": {
          color: theme.palette.secondary.main,
        },
        "& $ghost": {
          color: theme.palette.background.default,
        },
        "& $inverted": {
          color: theme.palette.secondary.main,
        },
        "& $information": {
          color: theme.palette.primary.main,
        },
      },
    },
    "& .MuiInputLabel-shrink": {
      "& $primary": {
        transform: "translate(14px, -18px) scale(0.75)",
      },
      "& $secondary": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
      "& $ghost": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
      "& $inverted": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
      "& $information": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
    },
  },
  labelRoot: {
    marginBottom: theme.spacing(1),
    transform: "translate(20px, 18px) scale(1)",
    color: theme.palette.text.disabled,
    "&$labelShrink": {
      transform: "translate(20px, -10px) scale(1)",
      color: theme.palette.text.primary,
    },
    "&$labelError": {
      color: theme.palette.secondary.dark,
    },
    "&$labelFocused": {
      color: theme.palette.primary.main,
    },
  },
  inputDisabled: {
    opacity: 0.3,
    "& fieldset": {
      border: `2px solid ${theme.palette.action.disabled}`,
    },
  },
  inputBaseError: {
    "& fieldset": {
      border: `2px solid ${theme.palette.secondary.dark}`,
    },
  },
  inputRoot: {
    fontFamily: theme.typography.h2.fontFamily,
    padding: theme.spacing(1.8, 2),
    fontSize: 16,
    lineHeight: "24px",
    height: "auto",
  },
  labelShrink: {},
  labelError: {
    "&$labelShrink": {
      color: theme.palette.secondary.dark,
    },
  },
  labelFocused: {},
  primary: {},
  secondary: {},
  ghost: {},
  inverted: {},
  information: {},
}));

export type FHStyle =
  | "primary"
  | "secondary"
  | "ghost"
  | "inverted"
  | "information";

type FHTextFieldProps = TextFieldProps & {
  fhStyle?: FHStyle;
  className?: string;
  startAdornmentIcon?: JSX.Element;
};

export const FHTextField: React.FC<FHTextFieldProps> = forwardRef(
  (
    {
      fhStyle = "information",
      className,
      startAdornmentIcon,
      ...props
    }: FHTextFieldProps,
    _
  ) => {
    const classes = useStyles();
    return (
      <TextField
        variant="outlined"
        className={classNames(classes.root, className, {
          [classes.primary]: fhStyle === "primary",
          [classes.secondary]: fhStyle === "secondary",
          [classes.ghost]: fhStyle === "ghost",
          [classes.inverted]: fhStyle === "inverted",
          [classes.information]: fhStyle === "information",
        })}
        {...props}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot,
            shrink: classes.labelShrink,
            error: classes.labelError,
            focused: classes.labelFocused,
          },
        }}
        InputProps={{
          startAdornment: startAdornmentIcon ? (
            <InputAdornment position="start">
              {startAdornmentIcon}
            </InputAdornment>
          ) : undefined,
          classes: {
            error: classes.inputBaseError,
            input: classes.inputRoot,
            disabled: classes.inputDisabled,
          },
        }}
      />
    );
  }
);

export default FHTextField;
