import dynamic from "next/dynamic";

export const DynamicBookingWidget = dynamic(
  () =>
    import("./BookingWidgetTemplate").then(
      (components) => components.BookingWidgetComponent
    ),
  { ssr: false }
);

export const DynamicBookingForm = dynamic(
  () =>
    import("../../components/SemanticTheme/BookingForm").then(
      (components) => components.BookingForm
    ),
  { ssr: false }
);

export const DynamicInteractiveMapExtraOption = dynamic(
  () =>
    import(
      "../../components/SemanticTheme/InteractiveMap/InteractiveMapExtraOption"
    ).then((components) => components.InteractiveMapExtraOption),
  { ssr: false }
);

export const DynamicPaymentTemplate = dynamic(
  () =>
    import("./PaymentTemplate").then(
      (components) => components.PaymentTemplate
    ),
  { ssr: false }
);

export const DynamicPaymentTemplateWithExperiment = dynamic(
  () =>
    import("./experiments/PaymentTemplate").then(
      (components) => components.PaymentTemplate
    ),
  { ssr: false }
);

export const DynamicFeefoProductReviewComponent = dynamic(
  () =>
    import("./FeefoProductReviewTemplate").then(
      (components) => components.FeefoProductReviewComponent
    ),
  { ssr: false }
);

export const DynamicFeefoServiceReviewComponent = dynamic(
  () =>
    import("./FeefoServiceReviewTemplate").then(
      (components) => components.FeefoServiceReviewComponent
    ),
  { ssr: false }
);
export const DynamicBannerSticky = dynamic(
  () =>
    import("../../components/SemanticTheme/BannerSticky").then(
      (components) => components.BannerSticky
    ),
  { ssr: false }
);
export const DynamicPaymentCards = dynamic(
  () =>
    import("../../components/SemanticTheme/PaymentCards").then(
      (components) => components.PaymentCards
    ),
  { ssr: false }
);
