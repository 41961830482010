import React from "react";
import { makeStyles } from "@mui/styles";
import { Container, Grid } from "@mui/material";
import { LabeledIcon } from "../../components/SemanticTheme/LabeledIcon";
import FHButton from "../../components/SemanticTheme/FHButton";
import { FHInlineSvg } from "../../components/SemanticTheme/FHInlineSvg";
import { withContent } from "../../utils/TemplateWithContent";
import { FeaturedIconsContainerEntity } from "src/graphql/generated-strapi/types";
import { updateAbsoluteLink } from "@components/Utils";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginLeft: theme.spacing(2),
  },
  button: {
    padding: theme.spacing(1.6),
  },
}));

type IconType = {
  label: string;
  svgUrl: string;
};

const FeaturedIconsContainerComponent: React.FC<
  FeaturedIconsContainerEntity
> = (props): JSX.Element => {
  const primaryContent = props?.attributes;
  const classes = useStyles();
  const icons: IconType[] = primaryContent?.Icons?.data.map((contentItem) => {
    const svgUrl = contentItem?.attributes?.Icon?.data?.attributes
      ?.url as string;

    return {
      label: contentItem?.attributes?.Label as string,
      svgUrl: svgUrl,
    };
  });

  const ctaUrl =
    primaryContent?.PrimaryCallToActionRef?.data !== null
      ? updateAbsoluteLink(
          primaryContent?.PrimaryCallToActionRef?.data?.attributes?.PageRoute,
        )
      : undefined;

  const callToActionText = primaryContent?.PrimaryCallToActionText as string;

  return (
    <Container>
      <Grid container spacing={2} justifyContent={"center"}>
        {icons?.length > 0 &&
          icons.map((logo: IconType, i: number) => (
            <Grid key={i} item>
              <LabeledIcon
                text={logo.label}
                icon={<FHInlineSvg src={logo.svgUrl} size="40px" />}
              />
            </Grid>
          ))}
        {ctaUrl && (
          <Grid item className={classes.buttonContainer}>
            <FHButton
              fhStyle="secondary"
              href={ctaUrl}
              className={classes.button}
            >
              <strong>{callToActionText}</strong>
            </FHButton>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

const FeaturedIconsContainerTemplate = (props) => {
  const content = props?.content?.featuredIcons?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    FeaturedIconsContainerComponent,
    content,
    undefined,
    pageImgQuality,
  );
};

export default FeaturedIconsContainerTemplate;
