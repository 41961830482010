import React, { PropsWithChildren } from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

import { EventCardProps, EventCard } from "./EventCard";
import { FHButton } from "../FHButton";
import { CallToAction } from "../CallToAction";

export * from "./EventCard";

const useStyles = makeStyles((theme) => ({
  root: {},
  header: (props: EventCardsStyleProps) => ({
    textAlign: "center",
    margin: "0 auto",
    maxWidth: props.maxWidth,
  }),
  headerContent: {
    padding: theme.spacing(2, 20, 0, 20),
    "& h2": {
      marginBottom: 20,
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 2),
    },
  },
  ctaButton: (props: EventCardsStyleProps) => ({
    width: "100%",
    textAlign: "center",
    margin: "0 auto",
    maxWidth: props.maxWidth,
  }),
  gridContainer: {
    paddingBottom: theme.spacing(2),
  },
  slidingContent: {
    padding: theme.spacing(3, 0),
    "& ul": {
      padding: 0,
    },
  },
  gridItem: (props: EventCardsStyleProps) => ({
    flex: `0 1 ${props.width}px`,
    maxWidth: props.width,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  }),
}));

type EventCardsStyleProps = {
  maxWidth?: string | number;
  width?: string | number;
};

export type EventCardsProps = {
  title: string;
  description: string;
  events: EventCardProps[];
  callToAction?: { text: string; url: string; fullWidth: boolean };
} & EventCardsStyleProps;

export const EventCards: React.FC<EventCardsProps> = ({
  title,
  description,
  events,
  maxWidth,
  callToAction,
  width = 400,
}: PropsWithChildren<EventCardsProps>) => {
  const classes = useStyles({ maxWidth, width });

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          {callToAction?.url ? (
            <CallToAction
              callToAction={title}
              callToActionUrl={callToAction.url}
              variant={"h2"}
              showUnderline={false}
              usePrimaryLightColor={false}
              showChevron={false}
            />
          ) : (
            <Typography variant="h2">{title}</Typography>
          )}
          <Typography variant="h4">{description}</Typography>
        </div>
      </div>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className={classes.gridContainer}
      >
        {events.map((event, i) => (
          <Grid key={i} item xs={12} md="auto" className={classes.gridItem}>
            <EventCard key={i} {...event} />
          </Grid>
        ))}
      </Grid>
      {callToAction && (
        <div className={classes.ctaButton}>
          <FHButton
            fhStyle="primary"
            href={callToAction.url}
            fullWidth={callToAction.fullWidth}
            data-testid="event-card-container-cta-button"
          >
            {callToAction.text}
          </FHButton>
        </div>
      )}
    </div>
  );
};
