import React, { PropsWithChildren } from 'react';
import {Container, Card, CardMedia} from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: 550,
  },
  video: {
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    height: 309,
  },
}));

export type VideoProps = {
  videoUrl: string;
};

export const Video: React.FC<VideoProps> = ({
  videoUrl,
}: PropsWithChildren<VideoProps>) => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Card className={classes.card}>
        <CardMedia
          component="iframe"
          image={videoUrl}
          className={classes.video}
        />
      </Card>
    </Container>
  );
};
