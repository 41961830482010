import React, { PropsWithChildren, useEffect } from "react";
import Fade from "@mui/material/Fade";
import { makeStyles } from "@mui/styles";
import { isAWhitelistedBot } from "../../Utils";

const useStyles = () =>
  makeStyles(() => ({
    root: {},
    hidden: {
      position: "absolute",
      width: 0,
      height: 0,
    },
  }))();

export type PreLoadImageProps = {
  url: string;
  loaderOverlay?: JSX.Element;
  fadeIn?: boolean | number;
  onLoad?: () => void;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export const PreLoadImage: React.FC<PreLoadImageProps> = ({
  url,
  children,
  loaderOverlay,
  fadeIn,
  onLoad,
  className,
  style,
}: PropsWithChildren<PreLoadImageProps>) => {
  const classes = useStyles();
  const [loaded, setLoaded] = React.useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    setLoaded(false);
    const image = new Image();

    image.onload = () => {
      if (mounted) {
        setLoaded(true);
        onLoad && onLoad();
      }
    };
    image.src = url;

    return () => {
      mounted = false;
    };
  }, [url]);

  const renderContainer = () => {
    if (fadeIn) {
      const duration: number = typeof fadeIn === "number" ? fadeIn : 500;
      return isAWhitelistedBot() ? (
        <>
          <div className={className} style={style} data-testid="preload-image">
            {children}
          </div>
        </>
      ) : (
        <>
          {!loaded && loaderOverlay}
          <Fade timeout={duration} in={loaded}>
            <div
              className={className}
              style={style}
              data-testid="preload-image"
            >
              {children}
            </div>
          </Fade>
        </>
      );
    } else {
      return (
        <>
          {className || style ? (
            <>
              {!loaded && loaderOverlay}
              <div
                className={loaded ? className : classes.hidden}
                style={style}
              >
                {children}
              </div>
            </>
          ) : loaded ? (
            children
          ) : (
            loaderOverlay
          )}
        </>
      );
    }
  };

  return renderContainer();
};
