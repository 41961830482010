import { Card, Grid, CardMedia, Hidden, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import React, { PropsWithChildren } from "react";
import { ImageModalLink } from "../ImageModalLink";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ModalDrawerCard } from "../ModalDrawerCard";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: (props: FloorplanPanelStyleProps) => ({
    textAlign: "center",
    padding: theme.spacing(4, 2),
    maxWidth: props.maxWidth,
    margin: props.centered ? "0 auto" : "",
  }),
  frameContainer: {
    width: "100%",
    paddingTop: "75%", //4:3 ratio
    position: "relative",
    overflow: "hidden",
  },
  title: {
    color: theme.palette.text.primary,
  },
  frame: {
    width: "100%",
    height: "calc(100% + 1px)", // extra 1px covers border issue on mobile
    borderWidth: 0,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  desktopImageContainer: {
    width: "100%",
    paddingTop: "75%", //4:3 ratio
    position: "relative",
  },
  img: {
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    top: 0,
  },
  imgHover: {
    cursor: "pointer",
  },
  thumbnail: {
    position: "absolute",
    top: 0,
    left: 0,
    cursor: "pointer",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      pointerEvents: "none", // allows the click to go through to iframe on mobile
    },
  },
  youtubeThumbnail: {
    position: "absolute",
    top: 0,
    left: 0,
    cursor: "pointer",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      pointerEvents: "none",
    },
  },
  desktopModalContainer: {
    width: "80vw",
    height: "80vh",
  },
  gridItem: {
    margin: "0 auto",
  },
}));

type FloorplanPanelStyleProps = {
  maxWidth?: string | number;
  centered?: boolean;
};

export type FloorplanPanelProps = {
  title: string;
  subTitle: string;
  matterportUrl?: string;
  imageUrl?: string;
  imageAlt?: string;
  imageThumbnail?: string;
} & FloorplanPanelStyleProps;

export const FloorplanPanel: React.FC<FloorplanPanelProps> = ({
  title,
  subTitle,
  matterportUrl = "",
  imageUrl,
  imageAlt,
  maxWidth,
  centered = true,
  imageThumbnail,
}: PropsWithChildren<FloorplanPanelProps>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isYoutubeLink = matterportUrl.indexOf("youtube") !== -1; // NOTE: matterport to be depracated in favor of youtube
  const classes = useStyles({
    maxWidth,
    centered,
  });
  const [open, setOpen] = React.useState(false);
  const [clickedImagePosition, setClickedImagePosition] = React.useState<
    null | number
  >(null);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const matterportMobileNewTabQuery = "&nt=1";
  const handleThumbnailClick = () => {
    if (!isMobile) {
      setOpen(true);
    }
  };

  const thumbnailMedia = (
    <CardMedia
      className={classes.thumbnail}
      onClick={handleThumbnailClick}
      image={imageThumbnail}
    />
  );

  const matterportFrame = (
    <iframe
      title={title}
      className={classes.frame}
      src={`${matterportUrl}${
        !isYoutubeLink ? matterportMobileNewTabQuery : ""
      }`}
      allowFullScreen={true}
      allow="xr-spatial-tracking"
      data-testid="floorplanPanelIframe"
      loading="lazy"
    />
  );
  return (
    <div className={classes.root} data-testid="floorplanPanelRoot">
      <Typography variant="h2" data-testid="floorplanPanelTitleSegment">
        {title}
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        data-testid="floorplanPanelSubTitleSegment"
      >
        {subTitle}
      </Typography>
      <Grid container spacing={isSmall ? 1 : 2}>
        {matterportUrl && (
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <Paper className={classes.frameContainer}>
              {matterportFrame}
              {isYoutubeLink && isMobile ? (
                <a
                  className={classes.youtubeThumbnail}
                  rel="noreferrer"
                  target="_blank"
                  href={matterportUrl}
                  onClick={(e) => {
                    if (!isMobile) {
                      e.preventDefault();
                    }
                  }}
                >
                  {imageThumbnail && thumbnailMedia}
                </a>
              ) : (
                imageThumbnail && thumbnailMedia
              )}
              <Hidden xsDown>
                <ModalDrawerCard
                  open={open}
                  title={title}
                  onOpen={() => {}}
                  onClose={() => setOpen(false)}
                  desktopMaxWidth={"100%"}
                  showHeader={false}
                >
                  <div className={classes.desktopModalContainer}>
                    {matterportFrame}
                  </div>
                </ModalDrawerCard>
              </Hidden>
            </Paper>
          </Grid>
        )}

        {imageUrl && (
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <Card data-testid="floorplanPanelModalLinkContainer">
              <ImageModalLink
                imageUrl={imageUrl}
                clickedImagePosition={clickedImagePosition}
                setOnClickImage={() => {
                  setClickedImagePosition(null);
                }}
              >
                <Hidden mdDown>
                  <div className={classes.desktopImageContainer}>
                    <CardMedia
                      image={imageUrl}
                      title={imageAlt ? imageAlt : title}
                      className={classes.img}
                    />
                  </div>
                </Hidden>
                <Hidden mdUp>
                  <CardMedia
                    component="img"
                    image={imageUrl}
                    alt={imageAlt ? imageAlt : title}
                  />
                </Hidden>
              </ImageModalLink>
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
