import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { withContent } from "src/utils/TemplateWithContent";
import { Container, Grid } from "@mui/material";
import { RichContentArea } from "src/components/SemanticTheme/RichContentArea";
import { makeHtml } from "../../utils/common";
import { RichTextEntity } from "src/graphql/generated-strapi/types";
import { processRichText } from "@components/Utils";

interface RichTextCMSData {
  MainText: string;
  ReadMoreLabel: string;
  ReadMoreText: string;
  Theme: string;
}

const useStyles = () =>
  makeStyles((theme) => ({
    readMoreWrapper: {
      color: theme.palette.secondary.main,
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      fontFamily: theme.typography.h2.fontFamily,
      fontSize: "16px",
      padding: theme.spacing(1, 1),

      "&:before": {
        flex: "1 1",
        content: '""',
        borderBottom: "2px solid",
        margin: "auto 10px auto auto",
        color: theme.palette.divider,
        borderColor: theme.palette.divider,
      },
      "&:after": {
        flex: "1 1",
        content: '""',
        color: theme.palette.divider,
        borderBottom: "2px solid",
        margin: "auto auto auto 10px",
        borderColor: theme.palette.action.active,
      },
      "& > *": {
        alignSelf: "center",
      },
    },
  }))();

const RichTextComponent: React.FC<RichTextEntity> = (
  props: RichTextEntity,
): JSX.Element => {
  const classes = useStyles();
  const content = props?.attributes;
  const [expanded, toggleExpanded] = useState<boolean>(false);

  const richTextCMSData: RichTextCMSData = {
    MainText: content?.["MainText"] as string,
    ReadMoreLabel: content?.["ReadMoreLabel"] as string,
    ReadMoreText: content?.["ReadMoreText"] as string,
    Theme: content?.["Theme"] as string,
  };

  const toggle = () => {
    toggleExpanded(!expanded);
  };

  return (
    <Container>
      {richTextCMSData?.MainText && (
        <Grid item xs={12}>
          <RichContentArea>
            <div
              dangerouslySetInnerHTML={makeHtml(
                processRichText(richTextCMSData.MainText),
              )}
            />
          </RichContentArea>
        </Grid>
      )}
      {expanded && richTextCMSData?.ReadMoreText && (
        <Grid item xs={12}>
          <RichContentArea>
            <div
              dangerouslySetInnerHTML={makeHtml(
                processRichText(richTextCMSData.ReadMoreText),
              )}
            />
          </RichContentArea>
        </Grid>
      )}
      {richTextCMSData?.ReadMoreLabel && richTextCMSData?.ReadMoreText && (
        <Grid item xs={12}>
          <div className={classes.readMoreWrapper} onClick={toggle}>
            <span>{richTextCMSData.ReadMoreLabel}</span>
            {expanded ? (
              <ExpandLess color="secondary" data-testid="chevronless" />
            ) : (
              <ExpandMore color="secondary" data-testid="chevronmore" />
            )}
          </div>
        </Grid>
      )}
    </Container>
  );
};

const RichTextTemplate = (props) => {
  const content = props?.content?.richText?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(RichTextComponent, content, undefined, pageImgQuality);
};
export default RichTextTemplate;
