import React from "react";
import { CardInformationText } from "./CardInformationText";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > p": {
      [theme.breakpoints.up("sm")]: {
        display: "inline",
      },
    },
  },
  separator: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "inline",
    },
  },
}));

export type CoordinatesCardInformationTextProps = {
  Icon?: React.FC;
  gpsLabel?: string;
  latitudeLabel?: string;
  latitude: number | string;
  longitudeLabel?: string;
  longitude: number | string;
  spacing?: string | number;
  key: string;
};

export const CoordinatesCardInformationText: React.FC<CoordinatesCardInformationTextProps> =
  ({
    Icon,
    gpsLabel = "GPS Coordinates",
    latitudeLabel = "Latitude:",
    latitude,
    longitudeLabel = "Longitude:",
    longitude,
    spacing,
    key,
  }: CoordinatesCardInformationTextProps) => {
    const classes = useStyles();
    return (
      <CardInformationText Icon={Icon} spacing={spacing} key={key}>
        <div data-testid="coordinates-card-root" className={classes.root}>
          <p>{gpsLabel} &ndash; </p>
          <p>
            {latitudeLabel} {latitude}
          </p>
          <span className={classes.separator}> | </span>
          <p>
            {longitudeLabel} {longitude}
          </p>
        </div>
      </CardInformationText>
    );
  };
