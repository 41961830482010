import { FunctionComponent, ClassicComponent } from "react";
import dynamic from "next/dynamic";

const DynamicBasicPage = dynamic(() =>
  import("./BasicPage").then((defaultImport) => defaultImport.default),
);

const DynamicMyAccountDashboardPage = dynamic(() =>
  import("./MyAccountDashboardPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);

const DynamicLocationContentPage = dynamic(() =>
  import("./LocationContentPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicFAQHubPage = dynamic(() =>
  import("./FAQHubPage").then((defaultImport) => defaultImport.default),
);
const DynamicFAQAnswerPage = dynamic(() =>
  import("./FAQAnswerPage").then((defaultImport) => defaultImport.default),
);
const DynamicBlogHubPage = dynamic(() =>
  import("./BlogHubPage").then((defaultImport) => defaultImport.default),
);
const DynamicBlogPostPage = dynamic(() =>
  import("./BlogPostPage").then((defaultImport) => defaultImport.default),
);
const DynamicGiftVoucherContentPage = dynamic(() =>
  import("./GiftVoucherContentPropertiesPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicCabinSearchResultsPageTemplate = dynamic(() =>
  import("./CabinSearchResultsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicLocationSearchResultsPage = dynamic(() =>
  import("./LocationSearchResultsPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicYourBookingPageComponent = dynamic(() =>
  import("./YourBookingPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicMyBookingsPageComponent = dynamic(() =>
  import("./MyBookingsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicChangePasswordPage = dynamic(() =>
  import("./ChangePasswordPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicForgotPasswordPage = dynamic(() =>
  import("./ForgotPasswordPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicLocationDetailsPage = dynamic(() =>
  import("./LocationDetailsPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicLoginRegisterPageComponent = dynamic(() =>
  import("./LoginRegisterPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicGVAddUpdateMessagePage = dynamic(() =>
  import("./GVAddUpdateMessagePage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicSecurePaymentPage = dynamic(() =>
  import("./SecurePaymentPage").then((defaultImport) => defaultImport.default),
);
const DynamicMyDetailsPageComponent = dynamic(() =>
  import("./MyDetailsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicFeaturedExtrasPageComponent = dynamic(() =>
  import("./FeaturedExtrasPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicGVPaymentConfirmationPageTemplate = dynamic(() =>
  import("./GVPaymentConfirmationPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicBookingConfirmationPageComponent = dynamic(() =>
  import("./BookingConfirmationPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicAbandonedBasketPageComponent = dynamic(() =>
  import("./AbandonedBasketPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicManageBookingPageTemplate = dynamic(() =>
  import("./ManageBookingPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicManageAttendeesPageTemplate = dynamic(() =>
  import("./ManageAttendeesPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicExtrasSearchResultsPageTemplate = dynamic(() =>
  import("./ExtrasSearchResultsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicExtrasPaymentPageTemplate = dynamic(() =>
  import("./ExtrasPaymentPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicCabinDetailsPageComponent = dynamic(() =>
  import("./CabinDetailsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicPayBalancePageComponent = dynamic(() =>
  import("./PayBalancePageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicNotFoundPageComponent = dynamic(() =>
  import("./NotFoundPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicCabinsAtLocationPageComponent = dynamic(() =>
  import("./CabinsAtLocationPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicSpecificPaymentPageComponent = dynamic(() =>
  import("./SpecificPaymentPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicExtraDetailsPageComponent = dynamic(() =>
  import("./ExtraDetailsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicResetPasswordPageTemplate = dynamic(() =>
  import("./ResetPasswordPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);

const DynamicInterstitialPage = dynamic(() =>
  import("./SykesInterstitialPage").then(
    (defaultImport) => defaultImport.default,
  ),
);

// import experiment page templates
const DynamicCabinSearchResultsPageTemplateWithExperiment = dynamic(() =>
  import("./experiments/CabinSearchResultsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicExtrasSearchResultsPageTemplateWithExperiment = dynamic(() =>
  import("./experiments/ExtrasSearchResultsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicFeaturedExtrasPageComponentWithExperiment = dynamic(() =>
  import("./experiments/FeaturedExtrasPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicLocationSearchResultsPageWithExperiment = dynamic(() =>
  import("./experiments/LocationSearchResultsPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicLoginRegisterPageComponentWithExperiment = dynamic(() =>
  import("./experiments/LoginRegisterPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicSecurePaymentPageWithExperiment = dynamic(() =>
  import("./experiments/SecurePaymentPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicYourBookingPageComponentWithExperiment = dynamic(() =>
  import("./experiments/YourBookingPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicMyBookingsPageComponentWithExperiment = dynamic(() =>
  import("./experiments/MyBookingsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicManageBookingPageComponentWithExperiment = dynamic(() =>
  import("./experiments/ManageBookingPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);

const DynamicCabinDetailsPageComponentWithExperiment = dynamic(() =>
  import("./experiments/CabinDetailsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicBookingConfirmationPageTemplateWithExperiment = dynamic(() =>
  import("./experiments/BookingConfirmationPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicLocationDetailsPageWithExperiment = dynamic(() =>
  import("./experiments/LocationDetailsPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicCabinsAtLocationPageWithExperiment = dynamic(() =>
  import("./experiments/CabinsAtLocationPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicBasicPageWithExperiment = dynamic(() =>
  import("./experiments/BasicPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicLocationContentPageWithExperiment = dynamic(() =>
  import("./experiments/LocationContentPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicFAQHubPageWithExperiment = dynamic(() =>
  import("./experiments/FAQHubPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicFAQAnswerPageWithExperiment = dynamic(() =>
  import("./experiments/FAQAnswerPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicBlogHubPageWithExperiment = dynamic(() =>
  import("./experiments/BlogHubPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicBlogPostPageWithExperiment = dynamic(() =>
  import("./experiments/BlogPostPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicGiftVoucherContentPageWithExperiment = dynamic(() =>
  import("./experiments/GiftVoucherContentPropertiesPage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicChangePasswordPageWithExperiment = dynamic(() =>
  import("./experiments/ChangePasswordPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicForgotPasswordPageWithExperiment = dynamic(() =>
  import("./experiments/ForgotPasswordPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicGVAddUpdateMessagePageWithExperiment = dynamic(() =>
  import("./experiments/GVAddUpdateMessagePage").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicMyDetailsPageComponentWithExperiment = dynamic(() =>
  import("./experiments/MyDetailsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicGVPaymentConfirmationPageTemplateWithExperiment = dynamic(() =>
  import("./experiments/GVPaymentConfirmationPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicAbandonedBasketPageComponentWithExperiment = dynamic(() =>
  import("./experiments/AbandonedBasketPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicManageAttendeesPageTemplateWithExperiment = dynamic(() =>
  import("./experiments/ManageAttendeesPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicExtrasPaymentPageTemplateWithExperiment = dynamic(() =>
  import("./experiments/ExtrasPaymentPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicPayBalancePageComponentWithExperiment = dynamic(() =>
  import("./experiments/PayBalancePageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicSpecificPaymentPageComponentWithExperiment = dynamic(() =>
  import("./experiments/SpecificPaymentPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicExtraDetailsPageComponentWithExperiment = dynamic(() =>
  import("./experiments/ExtraDetailsPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicResetPasswordPageTemplateWithExperiment = dynamic(() =>
  import("./experiments/ResetPasswordPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);
const DynamicMyAccountDashboardPageTemplateWithExperiment = dynamic(() =>
  import("./experiments/MyAccountDashboardPageTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);

import CabinTypeFeaturedMapComponentTemplate from "./mapped-templates/CabinTypeFeaturedMapTemplate";
import ImageGalleryComponentTemplate from "./mapped-templates/ImageGalleryTemplate";
import RichTextAreaComponentTemplate from "./mapped-templates/RichTextAreaTemplate";
import UsefulInfoBannerComponentTemplate from "./mapped-templates/UsefullBannerInfoTemplate";
import FullWidthDividerComponentTemplate from "./mapped-templates/FullWidthDividerTemplate";
import RichContentCardsNavigationContainerComponentTemplate from "./mapped-templates/RichContentCardsNavigationContainerTemplate";
import TileContainerComponentTemplate from "./mapped-templates/TileContainerTemplate";
import CabinsListInCabinTabbedPageComponent from "./mapped-templates/CabinsListInCabinTabbedPageTemplate";
import LocationBrochureCardsComponent from "./mapped-templates/LocationBrochureCardsTemplate";
import CollapsibleCardContainerComponent from "./mapped-templates/CollapsibleCardContainerTemplate";
import CaptionedImageComponentTemplate from "./mapped-templates/CaptionedImageTemplate";
import FeaturedIconsContainerComponent from "./mapped-templates/FeaturedIconsContainerTemplate";
import CabinsCardContainerComponent from "./mapped-templates/CabinsCardContainerTemplate";
import DescriptionCardContainerTemplate from "./mapped-templates/DescriptionCardContainerTemplate";
import MediaBlockComponentTemplate from "./mapped-templates/MediaBlockTemplate";
import SimilarLocationsComponent from "./mapped-templates/SimilarLocationsTemplate";
import PlanYourVisitCardContainerComponent from "./mapped-templates/PlanYourVisitCardContainer";
import FeefoProductReviewTemplate from "./mapped-templates/FeefoProductReviewTemplate";
import FeefoServiceReviewTemplate from "./mapped-templates/FeefoServiceReviewTemplate";
import WhatsIncludedContainerComponentTemplate from "./mapped-templates/WhatsIncludedContainerTemplate";
import ButtonContainer from "./mapped-templates/ButtonContainerTemplate";
import SubNavigationComponentTemplate from "./mapped-templates/SubNavigationTemplate";
import BookingWidgetComponentTemplate from "./mapped-templates/BookingWidgetTemplate";
import TwinTabbedComponentTemplate from "./mapped-templates/TwinTabbedComponentTemplate";
import ManagePaymentCardsTemplate from "./mapped-templates/ManagePaymentCardsTemplate";
import FloorPlanPanelComponent from "./mapped-templates/FloorPlanPanelTemplate";
import ContentTabContainerTemplate from "./mapped-templates/ContentTabContainerTemplate";
import StaffReviewTemplate from "./mapped-templates/StaffReviewTemplate";
import EventCardsContainerTemplate from "./mapped-templates/EventCardsContainerTemplate";
import RichTextTemplate from "./mapped-templates/RichTextTemplate";
import VideoComponentTemplate from "./mapped-templates/VideoTemplate";
import VideoUrlComponentTemplate from "./mapped-templates/VideoUrlTemplate";
import ImageBannerComponentTemplate from "./mapped-templates/ImageBannerTemplate";
import USPContainerComponentTemplate from "./mapped-templates/USPContainerTemplate";
import FAQComponentTemplate from "./mapped-templates/FAQComponentTemplate";
const DynamicScheduledPaymentTemplate = dynamic(() =>
  import("./mapped-templates/ScheduledPaymentTemplate").then(
    (defaultImport) => defaultImport.default,
  ),
);

type PageComponent = FunctionComponent<any> | ClassicComponent<any>;

export const pageTemplates: Map<string, PageComponent> = new Map<
  string,
  PageComponent
>();

export const pageTemplatesWithExperiments: Map<string, PageComponent> = new Map<
  string,
  PageComponent
>();

export const mappedTemplates: Map<string, PageComponent> = new Map<
  string,
  PageComponent
>();

// page templates
pageTemplates["BasicPage"] = DynamicBasicPage;
pageTemplates["LocationContentPage"] = DynamicLocationContentPage;
pageTemplates["FaqHubPage"] = DynamicFAQHubPage;
pageTemplates["FaqAnswerPage"] = DynamicFAQAnswerPage;
pageTemplates["BlogHubPage"] = DynamicBlogHubPage;
pageTemplates["BlogPostPage"] = DynamicBlogPostPage;
pageTemplates["LocationPage"] = DynamicLocationDetailsPage;
pageTemplates["LocationSearchResultsPage"] = DynamicLocationSearchResultsPage;
pageTemplates["CabinSearchResultsPage"] = DynamicCabinSearchResultsPageTemplate;
pageTemplates["GiftVoucherContentPage"] = DynamicGiftVoucherContentPage;
pageTemplates["GvPaymentConfirmationPage"] =
  DynamicGVPaymentConfirmationPageTemplate;
pageTemplates["LoginRegisterPage"] = DynamicLoginRegisterPageComponent;
pageTemplates["GvAddUpdateMessagePage"] = DynamicGVAddUpdateMessagePage;
pageTemplates["SecurePaymentPage"] = DynamicSecurePaymentPage;
pageTemplates["YourBookingPage"] = DynamicYourBookingPageComponent;
pageTemplates["MyBookingsPage"] = DynamicMyBookingsPageComponent;
pageTemplates["FeaturedExtrasPage"] = DynamicFeaturedExtrasPageComponent;
pageTemplates["ChangePasswordPage"] = DynamicChangePasswordPage;
pageTemplates["MyDetailsPage"] = DynamicMyDetailsPageComponent;
pageTemplates["BookingConfirmationPage"] =
  DynamicBookingConfirmationPageComponent;
pageTemplates["AbandonedBasketPage"] = DynamicAbandonedBasketPageComponent;
pageTemplates["ManageBookingPage"] = DynamicManageBookingPageTemplate;
pageTemplates["ManageAttendeesPage"] = DynamicManageAttendeesPageTemplate;
pageTemplates["ExtrasSearchResultsPage"] =
  DynamicExtrasSearchResultsPageTemplate;
pageTemplates["ExtrasPaymentPage"] = DynamicExtrasPaymentPageTemplate;
pageTemplates["CabinDetailsPage"] = DynamicCabinDetailsPageComponent;
pageTemplates["PayBalancePageTemplate"] = DynamicPayBalancePageComponent;
pageTemplates["Page404"] = DynamicNotFoundPageComponent;
pageTemplates["CabinsAtLocationPage"] = DynamicCabinsAtLocationPageComponent;
pageTemplates["ForgotPasswordPage"] = DynamicForgotPasswordPage;
pageTemplates["SpecificPaymentPage"] = DynamicSpecificPaymentPageComponent;
pageTemplates["ExtraDetailsPage"] = DynamicExtraDetailsPageComponent;
pageTemplates["ResetPasswordPage"] = DynamicResetPasswordPageTemplate;
pageTemplates["InterstitialPage"] = DynamicInterstitialPage;
pageTemplates["MyAccountDashboardPage"] = DynamicMyAccountDashboardPage;

// page templates with experiments
pageTemplatesWithExperiments["LocationSearchResultsPage"] =
  DynamicLocationSearchResultsPageWithExperiment;
pageTemplatesWithExperiments["CabinSearchResultsPage"] =
  DynamicCabinSearchResultsPageTemplateWithExperiment;
pageTemplatesWithExperiments["LoginRegisterPage"] =
  DynamicLoginRegisterPageComponentWithExperiment;
pageTemplatesWithExperiments["FeaturedExtrasPage"] =
  DynamicFeaturedExtrasPageComponentWithExperiment;
pageTemplatesWithExperiments["ExtrasSearchResultsPage"] =
  DynamicExtrasSearchResultsPageTemplateWithExperiment;
pageTemplatesWithExperiments["YourBookingPage"] =
  DynamicYourBookingPageComponentWithExperiment;
pageTemplatesWithExperiments["SecurePaymentPage"] =
  DynamicSecurePaymentPageWithExperiment;
pageTemplatesWithExperiments["MyBookingsPage"] =
  DynamicMyBookingsPageComponentWithExperiment;
pageTemplatesWithExperiments["ManageBookingPage"] =
  DynamicManageBookingPageComponentWithExperiment;
pageTemplatesWithExperiments["CabinDetailsPage"] =
  DynamicCabinDetailsPageComponentWithExperiment;
pageTemplatesWithExperiments["BookingConfirmationPage"] =
  DynamicBookingConfirmationPageTemplateWithExperiment;
pageTemplatesWithExperiments["LocationPage"] =
  DynamicLocationDetailsPageWithExperiment;
pageTemplatesWithExperiments["CabinsAtLocationPage"] =
  DynamicCabinsAtLocationPageWithExperiment;
pageTemplatesWithExperiments["BasicPage"] = DynamicBasicPageWithExperiment;
pageTemplatesWithExperiments["LocationContentPage"] =
  DynamicLocationContentPageWithExperiment;
pageTemplatesWithExperiments["FaqHubPage"] = DynamicFAQHubPageWithExperiment;
pageTemplatesWithExperiments["FAQAnswerPage"] =
  DynamicFAQAnswerPageWithExperiment;
pageTemplatesWithExperiments["BlogHubPage"] = DynamicBlogHubPageWithExperiment;
pageTemplatesWithExperiments["BlogPostPage"] =
  DynamicBlogPostPageWithExperiment;
pageTemplatesWithExperiments["GiftVoucherContentPage"] =
  DynamicGiftVoucherContentPageWithExperiment;
pageTemplatesWithExperiments["ChangePasswordPage"] =
  DynamicChangePasswordPageWithExperiment;
pageTemplatesWithExperiments["ForgotPasswordPage"] =
  DynamicForgotPasswordPageWithExperiment;
pageTemplatesWithExperiments["GvAddUpdateMessagePage"] =
  DynamicGVAddUpdateMessagePageWithExperiment;
pageTemplatesWithExperiments["MyDetailsPage"] =
  DynamicMyDetailsPageComponentWithExperiment;
pageTemplatesWithExperiments["GvPaymentConfirmationPage"] =
  DynamicGVPaymentConfirmationPageTemplateWithExperiment;
pageTemplatesWithExperiments["AbandonedBasketPage"] =
  DynamicAbandonedBasketPageComponentWithExperiment;
pageTemplatesWithExperiments["ManageAttendeesPage"] =
  DynamicManageAttendeesPageTemplateWithExperiment;
pageTemplatesWithExperiments["ExtrasPaymentPage"] =
  DynamicExtrasPaymentPageTemplateWithExperiment;
pageTemplatesWithExperiments["PayBalancePageTemplate"] =
  DynamicPayBalancePageComponentWithExperiment;
pageTemplatesWithExperiments["SpecificPaymentPage"] =
  DynamicSpecificPaymentPageComponentWithExperiment;
pageTemplatesWithExperiments["ExtraDetailsPage"] =
  DynamicExtraDetailsPageComponentWithExperiment;
pageTemplatesWithExperiments["ResetPasswordPage"] =
  DynamicResetPasswordPageTemplateWithExperiment;
pageTemplatesWithExperiments["MyAccountDashboardPage"] =
  DynamicMyAccountDashboardPageTemplateWithExperiment;

// mapped templates
mappedTemplates["textAreas"] = RichTextAreaComponentTemplate;
mappedTemplates["infoBanners"] = UsefulInfoBannerComponentTemplate;
mappedTemplates["cabinMaps"] = CabinTypeFeaturedMapComponentTemplate;
mappedTemplates["dividersList"] = FullWidthDividerComponentTemplate;
mappedTemplates["images"] = ImageGalleryComponentTemplate;
mappedTemplates["richCards"] =
  RichContentCardsNavigationContainerComponentTemplate;
mappedTemplates["tiles"] = TileContainerComponentTemplate;
mappedTemplates["cabinTypesList"] = CabinsListInCabinTabbedPageComponent;
mappedTemplates["locBrochureCards"] = LocationBrochureCardsComponent;
mappedTemplates["collapsibleCards"] = CollapsibleCardContainerComponent;
mappedTemplates["captionedImg"] = CaptionedImageComponentTemplate;
mappedTemplates["featuredIcons"] = FeaturedIconsContainerComponent;
mappedTemplates["cabinCardContainer"] = CabinsCardContainerComponent;
mappedTemplates["headSubNav"] = SubNavigationComponentTemplate;
mappedTemplates["descriptionCard"] = DescriptionCardContainerTemplate;
mappedTemplates["mediaBlocks"] = MediaBlockComponentTemplate;
mappedTemplates["planVisitCard"] = PlanYourVisitCardContainerComponent;
mappedTemplates["similarLocation"] = SimilarLocationsComponent;
mappedTemplates["feefoProduct"] = FeefoProductReviewTemplate;
mappedTemplates["feefoService"] = FeefoServiceReviewTemplate;
mappedTemplates["staffReviews"] = StaffReviewTemplate;
mappedTemplates["bookingWidget"] = BookingWidgetComponentTemplate;
mappedTemplates["BookingWidget"] = BookingWidgetComponentTemplate;
mappedTemplates["whatIncluded"] = WhatsIncludedContainerComponentTemplate;
mappedTemplates["buttonContainer"] = ButtonContainer;
mappedTemplates["SubNavigation"] = SubNavigationComponentTemplate;
mappedTemplates["twinTab"] = TwinTabbedComponentTemplate;
mappedTemplates["paymentSchedule"] = DynamicScheduledPaymentTemplate;
mappedTemplates["paymentCards"] = ManagePaymentCardsTemplate;
mappedTemplates["floorPlanPanel"] = FloorPlanPanelComponent;
mappedTemplates["contentTab"] = ContentTabContainerTemplate;
mappedTemplates["eventCards"] = EventCardsContainerTemplate;
mappedTemplates["richText"] = RichTextTemplate;
mappedTemplates["video"] = VideoComponentTemplate;
mappedTemplates["videoUrl"] = VideoUrlComponentTemplate;
mappedTemplates["imageBanners"] = ImageBannerComponentTemplate;
mappedTemplates["USPContainers"] = USPContainerComponentTemplate;
mappedTemplates["faq_comp"] = FAQComponentTemplate;
