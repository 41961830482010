import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Divider, DividerProps } from "@mui/material";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.divider,

    "&$large": {
      marginTop: 40,
      marginBottom: 40,
    },
    "&$medium": {
      marginTop: 20,
      marginBottom: 20,
    },
    "&$small": {
      marginTop: 10,
      marginBottom: 10,
    },
    "@media (max-width:600px)": {
      "&$large": {
        marginTop: 20,
        marginBottom: 20,
      },
      "&$medium": {
        marginTop: 10,
        marginBottom: 10,
      },
      "&$small": {
        marginTop: 5,
        marginBottom: 5,
      },
    },
  },
  large: {},
  medium: {},
  small: {},
}));

const StyledDivider = ({ ...other }) => <Divider {...other} />;

export type FullWidthDividerProps = DividerProps & {
  spacing?: "large" | "medium" | "small";
};

export const FullWidthDivider: React.FC<FullWidthDividerProps> = ({
  spacing = "medium",
  children,
  ...props
}: PropsWithChildren<FullWidthDividerProps>) => {
  const classes = useStyles();
  return (
    <StyledDivider
      className={classNames(classes.root, {
        [classes.large]: spacing === "large",
        [classes.medium]: spacing === "medium",
        [classes.small]: spacing === "small",
      })}
      spacing={spacing}
      data-testid="divider"
      {...props}
    >
      {children}
    </StyledDivider>
  );
};

export default FullWidthDivider;
