import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { Tile } from '../Tile/index';
import { PropsWithChildren } from 'react';
import { FHButton } from '../FHButton';
import { TileContainer } from '../TileContainer/index';

const useStyles = makeStyles((theme) => ({
  root: {},
  contentCenter: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
  },
}));

export type SimilarLocationsContainerProps = {
  title?: string;
  subtitle?: string;
  ctaLabel?: string;
  ctaUrl?: string;
  cards: Array<typeof Tile | JSX.Element>;
};

export const SimilarLocationsContainer: React.FC<SimilarLocationsContainerProps> = ({
  title,
  subtitle,
  ctaLabel,
  ctaUrl,
  cards,
}: PropsWithChildren<SimilarLocationsContainerProps>) => {
  const classes = useStyles();
  return (
    <div className={classes.root} data-testid={'similarLocationsContainerRoot'}>
      <Grid>
        <Grid item xs={12}>
          <TileContainer
            title={title}
            subtitle={subtitle}
            cards={cards}
            numberOfColumns={4}
            numberOfRowsMobile={1}
          />
        </Grid>

        {ctaLabel && ctaUrl && (
          <Grid item xs={12} className={classes.contentCenter}>
            <FHButton
              href={ctaUrl}
              fhStyle={'secondary'}
              fullWidth={false}
              data-testid="FHButton"
            >
              {ctaLabel}
            </FHButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
