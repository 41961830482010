import React from "react";
import { Container } from "@mui/material";
import {
  SubNavigationBar,
  SubNavLinkItem,
} from "../../components/SemanticTheme/SubNavigationBar";
import { withContent } from "../../utils/TemplateWithContent";
import { makeStyles } from "@mui/styles";
import { SubNavigation } from "src/graphql/generated-strapi/types";
import { updateAbsoluteLink } from "@components/Utils";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
  },
}));

export const SubNavigationComponent = (props: SubNavigation): JSX.Element => {
  const classes = useStyles();
  const linkList = props?.NavigationItems?.map(
    (contentItem) =>
      ({
        text: contentItem?.LinkText as string,
        isActive: false,
        link: !!contentItem?.ChildLinks?.length
          ? contentItem?.ChildLinks?.map((child) => ({
              text: child.LinkText,
              link: updateAbsoluteLink(
                child?.LinkURL?.data?.attributes?.PageRoute,
              ),
              isActive: false,
            }))
          : updateAbsoluteLink(
              contentItem?.LinkURL?.data?.attributes?.PageRoute,
            ) || "",
      } as SubNavLinkItem),
  );

  return (
    <Container className={classes.root}>
      <SubNavigationBar
        title={props?.MobileTitle}
        linkList={linkList}
        activePageName={linkList?.[0]?.text && linkList?.[0]?.text}
      />
    </Container>
  );
};

const SubNavigationComponentTemplate = (props): JSX.Element => {
  const content =
    props?.content?.headSubNav?.data?.attributes ||
    props?.content?.data?.attributes ||
    props?.content;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    SubNavigationComponent,
    content,
    undefined,
    pageImgQuality,
  );
};
export default SubNavigationComponentTemplate;
