import React from "react";
import SVG from "react-inlinesvg";
import { makeStyles } from "@mui/styles";

export type InlineSvgProps = {
  alt?: string;
  src: string;
  size?: string;
  color?: string;
  className?: string;
};

const useStyles = (size?: string, color?: string) =>
  makeStyles(() => ({
    svgContainer:
      size || color
        ? {
            height: size,
            width: size,
            color,
          }
        : {},
        svgRoot: {
          fill: 'currentColor',
          '& circle, & path': {
            fill: 'currentColor',
          },
        },
  }))();

const defaultprops: InlineSvgProps = Object.assign({
  src: "https://dev.forestholidays.co.uk/siteassets/dog.svg",
  size: "20px",
  color: "black",
});

export const FHInlineSvg = ({
  src,
  size,
  color,
  className = "",
}: InlineSvgProps = defaultprops) => {
  const classes = useStyles(size, color);

  return (
    <SVG
      src={src}
      className={classes.svgContainer + ' ' + classes.svgRoot + className}
      onError={(error) => console.log(error.message)}
    />
  );
};
