import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ImageWithCaption } from "../../components/SemanticTheme/ImageWithCaption";
import { withContent } from "../../utils/TemplateWithContent";
import { maxImgWidth, useGenerateResponsiveImageUrl } from "@components/Utils";
import { defaultcaptionedImgMaxWidths } from "src/utils/common";
import { CaptionedImageEntity } from "src/graphql/generated-strapi/types";

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: "center",
  },
}));

type CaptionedImageComponent = {
  pageImgQuality?: number;
} & CaptionedImageEntity;

const CaptionedImageComponent: React.FC<CaptionedImageComponent> = (
  props: CaptionedImageComponent
): JSX.Element => {
  const classes = useStyles();
  const imageUrl = props?.attributes?.Image?.data?.attributes?.url;
  const caption = props?.attributes?.Caption;
  const altText = props?.attributes?.Image?.data?.attributes?.alternativeText;
  const imgQuality = props?.attributes?.ImageQuality || props?.pageImgQuality;

  const generateImageUrl = (
    url: string | undefined,
    maxWidths: maxImgWidth
  ) => {
    return !!url
      ? useGenerateResponsiveImageUrl(url, maxWidths, imgQuality, true)
      : undefined;
  };

  return (
    <Container>
      <Grid container className={classes.container}>
        <Grid item xs={12} md={8}>
          <ImageWithCaption
            imageUrl={generateImageUrl(imageUrl, defaultcaptionedImgMaxWidths)}
            caption={caption ?? undefined}
            altText={altText ?? ""}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

const CaptionedImageComponentTemplate = (props) => {
  const content = props?.content?.captionedImg?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    CaptionedImageComponent,
    content,
    undefined,
    pageImgQuality
  );
};
export default CaptionedImageComponentTemplate;
