import {
  Theme,
  CardContent,
  useTheme,
  Grid,
  Typography,
  Card,
} from "@mui/material";

import React from "react";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { TitleVariant } from "../Enums";

const linePseudoElStyles = (color: string) => ({
  flex: `auto`,
  content: `""`,
  borderBottom: `2px solid ${color}`,
  maxWidth: `50px`,
  width: `100%`,
});

const useStyles = (
  showPaddingTop: boolean,
  showPaddingBottom: boolean,
  iconColour?: string
) =>
  makeStyles((theme) => ({
    card: {
      textAlign: "center",
      width: "min(400px, 90vw)",
      boxShadow: "0px 1px 7px 0px rgba(0,0,0,0.2)",
      borderRadius: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        margin: theme.spacing(0, 2, 0),
      },
    },
    fullHeight: {
      [theme.breakpoints.up("sm")]: {
        height: "100%",
      },
    },
    cardAllowFullWidth: {
      textAlign: "center",
      width: "min(400px, 90vw)",
      boxShadow: "0px 1px 7px 0px rgba(0,0,0,0.2)",
      borderRadius: "10px",
      [theme.breakpoints.up("md")]: {
        height: "100%",
        width: "100%",
      },
    },
    iconWrapper: {
      position: "relative",
      color: iconColour ? iconColour : theme.palette.text.primary,
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2),
      "& svg": {
        width: 40,
        height: 36,
      },
    },
    content: {},
    cardContent: {
      padding: theme.spacing(2.5, 3),

      "& ul": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    infoWrapper: {
      "& ul": {
        margin: "0",
        padding: "0",
      },
    },
    padding: {
      height: "100%",
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0
      ),
      "@media (max-width:600px)": {
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0
        ),
      },
    },
  }))();

export type DescriptionCardProps = {
  icon?: JSX.Element;
  title?: string;
  titleVariant?: TitleVariant;
  description?: React.FC<any> | JSX.Element;
  allowFullWidth?: boolean;
  fullHeight?: boolean;
  showPaddingTop?: boolean;
  showPaddingBottom?: boolean;
  iconColour?: string;
};

const defaultProps = (theme: Theme) =>
  ({
    title: "",
    titleVariant: TitleVariant.h2,
    description: <span></span>,
    allowFullWidth: false,
    fullHeight: true,
    showPaddingTop: false,
    showPaddingBottom: false,
  } as Required<DescriptionCardProps>);

export const DescriptionCard: React.FC<DescriptionCardProps> = (props) => {
  const theme = useTheme();

  const {
    icon,
    title,
    titleVariant = TitleVariant.h2,
    description,
    allowFullWidth,
    fullHeight,
    showPaddingTop,
    showPaddingBottom,
    iconColour,
  } = Object.assign({}, defaultProps(theme), props);

  const classes = useStyles(showPaddingTop, showPaddingBottom, iconColour);

  return (
    <div className={classes.padding}>
      <Card
        className={classnames(
          allowFullWidth ? classes.cardAllowFullWidth : classes.card,
          { [classes.fullHeight]: fullHeight }
        )}
      >
        <CardContent className={classes.cardContent}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            className={classes.content}
          >
            <Grid item xs={12}>
              <Typography
                variant={titleVariant?.toLowerCase() as TitleVariant}
                align={"center"}
              >
                {title}
              </Typography>
            </Grid>
            {icon && (
              <Grid item xs={12}>
                <div className={classes.iconWrapper}> {icon} </div>
              </Grid>
            )}
            <Grid item xs={12} className={classes.infoWrapper}>
              <> {description} </>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
