import React, { useEffect, useState } from "react";
import { Typography, FormHelperText, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Control, Controller } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import { FHStyle, FHTextField } from "../FHTextField";

const useStyles = makeStyles((theme) => ({
  root: ({ centered }: FHDropdownStyleProps) => ({
    textAlign: centered ? "center" : "left",
  }),
  disable: {
    opacity: 0.3,
  },
  errorHelpContainer: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.dark,
  },
}));

export type FHDropdownStyleProps = {
  centered?: boolean;
};

export type FHDropdownOption<T extends unknown> = {
  label: string;
  value: string;
  dataRef?: T;
};

export type FHDropdownProps<T extends unknown> = {
  name?: string;
  label?: string;
  id?: string;
  onDropdownChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onDropdownOpen?: () => void;
  validationFailure?: string;
  control?: Control;
  rules?: object;
  errorRef?: boolean | null;
  defaultValue?: string;
  className?: string;
  errorClassName?: string;
  options?: FHDropdownOption<T>[];
  help?: string;
  selectPlaceholder?: string;
  selectPlaceholderValue?: string;
  fhStyle?: FHStyle;
  variant?: "outlined";
  customOnChange?: (value: string, dataRef?: T) => void;
  value?: unknown;
  onPreChangeValidation?: (value: string, dataRef?: T) => void;
  loadingLabel?: string;
  isLoading?: boolean;
  isDisable?: boolean;
} & FHDropdownStyleProps;

export const FHSelectDropdown = <DataRef extends unknown>({
  label,
  name,
  id,
  validationFailure,
  control,
  rules,
  errorRef = null,
  defaultValue,
  className,
  errorClassName,
  options,
  onDropdownChange,
  onDropdownOpen,
  help,
  selectPlaceholder = "Select",
  selectPlaceholderValue = "",
  fhStyle,
  customOnChange,
  centered,
  value,
  onPreChangeValidation,
  loadingLabel = "Loading",
  isLoading = false,
  isDisable = false,
}: FHDropdownProps<DataRef>) => {
  const classes = useStyles({ centered });

  const [currentValue, setCurrentValue] = useState(value || defaultValue);

  useEffect(() => {
    if (value && typeof value === "string" && currentValue !== value) {
      handleOnChange(value);
    }
  }, [value, isLoading]);

  const handleOnChange = (value: string) => {
    const selectedOption = options.find((option) => option.value === value);
    if (customOnChange) {
      customOnChange(value, selectedOption?.dataRef);
    }

    setCurrentValue(value);
  };

  const renderDropdown = (controlProps?: {
    onChange: (...event: any[]) => void;
    onBlur: () => void;
    value: any;
    name: string;
    ref: React.MutableRefObject<any>;
  }) => (
    <FHTextField
      fullWidth
      select
      SelectProps={{
        IconComponent: ExpandMoreIcon,
        onOpen: () => {
          onDropdownOpen && onDropdownOpen();
        },
        value: currentValue,
        disabled: isLoading || isDisable,
      }}
      fhStyle={fhStyle}
      onChange={(ev) => {
        const value = ev.target.value;
        const selectedOption = options.find((option) => option.value === value);
        if (onPreChangeValidation) {
          onPreChangeValidation(value, selectedOption?.dataRef);
        } else {
          handleOnChange(value);
          controlProps?.onChange && controlProps.onChange(ev);
          if (onDropdownChange) {
            // ev.persist();
            onDropdownChange(ev);
          }
        }
      }}
      {...{
        label,
        className,
        id,
        defaultValue,
      }}
      onBlur={controlProps?.onBlur}
      value={currentValue || controlProps?.value}
      name={controlProps?.name}
      inputRef={controlProps?.ref}
      InputProps={{
        classes: {
          root: classes.root,
          disabled: classes.disable,
        },
      }}
      InputLabelProps={{
        disabled: isDisable,
        classes: {
          disabled: classes.disable,
        },
      }}
    >
      <MenuItem key={"none"} value={selectPlaceholderValue}>
        {isLoading ? loadingLabel : selectPlaceholder}
      </MenuItem>
      {options &&
        options.map((option, index) => (
          <MenuItem key={option.label + "-" + index} value={option.value}>
            {isLoading ? loadingLabel : option.label}
          </MenuItem>
        ))}
    </FHTextField>
  );

  return (
    <>
      {!!control && name ? (
        <React.Fragment>
          <Controller
            {...{ name, control, rules, defaultValue }}
            render={() => renderDropdown()}
          />
          {errorRef && (
            <div className={classes.errorHelpContainer}>
              {validationFailure && (
                <Typography
                  className={classNames(classes.error, errorClassName)}
                >
                  {validationFailure}
                </Typography>
              )}
              {help && (
                <FormHelperText data-testid={"inputHelpMessage"}>
                  {help}
                </FormHelperText>
              )}
            </div>
          )}
        </React.Fragment>
      ) : (
        renderDropdown()
      )}
    </>
  );
};

export default FHSelectDropdown;
