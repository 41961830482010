"use client";

import { getStorybookBaseUrl } from "./utils";

const normalizeSrc = (src: string) => {
  return src.startsWith("/") ? src.slice(1) : src;
};

export const staticImageLoader = ({ src, width, quality }) => {
  return `${getStorybookBaseUrl()}/${src}?w=${width}&q=${quality || 75}`;
};

export const cloudflareLoader = ({ src, width, quality = 85 }) => {
  // extract width and quality from url and override with nextjs default width
  const imageUrlObject = new URL(src);
  const imageUrlWithoutParameters = String(src).split("?")[0];
  const calculatedWidth = imageUrlObject.searchParams.get("width");
  const calculatedQuality = imageUrlObject.searchParams.get("quality");
  const params = [
    `width=${Number(calculatedWidth) > 0 ? calculatedWidth : width}`,
  ];
  // this must be from imageUrl will be cleaned in next user stories.
  if (quality) {
    params.push(`quality=${!!calculatedQuality ? calculatedQuality : quality}`);
  }
  const paramsString = params.join(",");
  const finalUrl = `${imageUrlWithoutParameters}`;
  const formattedImageUrl = `${
    process.env.NEXT_PUBLIC_CLOUDFLARE_IMAGES_URL
  }/${paramsString}/${normalizeSrc(finalUrl)}`;

  return formattedImageUrl;
};
