import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

export type LabeledIconProps = {
  text: string;
  icon: JSX.Element;
};

const useStyles = () =>
  makeStyles((theme) => ({
    container: {
      textAlign: "center",
      "& svg": {
        color: theme.palette.text.primary,
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100px",
      },
    },
    text: {},
  }))();

const defaultprops: LabeledIconProps = Object.assign({
  text: "Icon Label",
  icon: null,
});

export const LabeledIcon = ({
  text,
  icon,
}: LabeledIconProps = defaultprops) => {
  const classes = useStyles();

  return (
    <div className={classes.container} data-testid="labeledIconRoot">
      {icon}
      <Typography variant={"body2"} className={classes.text}>
        {text}
      </Typography>
    </div>
  );
};
