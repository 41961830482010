import React, { PropsWithChildren } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 18,
    fontFamily: theme.typography.h2.fontFamily,
    borderRadius: 10,
    height: 60,
    letterSpacing: "-0.18px",
    textAlign: "center",
    lineHeight: "24px",
    border: "none",
    padding: theme.spacing(1.9, 3.5, 1.7),
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      left: -1,
      right: -1,
      bottom: -1,
      top: -1,
      border: "0 solid transparent",
      borderRadius: 10,
    },
    "& svg": {
      marginRight: 5,
    },
    "&$primary": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        textDecoration: "underline",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `6px solid ${theme.palette.common.white}`,
        },
      },
      "&:active": {
        backgroundColor: theme.palette.secondary.main,
        textDecoration: "underline",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `6px solid ${theme.palette.common.white}`,
          boxShadow: theme.shadows[5],
        },
      },
      "&:focus": {
        backgroundColor: theme.palette.secondary.main,
        textDecoration: "none",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `3px solid ${theme.palette.success.dark}`,
          boxShadow: theme.shadows[0],
        },
      },
      "&:disabled, &.Mui-disabled": {
        opacity: 0.3,
        color: theme.palette.primary.contrastText,
      },
    },
    "&$secondary": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      "&:before": {
        border: `2px solid ${theme.palette.secondary.main}`,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default,
        textDecoration: "underline",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `4px solid ${theme.palette.secondary.main}`,
        },
      },
      "&:active": {
        backgroundColor: theme.palette.background.default,
        textDecoration: "underline",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `4px solid ${theme.palette.secondary.main}`,
          boxShadow: theme.shadows[5],
        },
      },
      "&:focus": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.success.dark,
        textDecoration: "none",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `3px solid ${theme.palette.success.dark}`,
          boxShadow: theme.shadows[0],
        },
      },
      "&:disabled, &.Mui-disabled": {
        opacity: 0.3,
        color: theme.palette.secondary.main,
      },
    },
    "&$tertiary": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.info.dark,
      "&:before": {
        border: `2px solid ${theme.palette.info.dark}`,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default,
        textDecoration: "underline",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `4px solid ${theme.palette.info.dark}`,
        },
      },
      "&:active": {
        backgroundColor: theme.palette.background.default,
        textDecoration: "underline",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `4px solid ${theme.palette.info.dark}`,
          boxShadow: theme.shadows[5],
        },
      },
      "&:focus": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.success.dark,
        textDecoration: "none",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `3px solid ${theme.palette.success.dark}`,
          boxShadow: theme.shadows[0],
        },
      },
      "&:disabled, &.Mui-disabled": {
        opacity: 0.3,
        color: theme.palette.info.dark,
      },
    },
    "&$ghost": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      color: theme.palette.background.default,
      "&:before": {
        border: `2px solid ${theme.palette.background.default}`,
      },
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        textDecoration: "underline",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `4px solid ${theme.palette.background.default}`,
        },
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        textDecoration: "underline",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `4px solid ${theme.palette.background.default}`,
          boxShadow: theme.shadows[5],
        },
      },
      "&:focus": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        color: theme.palette.success.dark,
        textDecoration: "none",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `3px solid ${theme.palette.background.default}`,
          boxShadow: theme.shadows[0],
        },
      },
      "&:disabled, &.Mui-disabled": {
        opacity: 0.3,
        color: theme.palette.background.default,
      },
    },
    "&$errorFilled": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
        textDecoration: "underline",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `6px solid ${theme.palette.common.white}`,
        },
      },
      "&:active": {
        backgroundColor: theme.palette.error.main,
        textDecoration: "underline",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `6px solid ${theme.palette.common.white}`,
          boxShadow: theme.shadows[5],
        },
      },
      "&:focus": {
        backgroundColor: theme.palette.error.main,
        textDecoration: "none",
        boxShadow: theme.shadows[4],
        "&:before": {
          border: `3px solid ${theme.palette.success.dark}`,
          boxShadow: theme.shadows[0],
        },
      },
      "&:disabled, &.Mui-disabled": {
        opacity: 0.3,
        color: theme.palette.primary.contrastText,
      },
    },
    "&$filter": {
      fontSize: 16,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.grey[600],
      height: 50,
      lineHeight: "21px",
      "&:before": {
        border: `2px solid ${theme.palette.grey[300]}`,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default,
        "&:before": {
          border: `4px solid ${theme.palette.grey[300]}`,
        },
      },
      "&:active": {
        backgroundColor: theme.palette.background.default,
        "&:before": {
          border: `4px solid ${theme.palette.grey[300]}`,
        },
      },
      "&:focus": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.grey[600],
        textDecoration: "none",
        "&:before": {
          border: `3px solid ${theme.palette.grey[300]}`,
        },
      },
      "&:disabled, &.Mui-disabled": {
        opacity: 0.3,
        color: theme.palette.grey[600],
      },
      "& svg": {
        color: "currentColor",
      },
    },
    "&$mediumSize": {
      fontSize: 16,
      lineHeight: "21px",
      height: 40,
      padding: theme.spacing(1, 3, 0.9),
    },
    "&$smallSize": {
      lineHeight: "18px",
      fontSize: 14,
      height: 30,
      padding: theme.spacing(0.6, 2),
    },
  },
  primary: {},
  secondary: {},
  tertiary: {},
  filter: {},
  ghost: {},
  errorFilled: {},
  mediumSize: {},
  smallSize: {},
}));

export type ButtonStyle =
  | "primary"
  | "secondary"
  | "tertiary"
  | "filter"
  | "ghost"
  | "errorFilled";

export type ButtonSize = "lg" | "md" | "sm";

export type FHButtonProps = ButtonProps & {
  fhStyle?: ButtonStyle;
  fhSize?: ButtonSize;
  minWidth?: string;
  className?: string;
  component?: string;
  isIcon?: boolean;
  icon?: JSX.Element;
};

export const FHButton: React.FC<FHButtonProps> = ({
  fhStyle = "primary",
  fhSize = "lg",
  children,
  className,
  minWidth,
  component,
  isIcon,
  icon = <AddRoundedIcon />,
  ...props
}: PropsWithChildren<FHButtonProps>) => {
  const classes = useStyles();

  return (
    <Button
      disableTouchRipple
      data-testid="fhButton"
      style={{ minWidth: minWidth }}
      className={classNames(classes.root, className, {
        [classes.primary]: fhStyle === "primary",
        [classes.secondary]: fhStyle === "secondary",
        [classes.tertiary]: fhStyle === "tertiary",
        [classes.filter]: fhStyle === "filter",
        [classes.ghost]: fhStyle === "ghost",
        [classes.errorFilled]: fhStyle === "errorFilled",
        [classes.mediumSize]: fhSize === "md",
        [classes.smallSize]: fhSize === "sm",
      })}
      {...props}
    >
      {isIcon && <>{icon}</>}
      {children}
    </Button>
  );
};
export default FHButton;
