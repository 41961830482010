import React, { PropsWithChildren } from "react";

import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { ContentCard, ContentCardProps } from "../SlidingContentCard";

const useStyles = (
  showPaddingTop: boolean,
  showPaddingBottom: boolean,
  width?: string | number
) =>
  makeStyles((theme) => ({
    root: {
      height: "100%",
      width: width,
    },
    calendar: {
      position: "absolute",
      width: 90,
      height: 80,
      margin: theme.spacing(3.5, 3),
      textAlign: "center",
    },
    calendarHeader: {
      background: `linear-gradient(to bottom, rgba(186,212,40,1) 0%, rgba(51,128,0,1) 100%)`,
      padding: theme.spacing(0.5, 0),
      fontSize: theme.typography.caption.fontSize,
      textTransform: "uppercase",
    },
    calendarDay: {
      lineHeight: "1em",
      marginTop: theme.spacing(0.5),
    },
    calendarMonth: {
      lineHeight: "1em",
      textTransform: "uppercase",
      fontSize: theme.typography.caption.fontSize,
    },
    title: {
      marginBottom: theme.spacing(2),
      lineHeight: "20px",
    },
    dateRange: {
      textTransform: "uppercase",
    },
    eventCardPadding: {
      height: "100%",
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0
      ),
      "@media (max-width:600px)": {
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0
        ),
      },
    },
  }))();

export type EventCardStyleProps = {
  width?: string | number;
  showPaddingTop: boolean;
  showPaddingBottom: boolean;
};

export type EventCardProps = {
  isoDateFrom?: string;
  isoDateTo?: string;
  title: string;
  imgUrl: string;
  description: React.FC<any> | JSX.Element;
  callToActionText?: string;
  callToActionUrl?: string;
  callToActionTelephone?: string;
  imageQuality?: number;
} & EventCardStyleProps;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const EventCard: React.FC<EventCardProps> = ({
  isoDateFrom,
  isoDateTo,
  imgUrl,
  title,
  description,
  callToActionText,
  callToActionUrl,
  callToActionTelephone,
  showPaddingTop,
  showPaddingBottom,
  width = "100%",
}: PropsWithChildren<EventCardProps>) => {
  const classes = useStyles(showPaddingTop, showPaddingBottom, width);
  const from = isoDateFrom ? new Date(isoDateFrom) : undefined;
  const to = isoDateTo ? new Date(isoDateTo) : undefined;
  const contentCardProps: ContentCardProps = {
    media: {
      imgUrl,
      overlayContent:
        from && to ? (
          <div>
            {!isNaN(from.getDate()) && (
              <Card className={classes.calendar}>
                <Typography component="div" className={classes.calendarHeader}>
                  <b>{days[from.getDay()]}</b>
                </Typography>
                <Typography variant="h5" className={classes.calendarDay}>
                  <b>{from.getDate()}</b>
                </Typography>
                <Typography component="span" className={classes.calendarMonth}>
                  <b>{months[from.getMonth()]}</b>
                </Typography>
              </Card>
            )}
          </div>
        ) : undefined,
    },
    callToAction: callToActionText
      ? {
          label: callToActionText,
          url: callToActionUrl,
          telephone: callToActionTelephone,
        }
      : undefined,
  };

  const generateDateRange = () => {
    if (from && to) {
      const isSameYear = from.getFullYear() === to.getFullYear();
      const isSameMonth = from.getMonth() === to.getMonth();
      const fromMonth = months[from.getMonth()];
      const fromDateString = `${from.getDate()} ${
        isSameYear
          ? isSameMonth
            ? ""
            : fromMonth
          : `${fromMonth} ${from.getFullYear()}`
      }`;

      const toDateString = `${to.getDate()} ${
        months[to.getMonth()]
      } ${to.getFullYear()}`;
      return `${fromDateString} - ${toDateString}`;
    } else {
      return undefined;
    }
  };
  return (
    <div className={classes.eventCardPadding}>
      <ContentCard {...contentCardProps} className={classes.root}>
        {from && to && (
          <Typography variant="body2" className={classes.dateRange}>
            <strong data-testid="cardDateRange">{generateDateRange()}</strong>
          </Typography>
        )}
        {title && (
          <Typography
            variant="h4"
            className={classes.title}
            data-testid="cardtitle"
          >
            {title}
          </Typography>
        )}
        <Typography data-testid="cardDescription" component="div">
          <>{description}</>
        </Typography>
      </ContentCard>
    </div>
  );
};
