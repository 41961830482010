import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    paddingLeft: theme.spacing(4.2),
  },
  checkbox: {
    position: "absolute",
    top: theme.spacing(-1),
    left: 0,
    color: theme.palette.secondary.main,
  },
  checkboxDefault: {
    color: theme.palette.action.active,
  },
}));

export type CheckboxFieldProps = {
  name: string;
  label: string;
  customOnChange?: (event: any) => void;
  validationFailure?: string;
  rules?: object;
  control?: any;
  errorRef?: any;
  defaultValue?: boolean;
  className?: string;
  errorClassName?: string;
  disabled?: boolean;
  fhStyle?: any;
  checkedIcon?: JSX.Element;
};

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  label,
  name,
  customOnChange = () => {},
  validationFailure,
  rules,
  control,
  errorRef = null,
  defaultValue,
  className,
  errorClassName,
  disabled,
  fhStyle = "secondary",
  checkedIcon,
}: PropsWithChildren<CheckboxFieldProps>) => {
  const classes = useStyles();
  return (
    <div>
      <Controller
        {...{ control, rules, name, defaultValue, fhStyle }}
        render={({ field: { ref, name, onChange } }) => (
          <FormControlLabel
            inputRef={ref}
            color={"secondary"}
            className={classnames(classes.root, className)}
            label={
              <Typography variant={"body1"} color={"secondary"}>
                <b>{label}</b>
              </Typography>
            }
            control={
              <Checkbox
                color={fhStyle}
                size={"medium"}
                className={classnames(classes.checkbox, {
                  [classes.checkboxDefault]: fhStyle === "default",
                })}
                checkedIcon={checkedIcon}
                disabled={disabled}
                onChange={({ target }) => {
                  onChange(target.checked);
                  customOnChange && customOnChange(target.checked);
                }}
                {...{ name, checked: defaultValue }}
              />
            }
          />
        )}
      />
      {errorRef && (
        <div className={errorClassName}>
          {validationFailure && (
            <Typography className={errorClassName}>
              {validationFailure}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};
