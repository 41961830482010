import { Container, Grid } from "@mui/material";
import React from "react";
import { TeamReviewSnippet } from "../../components/SemanticTheme/TeamReviewSnippet";
import {
  maxImgWidth,
  useGenerateResponsiveImageUrl,
} from "../../components/Utils";
import { useIsIE } from "../../hooks/hooks";
import { withContent } from "../../utils/TemplateWithContent";
import { StaffReviewEntity } from "src/graphql/generated-strapi/types";

type CmsData = ReturnType<typeof makeCmsData>;
const makeCmsData = (content: any) => ({
  title: content?.["Title"] as string,
  subTitle: content?.["SubTitle"] as string,
  quote: content?.["Description"] as string,
  imageUrl: content?.["Image"]?.data?.attributes?.url as string,
  ctaLink: content?.["PrimaryCallToActionRef"]?.data?.attributes
    ?.PageRoute as string,
  cta: content?.["PrimaryCallToActionText"] as string,
  showPaddingBottom: content?.["showPaddingBottom"] as boolean,
  showPaddingTop: content?.["showPaddingTop"] as boolean,
});

type StaffReviewComponent = {
  pageImgQuality?: number;
} & StaffReviewEntity;

const StaffReviewComponent: React.FC<StaffReviewComponent> = (
  props: StaffReviewComponent
): JSX.Element => {
  const isIE = useIsIE();
  const cmsData: CmsData = makeCmsData(props?.attributes);
  const imgQuality = props?.attributes?.ImageQuality || props?.pageImgQuality;

  const maxWidths = {
    xs: 100,
    sm: 100,
    md: 100,
    lg: 100,
    xl: 100,
  };

  const generateImageUrl = (
    url: string | undefined,
    maxWidths?: maxImgWidth
  ) => {
    if (url) {
      return useGenerateResponsiveImageUrl(url, maxWidths, imgQuality);
    }
  };

  return (
    <Container>
      <Grid container spacing={0} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={8}>
          <TeamReviewSnippet
            imageUrl={generateImageUrl(cmsData?.imageUrl, maxWidths)}
            title={cmsData?.title}
            subTitle={cmsData?.subTitle}
            quote={cmsData?.quote}
            cta={cmsData?.cta}
            ctaLink={cmsData?.ctaLink}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

const StaffReviewTemplate = (props) => {
  const content = props?.content?.staffReviews?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(StaffReviewComponent, content, undefined, pageImgQuality);
};
export default StaffReviewTemplate;
