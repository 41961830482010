import { Container } from "@mui/material";
import React from "react";
import FullWidthDivider from "../../components/SemanticTheme/FullWidthDivider";
import { withContent } from "../../utils/TemplateWithContent";
import { FullWidthDividerEntity } from "src/graphql/generated-strapi/types";

interface FullWidthDividerComponent {
  cmsData: FullWidthDividerEntity;
}
const FullWidthDividerComponent: React.FC<FullWidthDividerEntity> = (
  props: FullWidthDividerEntity
): JSX.Element => {
  const cmsData = {
    spacing: props?.attributes?.Spacing.toLocaleLowerCase() as string,
    variant: props?.attributes?.Variant as string,
  };
  return (
    <Container>
      <FullWidthDivider
        variant={cmsData?.variant as any}
        spacing={cmsData?.spacing as any}
      />
    </Container>
  );
};

const FullWidthDividerComponentTemplate = (props) => {
  const content = props?.content?.dividersList?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    FullWidthDividerComponent,
    content,
    undefined,
    pageImgQuality
  );
};
export default FullWidthDividerComponentTemplate;
