import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren } from "react";
import { Card, Grid, CardContent, Hidden } from "@mui/material";
import {
  ImageGalleryCarousel,
  ImageGalleryCarouselProps,
} from "../ImageGalleryCarousel/index";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: (props: BrochureCardStyleProps) => ({
    maxWidth: props.maxWidth,
    margin: props.centered ? "0 auto" : "",
  }),
  cardContent: {
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
    "& h2": {
      wordBreak: "break-word",
    },
  },
  infoContainer: {
    height: "100%",
  },
}));

type BrochureCardStyleProps = {
  maxWidth?: string | number;
  centered?: boolean;
};

export type BrochureCardProps = {
  imageGalleryCarouselProps: ImageGalleryCarouselProps;
  className?: string;
} & BrochureCardStyleProps;

export const BrochureCard: React.FC<PropsWithChildren<BrochureCardProps>> = ({
  imageGalleryCarouselProps,
  maxWidth,
  centered,
  children,
  className,
}: PropsWithChildren<BrochureCardProps>) => {
  const classes = useStyles({ maxWidth, centered });
  return (
    <Card data-testid="root" className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Hidden mdDown>
            <ImageGalleryCarousel {...imageGalleryCarouselProps} />
          </Hidden>
          <Hidden mdUp>
            <ImageGalleryCarousel
              {...imageGalleryCarouselProps}
              containerRatio={{ x: 4, y: 3 }}
            />
          </Hidden>
        </Grid>
        <Grid item xs={12} md={7}>
          <CardContent className={classes.cardContent}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              className={classNames(classes.infoContainer, className)}
            >
              {children}
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};
