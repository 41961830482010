import React from "react";
import { Container, Theme, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { CallToAction } from "../CallToAction";
import { DescriptionCard } from "../DescriptionCard";
import { useIsIE } from "../../../hooks/hooks";
import classnames from "classnames";
import { TitleVariant } from "../Enums";
import { FHNextImage, ImageLoader } from "../FHNextImage";

const useStyles = () =>
  makeStyles((theme: Theme) => ({
    UsefulInfoBanner: {
      width: "100%",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
      },
    },
    banner: {
      width: "100%",
      height: "350px",
      position: "absolute",
      zIndex: -1,
      left: 0,
      // border: '1px solid green',
    },
    wrapper: {
      maxWidth: "1100px",
      height: "100%",
      display: "flex",
      justifyContent: "flex-end",
      margin: "0 auto",

      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    desc: {
      minHeight: "80px",
    },
    callToAction: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(0),
      },
    },
    callToActionText: {
      fontSize: 18,
      color: theme.palette.secondary.main,
    },
    containerContent: {
      height: "100%",
      display: "inline-block",
      marginTop: theme.spacing(3),

      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(21),
      },
    },
    cardFlexFix: {
      flex: "0 1 400px",
    },
  }))();

export type UsefulInfoBannerProps = {
  backgroundImageUrl?: string;
  icon?: JSX.Element;
  title?: string;
  titleVariant?: TitleVariant;
  description?: JSX.Element;
  callToAction?: string;
  callToActionUrl?: string;
  showPaddingTop?: boolean;
  showPaddingBottom?: boolean;
  imageLoader?: ImageLoader;
};

const defaultProps = {
  backgroundImageUrl: "",
  icon: <span></span>,
  title: "",
  titleVariant: TitleVariant.h2,
  description: "",
  callToAction: "",
  callToActionUrl: "#",
  showPaddingTop: false,
  showPaddingBottom: false,
};

export const UsefulInfoBanner: React.FC<UsefulInfoBannerProps> = (props) => {
  const {
    backgroundImageUrl,
    icon,
    title,
    titleVariant,
    description,
    callToAction,
    callToActionUrl,
    showPaddingTop,
    showPaddingBottom,
    imageLoader,
  } = Object.assign({}, defaultProps, props);

  const classes = useStyles();
  const theme = useTheme();
  const isIE = useIsIE();

  const cardContent = (
    <div>
      <div className={classes.desc}>{description}</div>

      {callToAction && callToActionUrl && (
        <div className={classes.callToAction}>
          <CallToAction
            {...{ callToAction, callToActionUrl }}
            variant="body1"
            showUnderline={false}
            textClassName={classes.callToActionText}
            usePrimaryLightColor={true}
          />
        </div>
      )}
    </div>
  );

  return (
    <div
      className={classes.UsefulInfoBanner}
      data-testid="usefulInfoBannerRoot"
    >
      <div className={classes.banner} data-testid="usefulInfoBannerImg">
        <FHNextImage
          src={backgroundImageUrl}
          alt={title}
          layout="fill"
          objectFit="cover"
          loading="lazy"
          loader={imageLoader}
        />
      </div>

      <Container className={classes.wrapper}>
        <div
          className={classnames(classes.containerContent, {
            [classes.cardFlexFix]: isIE,
          })}
        >
          <DescriptionCard
            icon={icon}
            title={title}
            titleVariant={titleVariant}
            description={cardContent}
            fullHeight={false}
            showPaddingTop={showPaddingTop}
            showPaddingBottom={showPaddingBottom}
          ></DescriptionCard>
        </div>
      </Container>
    </div>
  );
};
