import React from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "@mui/material";
import { useIsIE } from "../../../hooks/hooks";
import { WrapperProps, Wrapper } from "./Wrapper";

const useStyles = makeStyles((theme) => ({
  desktopModal: (props: DesktopModalCardStyleProps & { isIE?: boolean }) => ({
    width: "100%",
    maxWidth: props.desktopMaxWidth || 400,
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      minWidth: props.desktopMinWidth,
    },
    "&:focus ": {
      outlineColor: "transparent",
      outlineStyle: "none",
    },
    "& .MuiCard-root": {
      height: props.isIE ? props.isIEMaxHeight : "auto",
      maxHeight: "80vh",
    },
    "& .MuiCardHeader-root": {
      backgroundColor: theme.palette.divider,
    },
  }),
}));

export type DesktopModalCardStyleProps = {
  desktopMaxWidth?: string | number;
  desktopMinWidth?: string | number;
  isIEMaxHeight?: string | number;
};

export type DesktopModalCardProps = {
  open: boolean;
} & WrapperProps &
  DesktopModalCardStyleProps;

export const DesktopModalCard: React.FC<DesktopModalCardProps> = ({
  children,
  open,
  onClose,
  desktopMinWidth,
  desktopMaxWidth,
  isIEMaxHeight,
  ...rest
}: DesktopModalCardProps) => {
  const isIE = useIsIE();
  const classes = useStyles({
    desktopMaxWidth,
    desktopMinWidth,
    isIE,
    isIEMaxHeight,
  });
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      data-testid="modal"
      BackdropProps={{ "data-testid": "modal-backdrop" } as any}
    >
      <div className={classes.desktopModal}>
        <Wrapper {...{ children, open, onClose, ...rest }} />
      </div>
    </Modal>
  );
};
