import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren } from "react";
import { Grid, Typography } from "@mui/material";
import FHButton from "../FHButton";
import FHLink from "../FHLink";
import { FHInlineSvg } from "../FHInlineSvg/index";
import { BrochureCard, BrochureCardProps } from "./BrochureCard";

const useStyles = makeStyles((theme) => ({
  coordinatesContainer: {
    marginTop: theme.spacing(4),
  },
  featureList: {
    paddingInlineStart: "1.1em",
  },
  ctaContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  activities: {
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  activityContainer: {
    display: "flex",
    flexDirection: "column",
  },
  activity: {
    display: "inline-block",
    "& svg": {
      color: theme.palette.text.primary,
    },
  },
}));

export type LocationBrochureCardProps = {
  title: string;
  subtitle: string;
  activities?: { label: string; svgIconUrl: string }[];
  description?: string;
  features?: JSX.Element;
  ctaText?: string;
  onCtaClick?: () => void;
  locationDetailsPageUrl?: string;
} & BrochureCardProps;

export const LocationBrochureCard: React.FC<LocationBrochureCardProps> = ({
  imageGalleryCarouselProps,
  title,
  subtitle,
  activities,
  maxWidth,
  centered,
  children,
  description,
  features,
  ctaText,
  onCtaClick,
}: PropsWithChildren<LocationBrochureCardProps>) => {
  const classes = useStyles();
  return (
    <BrochureCard {...{ imageGalleryCarouselProps, maxWidth, centered }}>
      <Grid item xs={12} md>
        <Typography variant="h2">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Grid>
      <Grid item xs={12} md>
        <Grid
          container
          className={classes.activities}
          justifyContent="center"
          spacing={2}
        >
          {activities &&
            activities.map((activity, i) => (
              <Grid item key={i} className={classes.activityContainer}>
                <div className={classes.activity}>
                  <FHInlineSvg src={activity.svgIconUrl} size="40px" />
                </div>
                <Typography variant="body1" component="span">
                  {activity.label}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {children ? (
          children
        ) : (
          <Typography variant="body1">{description}</Typography>
        )}
      </Grid>
      <Grid item xs={12} md>
        {features ? features : undefined}
      </Grid>
      <Grid item xs={12} md className={classes.ctaContainer}>
        <FHButton fhStyle="secondary" onClick={onCtaClick} fullWidth={true}>
          {ctaText}
        </FHButton>
      </Grid>
    </BrochureCard>
  );
};
