import React from "react";
import { LocationCard } from "../../components/SemanticTheme/LocationCard";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import {
  defaultMaxWidths,
  makeHtml,
  processRichText,
  useGenerateResponsiveImageUrl,
} from "../../components/Utils";
import { BasicCardContainer } from "../../components/SemanticTheme/BasicCardContainer";
import { withContent } from "../../utils/TemplateWithContent";
import { cardImageWidths, maxImgWidth } from "src/utils/common";
import { CabinTypeFeaturedMapEntity } from "src/graphql/generated-strapi/types";
type CabinTypeFeaturedMapComponent = {
  pageImgQuality?: number;
  additionalProps?: { [key: string]: string | boolean };
} & CabinTypeFeaturedMapEntity;

const CabinTypeFeaturedMapComponent: React.FC<CabinTypeFeaturedMapComponent> = (
  props: CabinTypeFeaturedMapComponent,
): JSX.Element => {
  const cmsData = props?.attributes;
  const imgQuality = props?.attributes?.ImageQuality || props?.pageImgQuality;
  const imageUrl = cmsData?.MapImage?.data?.attributes?.url
    ? props?.attributes?.MapImage?.data?.attributes?.url
    : "";

  const generateImageUrl = (
    url: string | undefined,
    maxWidths?: maxImgWidth,
  ) => {
    return !!url
      ? useGenerateResponsiveImageUrl(url, maxWidths, imgQuality, true)
      : undefined;
  };

  return (
    <>
      <BasicCardContainer
        title={cmsData?.Title}
        subTitle={cmsData?.SubTitle}
        showPaddingTop={cmsData?.ShowTopPadding}
        showPaddingBottom={cmsData?.ShowBottomPadding}
      >
        <LocationCard
          images={{
            imageUrl: generateImageUrl(imageUrl, defaultMaxWidths),
            smallImageUrl: generateImageUrl(imageUrl, cardImageWidths),
          }}
        >
          <RichContentArea>
            <div
              dangerouslySetInnerHTML={makeHtml(
                processRichText(cmsData?.RichText),
              )}
            />
          </RichContentArea>
        </LocationCard>
      </BasicCardContainer>
    </>
  );
};

const CabinTypeFeaturedMapComponentTemplate = (props) => {
  const content = props?.content?.cabinMaps?.data;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;

  return withContent(
    CabinTypeFeaturedMapComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
  );
};
export default CabinTypeFeaturedMapComponentTemplate;
