import React from "react";
import { Container } from "@mui/material";
import { FloorplanPanel } from "../../components/SemanticTheme/FloorplanPanel";
import { useGenerateResponsiveImageUrl } from "../../components/Utils";
import { defaultBookingWidths } from "../../utils";
import { withContent } from "../../utils/TemplateWithContent";
import { defaultMaxWidths } from "src/utils/common";
import { FloorPlanPanelEntity } from "src/graphql/generated-strapi/types";

type FloorPlanPanelContentComponent = {
  pageImgQuality?: number;
  additionalProps?: { [key: string]: string | boolean };
} & FloorPlanPanelEntity;

const FloorPlanPanelComponent: React.FC<FloorPlanPanelContentComponent> = (
  props: FloorPlanPanelContentComponent,
): JSX.Element => {
  const { attributes: content } = props;
  const title = content?.Title;
  const subTitle = content?.SubTitle;
  const floorPlanImageRef = content?.FloorPlanImageRef?.data?.attributes?.url;
  const imageThumbnailRef = content?.imageThumbnailRef?.data?.attributes?.url;
  const matterportUrl = content?.Matterport360Url;
  const maxWidth = content?.MaxWidth ?? undefined;
  const imgQuality = content?.ImageQuality || props?.pageImgQuality;

  const fullImageResp = useGenerateResponsiveImageUrl(
    floorPlanImageRef,
    defaultMaxWidths,
    imgQuality,
    true,
  );
  const imageUrl = fullImageResp ? fullImageResp : "";

  const imageThumbnailResp = useGenerateResponsiveImageUrl(
    imageThumbnailRef,
    defaultBookingWidths,
    imgQuality,
    true,
  );
  const imageThumbnail = imageThumbnailResp ? imageThumbnailResp : "";

  let imageAlt = "";

  if (floorPlanImageRef) {
    imageAlt = content?.FloorPlanImageRef?.data?.attributes?.alternativeText;
  }

  return (
    <Container>
      <FloorplanPanel
        maxWidth={maxWidth}
        {...{
          title,
          subTitle,
          imageUrl,
          imageAlt,
          matterportUrl,
          imageThumbnail,
        }}
      />
    </Container>
  );
};

const FloorPlanPanelComponentTemplate = (props) => {
  const content = props?.content?.floorPlanPanel?.data;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;

  return withContent(
    FloorPlanPanelComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
  );
};
export default FloorPlanPanelComponentTemplate;
