import * as React from "react";
import { PropsWithChildren } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

type BasicCardContainerStyleProps = {
  showPaddingTop?: boolean;
  showPaddingBottom?: boolean;
};

const useStyles = makeStyles((theme) => ({
  centre: {
    textAlign: "center",
  },
  title: {
    color: theme.palette.text.primary,
    lineHeight: "30px",
    marginBottom: theme.spacing(1),
  },
  titleLink: {
    textDecoration: "none",
  },
  subTitle: {
    marginBottom: theme.spacing(2),
  },
  padding: (props: BasicCardContainerStyleProps) => ({
    paddingTop: props?.showPaddingTop ? theme.spacing(2.5) : undefined,
    paddingBottom: props?.showPaddingBottom ? theme.spacing(2.5) : undefined,
    [theme.breakpoints.up("md")]: {
      paddingTop: props?.showPaddingTop ? theme.spacing(3.5) : undefined,
      paddingBottom: props?.showPaddingBottom ? theme.spacing(3.5) : undefined,
    },
  }),
}));

export type BasicCardContainerProps = {
  title?: string;
  subTitle?: string;
  showPaddingTop?: boolean;
  showPaddingBottom?: boolean;
};

export const BasicCardContainer: React.FC<
  PropsWithChildren<BasicCardContainerProps>
> = ({
  title,
  subTitle,
  children,
  showPaddingTop = false,
  showPaddingBottom = false,
}: PropsWithChildren<BasicCardContainerProps>) => {
  const classes = useStyles({ showPaddingTop, showPaddingBottom });

  return (
    <Container data-testid="root" className={classes.padding}>
      <Grid container>
        {title && (
          <Grid item xs={12} className={classes.centre}>
            <Typography
              variant="h2"
              className={classes.title}
              data-testid="title"
            >
              {title}
            </Typography>
          </Grid>
        )}
        {subTitle && (
          <Grid item xs={12} className={classes.centre}>
            <Typography
              variant="h4"
              className={classes.subTitle}
              data-testid="subTitle"
            >
              {subTitle}
            </Typography>
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          data-testid="content"
        >
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};
