import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren } from "react";
import { Link } from "@mui/material";
import classNames from "classnames";
import { FHNextImage, ImageLoader } from "../FHNextImage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    minWidth: 100,
    width: 120,
  },
  linkCard: {
    fontSize: 14,
    lineHeight: "16px",
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.text.primary,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:focus, &:active": {
      color: theme.palette.primary.main,
    },
    "& *": {
      display: "block",
      flexBasis: "100%",
      textAlign: "center",
    },
  },
  underlineNone: {
    textDecoration: "none",
    "&:hover, &:focus, &:active": {
      textDecoration: "none",
    },
  },
  linkImage: {
    textAlign: "center",
    width: 60,
    height: 60,
    flexBasis: "auto",
    "& >span": {
      "& >span": {
        "& >img": {
          position: "absolute",
          boxSizing: "border-box",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          padding: 0,
          border: "none",
          margin: "auto",
          display: "block",
          width: 0,
          height: 0,
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: "100%",
          maxHeight: "100%",
        },
        "& >span": {
          "& img": {
            display: "block",
            maxWidth: "100%",
            width: "initial",
            height: "initial",
            background: "none",
            opacity: 1,
            border: 0,
            margin: 0,
            padding: 0,
          },
        },
      },
    },
  },
}));

export type ImgProps = {
  url: string;
  altText: string;
  height?: number;
  width?: number;
  quality?: number;
};

type USPProps = {
  isImage?: boolean;
  title?: string;
  image?: ImgProps;
  linkUrl?: string;
  isBold?: boolean;
  underlineNone?: boolean;
  onLinkClick?: () => void;
  imageLoader?: ImageLoader;
};

export const USP: React.FC<PropsWithChildren<USPProps>> = ({
  title,
  image,
  linkUrl,
  isBold,
  isImage = true,
  underlineNone = false,
  onLinkClick,
  imageLoader,
}: PropsWithChildren<USPProps>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link
        href={linkUrl}
        color="primary"
        className={classNames(classes.linkCard, {
          [classes.underlineNone]: underlineNone,
        })}
        onClick={onLinkClick}
      >
        {isImage && image?.url && (
          <span className={classes.linkImage}>
            <FHNextImage
              src={image?.url}
              alt={image?.altText}
              width={image?.width}
              height={image?.height}
              quality={image?.quality}
              loader={imageLoader}
            />
          </span>
        )}
        <span>{isBold ? <strong>{title}</strong> : title}</span>
      </Link>
    </div>
  );
};
