import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Container, Slide } from "@mui/material";
import { FHButton } from "../FHButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.divider,
    border: "1px solid #00454026",
    boxShadow: "0px 3px 6px rgba(0,0,0,0.3)",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 3),
    },
  },
  stickyBar: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1001,
  },
  info: {
    flex: 1,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
    "& >strong": {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  },
  actions: {
    marginLeft: "auto",
    minWidth: "auto",
    flexBasis: "30px",
    height: "auto !important",
    padding: `${theme.spacing(0.6)} !important`,
    color: `${theme.palette.primary.dark} !important`,
    borderColor: theme.palette.primary.dark,
    "&:before": {
      borderColor: `${theme.palette.primary.dark} !important`,
    },
    "& svg": {
      fill: "currentColor",
      marginRight: 0,
    },
  },
}));

export type SnapBarProps = {
  title?: string;
  message: string;
  actionButton?: JSX.Element;
  show?: boolean;
  onCloseButtonClick: (showBar: boolean) => void;
};

export const SnapBar: React.FC<SnapBarProps> = ({
  title,
  message,
  actionButton,
  show = true,
  onCloseButtonClick,
}: PropsWithChildren<SnapBarProps>) => {
  const classes = useStyles();

  return (
    <Slide
      direction="left"
      in={show}
      timeout={500}
      data-testid="snapBar-testid"
    >
      <div className={classes.stickyBar}>
        <Container maxWidth="lg">
          <div className={classes.root}>
            <Typography className={classes.info}>
              {title && <strong>{title}</strong>}{" "}
              {message && <span>{message}</span>}
            </Typography>
            <FHButton
              fullWidth={false}
              fhStyle="secondary"
              className={classes.actions}
              onClick={() => {
                onCloseButtonClick(show);
              }}
            >
              {actionButton}
            </FHButton>
          </div>
        </Container>
      </div>
    </Slide>
  );
};
