import React from "react";
import { withContent } from "../../utils/TemplateWithContent";
import { Video } from "../../components/SemanticTheme/Video";
import { VideoUrlEntity } from "src/graphql/generated-strapi/types";

const VideoUrlComponent: React.FC<VideoUrlEntity> = (props): JSX.Element => {
  const { attributes: content } = props;
  const videoUrl = content?.VideoUrl;
  return <Video videoUrl={videoUrl ?? ""} />;
};

const VideoUrlComponentTemplate = (props) => {
  const content = props?.content?.videoUrl?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(VideoUrlComponent, content, undefined, pageImgQuality);
};
export default VideoUrlComponentTemplate;
