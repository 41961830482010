import React, { PropsWithChildren } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { FHButton } from "../FHButton";
import { SlidingContentCards } from "../SlidingContentCard/index";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  centre: {
    textAlign: "center",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
    lineHeight: "30px",
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: 36,
  },
  subTitle: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 3),
    },
  },
  contentCardRoot: {
    width: "100%",
  },
  contentCardRootExpanded: {
    display: "none",
  },
}));

export type RichContentCardContainerProps = {
  title?: string;
  subTitle?: string;
  ctaText?: string;
  ctaURL?: string;
  expanded?: boolean;
  onClick?: () => void;
} & RichContentCardContainerStyleProps;

type RichContentCardContainerStyleProps = {
  maxWidth?: string | number;
};

export const RichContentCardContainer: React.FC<
  PropsWithChildren<RichContentCardContainerProps>
> = ({
  title,
  subTitle,
  ctaText,
  ctaURL,
  expanded = true,
  onClick = () => {},
  children,
  maxWidth,
}: PropsWithChildren<RichContentCardContainerProps>) => {
  const classes = useStyles();

  return (
    <Grid container data-testid="root">
      <Grid item xs={12} className={classes.centre}>
        {title && (
          <Typography
            variant="h2"
            className={classes.title}
            data-testid="title"
            onClick={onClick}
          >
            {title}
            {expanded ? (
              <ExpandLess
                color="secondary"
                data-testid="chevronless"
                className={classes.icon}
              />
            ) : (
              <ExpandMore
                color="secondary"
                data-testid="chevronmore"
                className={classes.icon}
              />
            )}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} className={classes.centre}>
        {subTitle && (
          <Typography
            variant="h4"
            className={classes.subTitle}
            data-testid="subTitle"
            onClick={onClick}
          >
            {subTitle}
          </Typography>
        )}
      </Grid>
      <div
        className={classNames(classes.contentCardRoot, {
          [classes.contentCardRootExpanded]: !expanded,
        })}
      >
        <Grid item xs={12}>
          {children && (
            <SlidingContentCards {...{ maxWidth }}>
              {children}
            </SlidingContentCards>
          )}
        </Grid>

        {ctaText && ctaURL && (
          <Grid
            item
            xs={12}
            className={`${classes.centre} ${classes.buttonContainer}`}
          >
            <FHButton
              fhStyle="secondary"
              data-testid="button"
              href={ctaURL}
              fullWidth={false}
            >
              {ctaText}
            </FHButton>
          </Grid>
        )}
      </div>
    </Grid>
  );
};
