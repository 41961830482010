import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { Card, CardHeader } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  closeButton: {
    color: theme.palette.grey[600],
    borderColor: "#E0E0E0",
    borderWidth: 2,
    padding: theme.spacing(0.2),
    minWidth: "auto",
    margin: theme.spacing(0, 0, 0, 1),
    "&:hover": {
      color: theme.palette.grey[600],
      borderColor: "#E0E0E0",
      borderWidth: 2,
    },
  },
  subtitle: {
    fontSize: "11px",
    lineHeight: "35px",
  },
  actions: {
    marginTop: theme.spacing(0.8),
  },
  cardContent: (props: WrapperStyleProps) => ({
    overflowY: "auto",
    ...(props.noSpacing && { padding: 0, overflowX: "hidden" }),
  }),
  cardActions: (props: WrapperStyleProps) => ({
    alignItems: "flex-end",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "auto",
    borderTop: `1px solid ${theme.palette.divider}`,
    "& > button:not(:last-child)": {
      margin: theme.spacing(0, 0, 1, 0),
    },
    ...(props.noSpacing && { padding: 0 }),
  }),
  cardActionsRoot: (props: WrapperStyleProps) => ({
    ...(props.displayCardActionsAsBlock && {
      "& .MuiCardActions-root": {
        display: "block",
      },
    }),
  }),
}));

export type WrapperStyleProps = {
  noSpacing?: boolean;
  displayCardActionsAsBlock?: boolean;
};

export type WrapperProps = {
  children?: React.ReactNode;
  title: React.ReactNode;
  onClose: (event: React.SyntheticEvent<{}, Event>) => void;
  actions?: React.ReactNode;
  showHeader?: boolean;
  subtitle?: string;
  closeIcon?: JSX.Element;
  disabled?: boolean;
} & WrapperStyleProps;

export const Wrapper: React.FC<WrapperProps> = ({
  children,
  title,
  onClose,
  actions,
  showHeader = true,
  subtitle,
  noSpacing,
  displayCardActionsAsBlock = false,
  closeIcon,
  disabled = false,
}) => {
  const classes = useStyles({
    noSpacing,
    displayCardActionsAsBlock,
  });
  return (
    <Card className={`MuiCard-root ${classes.cardRoot}`}>
      {showHeader && (
        <CardHeader
          className={`MuiCardHeader-root`}
          action={
            <Grid container alignItems="center" className={classes.actions}>
              {!!subtitle && (
                <Typography className={classes.subtitle}>
                  <strong>{subtitle}</strong>
                </Typography>
              )}
              <Grid item>
                <Button
                  variant="outlined"
                  data-testid="stepper-down"
                  className={classes.closeButton}
                  onClick={onClose}
                  disabled={disabled}
                >
                  {closeIcon ? closeIcon : <CloseIcon />}
                </Button>
              </Grid>
            </Grid>
          }
          title={title}
        />
      )}
      <CardContent className={classes.cardContent}>{children}</CardContent>
      {actions && (
        <div className={classes.cardActionsRoot}>
          <CardActions className={classes.cardActions}>{actions}</CardActions>
        </div>
      )}
    </Card>
  );
};
