import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import StandardCardComponent from "./StandardCardTemplate";
import { RichContentCardContainer } from "../../components/SemanticTheme/RichContentCard";
import { withContent } from "../../utils/TemplateWithContent";
import { CollapsibleCardContainerEntity } from "src/graphql/generated-strapi/types";
import { updateAbsoluteLink } from "@components/Utils";

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme) => ({
    container: {
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0,
      ),
      "@media (max-width:600px)": {
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0,
        ),
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  }))();

type CollapsibleCardContainerComponent = {
  pageImgQuality?: number;
  additionalProps: { [key: string]: string | boolean };
} & CollapsibleCardContainerEntity;

const CollapsibleCardContainerComponent: React.FC<
  CollapsibleCardContainerComponent
> = (props: CollapsibleCardContainerComponent): JSX.Element => {
  const showPaddingTop = (props?.attributes?.showPaddingTop ||
    false) as boolean;
  const showPaddingBottom = (props?.attributes?.showPaddingBottom ||
    false) as boolean;
  const classes = useStyles(showPaddingTop, showPaddingBottom);
  const [expanded, toggleExpanded] = useState<boolean>(
    props?.attributes?.ExpandedByDefault as boolean,
  );

  const toggle = () => {
    toggleExpanded(!expanded);
  };

  const locationPinOnLandingPageCards =
    !!props?.additionalProps?.locationPinOnLandingPageCards;

  return (
    <Container className={classes.container}>
      <RichContentCardContainer
        title={props?.attributes?.Title}
        subTitle={props?.attributes?.SubTitle}
        ctaText={props?.attributes?.PrimaryCallToActionText}
        ctaURL={updateAbsoluteLink(
          props?.attributes?.PrimaryCallToActionRef?.data?.attributes
            ?.PageRoute,
        )}
        expanded={expanded}
        onClick={toggle}
      >
        {props?.attributes?.Cards?.data.map((cards, i) => {
          return (
            <React.Fragment key={`standard-card-${i}`}>
              <StandardCardComponent
                {...cards?.attributes}
                pageImgQuality={props?.pageImgQuality}
                containerImgQuality={props?.attributes?.ImageQuality}
                locationPinOnLandingPageCards={locationPinOnLandingPageCards}
              />{" "}
            </React.Fragment>
          );
        })}
      </RichContentCardContainer>
    </Container>
  );
};

const CollapsibleCardContainerTemplate = (props) => {
  const content = props?.content?.collapsibleCards?.data;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;
  return withContent(
    CollapsibleCardContainerComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
  );
};
export default CollapsibleCardContainerTemplate;
