import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  IconButton,
  Fade,
  Skeleton,
} from "@mui/material";
import Pause from "@mui/icons-material/Pause";
import Play from "@mui/icons-material/PlayArrow";

import { PreLoadImage } from "../PreLoadImage";
import { FHButton } from "../FHButton";
import { useIsClient, useOnScreen } from "../../../hooks/hooks";
import { FHNextImage, ImageLoader } from "../FHNextImage";

const useStyles = (
  border: boolean,
  align: Alignment,
  videoPaused: boolean,
  videoPausedImageUrl?: string
) =>
  makeStyles((theme) => ({
    title: {
      textAlign: "center",
      paddingTop: theme.spacing(3),
    },
    subtitle: {
      textAlign: "center",
      paddingBottom: theme.spacing(3),
    },
    button: {
      textAlign:
        align === "center" ? "center" : align === "left" ? "right" : "left",
      bottom: align !== "center" ? 0 : undefined,
      right: align === "left" ? 0 : undefined,
      position: align !== "center" ? "absolute" : undefined,
    },
    children: {
      paddingBottom: align === "center" ? theme.spacing(3) : theme.spacing(10),
      [theme.breakpoints.up("md")]: {
        paddingLeft: align === "center" ? theme.spacing(10) : undefined,
        paddingRight: align === "center" ? theme.spacing(10) : undefined,
      },
    },
    container: {
      padding: theme.spacing(3),
      borderRadius: border ? theme.shape.borderRadius : undefined,
      boxShadow: border ? "0px 1px 7px rgba(0,0,0,0.2)" : undefined,
    },
    innerContainer: {
      position: "relative",
      paddingLeft: align === "left" ? theme.spacing(3) : undefined,
      paddingRight: align === "right" ? theme.spacing(3) : undefined,
    },
    externalVideoContainer: {
      position: "relative",
      paddingBottom: "56.25%",
      paddingTop: 25,
      height: 0,
    },
    externalVideo: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    mediaContainer: {
      paddingBottom: align === "center" ? theme.spacing(3) : undefined,
    },
    internalMediaContainer: {
      position: "relative",
    },
    internalMedia: {
      width: "100%",
    },
    playPauseButton: {
      border: `1px solid ${theme.palette.background.paper}`,
      padding: theme.spacing(0.5),
    },
    videoOverlay: {
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundImage:
        videoPausedImageUrl && videoPaused
          ? `url(${videoPausedImageUrl})`
          : undefined,
      backgroundSize: videoPausedImageUrl && videoPaused ? "cover" : undefined,
    },
    imageContent: {
      position: "relative",
    },
    image: {
      width: "100%",
      paddingTop: "56.25%", // 16:9
      position: "relative",
    },
  }))();

export type Alignment = "left" | "right" | "center";

export type MediaBlockProps = {
  align?: Alignment;
  title?: string;
  subtitle?: string;
  videoUrl?: string;
  videoPausedImageUrl?: string;
  imageUrl?: string;
  youtubeUrl?: string;
  autoplay: boolean;
  ctaUrl?: string;
  ctaLabel?: string;
  border: boolean;
  imageLoader?: ImageLoader;
};

export const MediaBlock: React.FC<PropsWithChildren<MediaBlockProps>> = ({
  align = "center",
  title,
  subtitle,
  videoUrl,
  videoPausedImageUrl,
  imageUrl,
  youtubeUrl,
  autoplay,
  ctaUrl,
  ctaLabel,
  border,
  children,
  imageLoader,
}: PropsWithChildren<MediaBlockProps>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (isMobile) {
    align = "center";
  }
  const [videoPaused, toggleVideoPaused] = useState<boolean>(!autoplay);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [youtubeVideoUrl, setYoutubeVideoUrl] = React.useState(youtubeUrl);
  const classes = useStyles(border, align, videoPaused, videoPausedImageUrl);
  const video = useRef<HTMLVideoElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const isMP4Visibile = useOnScreen(video);
  const isYTVisibile = useOnScreen(iframeRef);
  const isClient = useIsClient();

  if (youtubeUrl && youtubeUrl.length > 0) {
    videoUrl = undefined;
    imageUrl = undefined;
  } else if (videoUrl) {
    imageUrl = undefined;
  }

  useEffect(() => {
    if (isYTVisibile && autoplay && youtubeVideoUrl) {
      if (youtubeVideoUrl?.includes("?autoplay=0")) {
        setYoutubeVideoUrl(
          youtubeVideoUrl.replace("?autoplay=0", "?autoplay=1")
        );
      }
    } else {
      setYoutubeVideoUrl(
        youtubeVideoUrl?.replace("?autoplay=1", "?autoplay=0")
      );
    }
  }, [isYTVisibile]);

  useEffect(() => {
    if (isMP4Visibile && autoplay && !videoPaused) {
      video.current?.play();
      toggleVideoPaused(false);
    } else {
      video.current?.pause();
    }
  }, [isMP4Visibile]);

  return (
    <>
      {(title || subtitle) && (
        <Grid item xs={12}>
          {title && (
            <Typography variant="h2" className={classes.title}>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant="h4" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Grid>
      )}
      <Grid
        container
        className={classes.container}
        direction={align === "right" ? "row-reverse" : "row"}
      >
        <Grid
          item
          xs={12}
          md={align === "center" ? 12 : 6}
          className={classes.mediaContainer}
        >
          {youtubeUrl && youtubeUrl.length > 1 && (
            <div className={classes.externalVideoContainer}>
              <iframe
                ref={iframeRef}
                width="560"
                height="315"
                src={youtubeVideoUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className={classes.externalVideo}
                loading="lazy"
              />
            </div>
          )}
          {videoUrl && (
            <div className={classes.internalMediaContainer}>
              <video
                src={videoUrl}
                className={classes.internalMedia}
                ref={video}
                autoPlay={autoplay && isClient}
                playsInline={autoplay}
                data-testid="mediaBlockVideo"
                poster={videoPausedImageUrl ? videoPausedImageUrl : undefined}
              />
              <div className={classes.videoOverlay}>
                <IconButton
                  className={classes.playPauseButton}
                  onClick={() => {
                    if (videoPaused) {
                      video.current?.play();
                      toggleVideoPaused(false);
                    } else {
                      video.current?.pause();
                      toggleVideoPaused(true);
                    }
                  }}
                >
                  {videoPaused ? <Play /> : <Pause />}
                </IconButton>
              </div>
            </div>
          )}
          {imageUrl && (
            <>
              <div className={classes.imageContent}>
                <PreLoadImage
                  url={imageUrl}
                  onLoad={() => setImageLoaded(true)}
                  loaderOverlay={
                    <Skeleton
                      className={classes.image}
                      animation="wave"
                      variant="rectangular"
                    />
                  }
                />
                {imageLoaded && (
                  <Fade timeout={500} in={imageLoaded}>
                    <div className={classes.image}>
                      <FHNextImage
                        src={imageUrl}
                        alt={"title"}
                        layout="fill"
                        objectFit="cover"
                        loading="lazy"
                        loader={imageLoader}
                      />
                    </div>
                  </Fade>
                )}
              </div>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={align === "center" ? 12 : 6}
          className={classes.innerContainer}
        >
          {children && (
            <Grid item className={classes.children}>
              {children}
            </Grid>
          )}
          {ctaUrl && ctaLabel && (
            <Grid item xs={12} className={classes.button}>
              <FHButton fhStyle="secondary" fullWidth={false} href={ctaUrl}>
                {ctaLabel}
              </FHButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
