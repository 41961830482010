import React from "react";
import { withContent } from "../../utils/TemplateWithContent";
import { ImageBanner } from "@components/SemanticTheme/ImageBanner";
import { Container } from "@mui/material";
import {
  defaultMaxWidths,
  defaultTileWidths,
  makeHtml,
  maxImgWidth,
  addLazyLoadingToImagesInRichText,
  useGenerateResponsiveImageUrl,
} from "@components/Utils";
import { RichContentArea } from "@components/SemanticTheme/RichContentArea";
import { useIsClient, useIsMobile } from "src/hooks/hooks";
import { FHInlineSvg } from "@components/SemanticTheme/FHInlineSvg";
import { ImageBannerEntity } from "src/graphql/generated-strapi/types";

export enum ImgBanner {
  IMAGEINCONTENT = "##ImageInContent##",
}

type CMSData = ReturnType<typeof makeCmsData>;
const makeCmsData = (content: any) => ({
  bannerImage: content?.["BannerImage"]?.data?.attributes?.url as string,
  mobileBannerImage: content?.["MobileBannerImage"]?.data?.attributes
    ?.url as string,
  mobileViewAlignment:
    content?.["MobileViewAlignment"]?.toLowerCase() ?? undefined,
  bannerColor: content?.["BannerColor"]?.data?.attributes?.ColorCode as string,
  richContent: content?.["BannerText"] as string,
  richTextColor: content?.["BannerTextColor"]?.data?.attributes
    ?.ColorCode as string,
  overlayOpacity: content?.["Opacity"] as number,
  textAlignment: content?.["BannerTextAlignment"]?.toLowerCase() ?? undefined,
  ctaButtonLink: content?.["CTAButtonLink"] as string,
  ctaButtonLabel: content?.["CTAButtonText"] as string,
  buttonStyle: content?.["CTAButtonStyle"]?.toLowerCase() ?? undefined,
  ctaLink: content?.["LinkTextURL"] as string,
  ctaLinkLabel: content?.["LinkText"] as string,
  ctaLinkStyle: content?.["LinkTextStyle"]?.toLowerCase() ?? undefined,
  chipLabel: content?.["TradingChip"]?.data?.attributes?.Text as string,
  chipTextColour: content?.["TradingChip"]?.data?.attributes?.TextColor?.data
    ?.attributes?.ColorCode as string,
  chipColor: content?.["TradingChip"]?.data?.attributes?.Color?.data?.attributes
    ?.ColorCode as string,
  chipBackground:
    content?.["TradingChip"]?.data?.attributes?.BackgroundColor ?? undefined,
  ctaUnderlineNone: content?.["LinkTextDecoration"] ?? false,
  ctaLinkIcon: content?.["LinkTextIcon"]?.data?.attributes?.url as string,
  ctaLinkAlignment: content?.["LinkTextAlignment"]?.toLowerCase() ?? undefined,
  logo: content?.["ImageInContent"]?.data?.attributes?.url as string,
  showPaddingTop: content?.["showPaddingTop"] ?? false,
  showPaddingBottom: content?.["showPaddingBottom"] ?? false,
});

type ImageBannerComponent = {
  pageImgQuality?: number;
} & ImageBannerEntity;

const ImageBannerComponent: React.FC<ImageBannerComponent> = (
  props: ImageBannerComponent,
) => {
  const { attributes: content, pageImgQuality } = props;
  const cmsData: CMSData = makeCmsData(content);
  const imgQuality = content?.ImageQuality || pageImgQuality;
  const isMobile = useIsMobile();
  const isClient = useIsClient();

  const generateImgUrl = (url: string | undefined, maxWidths?: maxImgWidth) => {
    if (!!url) {
      return isMobile
        ? useGenerateResponsiveImageUrl(
            url,
            defaultTileWidths,
            imgQuality,
            true,
          )
        : useGenerateResponsiveImageUrl(url, maxWidths, imgQuality, true);
    }
  };

  const parsedText = (text?: string) => {
    return text ? (
      <RichContentArea>
        <div
          dangerouslySetInnerHTML={makeHtml(
            addLazyLoadingToImagesInRichText(text),
          )}
        />
      </RichContentArea>
    ) : undefined;
  };

  const getSvgIcon = (url?: string) => {
    return url ? <FHInlineSvg src={url} size={"24px"} /> : undefined;
  };

  return (
    <Container>
      <ImageBanner
        bannerImage={
          isMobile
            ? generateImgUrl(cmsData?.mobileBannerImage)
            : generateImgUrl(cmsData?.bannerImage, defaultMaxWidths)
        }
        bannerColor={cmsData?.bannerColor}
        richContent={parsedText(
          cmsData?.richContent?.replace(
            ImgBanner?.IMAGEINCONTENT,
            isMobile
              ? ""
              : `<img style="width: 61px;height: 103px;" src=${cmsData?.logo} alt="" />`,
          ),
        )}
        richTextColor={cmsData?.richTextColor}
        overlayOpacity={cmsData?.overlayOpacity}
        textAlignment={
          isMobile ? cmsData?.mobileViewAlignment : cmsData?.textAlignment
        }
        ctaButtonLink={cmsData?.ctaButtonLink}
        ctaButtonLabel={cmsData?.ctaButtonLabel}
        buttonStyle={cmsData?.buttonStyle}
        ctaLink={isClient ? cmsData?.ctaLink : undefined}
        ctaLinkLabel={cmsData?.ctaLinkLabel}
        ctaLinkStyle={cmsData?.ctaLinkStyle}
        showChip={!!cmsData?.chipLabel}
        chipLabel={cmsData?.chipLabel}
        chipTextColour={cmsData?.chipTextColour}
        chipColor={cmsData?.chipColor}
        chipBackground={cmsData?.chipBackground}
        logo={
          cmsData?.richContent?.includes(ImgBanner?.IMAGEINCONTENT)
            ? (isMobile && cmsData?.logo) || undefined
            : cmsData?.logo
        }
        isImageAsPlaceholder={cmsData?.richContent?.includes(
          ImgBanner?.IMAGEINCONTENT,
        )}
        ctaUnderlineNone={!cmsData?.ctaUnderlineNone}
        ctaLinkAlignment={cmsData?.ctaLinkAlignment}
        ctaLinkIcon={getSvgIcon(cmsData?.ctaLinkIcon)}
        showPaddingTop={cmsData?.showPaddingTop}
        showPaddingBottom={cmsData?.showPaddingBottom}
      />
    </Container>
  );
};

const ImageBannerComponentTemplate = (props) => {
  const content = props?.content?.imageBanners?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(ImageBannerComponent, content, undefined, pageImgQuality);
};
export default ImageBannerComponentTemplate;
