import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { withContent } from "../../utils/TemplateWithContent";
import { Container, Grid } from "@mui/material";
import { useQueryString } from "../../utils";
import { filterXSS } from "xss";
import {
  TabbedNav,
  TabbedNavLink,
} from "../../components/SemanticTheme/TabbedNav";
import ContentArea from "../../utils/ContentArea";
import { MappedTemplateExperimentType } from "src/types/Content";
import { TwinTabbedComponent } from "src/graphql/generated-strapi/types";

const useStyles = makeStyles((theme) => ({
  tabPadding: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5, 0),
    },
    "& >div": {
      padding: 0,
      margin: 0,
    },
  },
  containerPadding: {
    padding: theme.spacing(7, 3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 2),
    },
  },
}));

enum DefaultTab {
  tab1 = "tab_1",
  tab2 = "tab_2",
}

const TwinTabbedComponent = (
  props: TwinTabbedComponent & MappedTemplateExperimentType
): JSX.Element => {
  const classes = useStyles();
  const queryStringData = useQueryString();
  const activeTab = filterXSS(queryStringData?.get("activeTab"));
  const defaultTab: string = (!!activeTab ? activeTab : props?.DefaultTab || 1)
    .toString()
    .toLowerCase();

  const handleTabChange = (index: number) => {
    setTabs(
      tabs.map((tab: TabbedNavLink, i: number) =>
        index === i
          ? {
              ...tab,
              active: true,
            }
          : {
              ...tab,
              active: false,
            }
      )
    );
  };

  // const tab1ContentType =
  //   props.content["tab1Content"].expandedValue &&
  //   props.content["tab1Content"].expandedValue.length > 0
  //     ? getContentTypeName(props.content["tab1Content"].expandedValue[0] as any)
  //     : undefined;
  // const tab2ContentType =
  //   props.content["tab2Content"].expandedValue &&
  //   props.content["tab2Content"].expandedValue.length > 0
  //     ? getContentTypeName(props.content["tab2Content"].expandedValue[0] as any)
  //     : undefined;
  // const selectedTab =
  //   tab1ContentType === "ManagePaymentCard"
  //     ? "tab1Content"
  //     : tab2ContentType === "ManagePaymentCard"
  //     ? "tab2Content"
  //     : "";

  // const manageCardCmsList =
  //   selectedTab &&
  //   props.content[selectedTab].expandedValue &&
  //   Array.isArray(props.content[selectedTab].expandedValue) &&
  //   props.content[selectedTab].expandedValue;
  // const manageCardCmsData = manageCardCmsList
  //   ? manageCardCmsList[0]
  //   : undefined;
  const [tabs, setTabs] = useState<TabbedNavLink[]>([
    {
      primaryLabel: props.Tab1Label,
      active: defaultTab === "1" || defaultTab === DefaultTab.tab1,
      onTabClick: () => handleTabChange(0),
    },
    {
      primaryLabel: props.Tab2Label,
      active: defaultTab === "2" || defaultTab === DefaultTab.tab2,
      onTabClick: () => handleTabChange(1),
    },
  ]);

  const getTab = (index: number) => {
    switch (index) {
      case 0:
        return (
          <Grid item xs={12} className={classes.tabPadding}>
            <ContentArea
              content={props?.Tab1Content}
              additionalProps={(props?.experiments as {}) || undefined}
            />
          </Grid>
        );
      case 1:
        return (
          <Grid item xs={12} className={classes.tabPadding}>
            <ContentArea
              content={props?.Tab2Content}
              additionalProps={(props?.experiments as {}) || undefined}
            />
          </Grid>
        );
    }
  };

  return (
    <Container className={classes.containerPadding}>
      <TabbedNav links={tabs} />
      {getTab(tabs.findIndex((tab: TabbedNavLink) => tab.active))}
    </Container>
  );
};

const TwinTabbedComponentTemplate = (props): JSX.Element => {
  const content =
    props?.content?.twinTab?.data?.[0]?.attributes ||
    props?.content?.twinTab?.data?.attributes;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.experiments || null;
  return withContent(
    TwinTabbedComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps
  );
};
export default TwinTabbedComponentTemplate;
