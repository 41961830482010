import React from "react";
import { CardInformationText } from "./CardInformationText";

export type LocationCardInformationTextProps = {
  key: string;
  Icon?: React.FC;
  line1: string;
  line2: string;
};

export const LocationCardInformationText: React.FC<LocationCardInformationTextProps> =
  ({ Icon, line1, line2, key }: LocationCardInformationTextProps) => {
    return (
      <div data-testid="location-card-info-root">
        <CardInformationText Icon={Icon} key={key}>
          <p>{line1}</p>
          <p>{line2}</p>
        </CardInformationText>
      </div>
    );
  };
