import React from "react";
import { makeHtml } from "../../utils/common";
import { withContent } from "../../utils/TemplateWithContent";
import { makeStyles } from "@mui/styles";
import { useIsMobile } from "../../hooks/hooks";
import { DescriptionCard } from "../../components/SemanticTheme/DescriptionCard";
import { FHInlineSvg } from "../../components/SemanticTheme/FHInlineSvg";
import { TitleVariant } from "../../components/SemanticTheme/Enums";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { Container } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import FHButton from "../../components/SemanticTheme/FHButton";
import { makeContentItemAbTestIdAttribute } from "../../utils/AbTestIdAndPaddingContainer";
import { WhatsIncludedEntity } from "src/graphql/generated-strapi/types";
import { processRichText, updateAbsoluteLink } from "@components/Utils";

const linePseudoElStyles = (color: string) => ({
  flex: `1 1`,
  content: `""`,
  borderBottom: `2px solid ${color}`,
  maxWidth: `50px`,
});

const useStyles = (lineColor?: string) =>
  makeStyles((theme) => ({
    iconWrapper: {
      color: theme.palette.text.primary,
      display: "flex",
      padding: theme.spacing(3, 3),

      "&:before": {
        ...linePseudoElStyles(lineColor ? lineColor : ""),
        margin: "auto 10px auto auto",
      },
      "&:after": {
        ...linePseudoElStyles(lineColor ? lineColor : ""),
        margin: "auto auto auto 10px",
      },
    },
    descriptionCard: {
      "& >div": {
        "& >div": {
          [theme.breakpoints.down("sm")]: {
            margin: 0,
          },
        },
      },
    },
  }))();

const WhatsIncludedContainerComponent: React.FC<WhatsIncludedEntity> = (
  props: WhatsIncludedEntity,
): JSX.Element => {
  const isMobile = useIsMobile();

  const classes = useStyles(
    props?.attributes?.LineColour?.data?.attributes?.ColorName,
  );

  const columnSize = props?.attributes?.ColumnTwo === "" ? 5 : 4;

  // Is there a better way to get a single card?
  const descriptionCard =
    props?.attributes?.description_cards?.data !== null
      ? (props?.attributes?.description_cards?.data).map((contentItem, i) => {
          return (
            <DescriptionCard
              key={i}
              icon={
                contentItem?.attributes?.Icon?.data?.attributes?.url ? (
                  <FHInlineSvg
                    src={
                      contentItem?.attributes?.Icon?.data?.attributes
                        ?.url as string
                    }
                    size="24px"
                    color={
                      contentItem?.attributes?.IconColour?.data?.attributes
                        ?.ColorCode
                    }
                  />
                ) : undefined
              }
              title={contentItem?.attributes?.Title as string}
              titleVariant={
                (contentItem?.attributes
                  ?.TitleVariant as unknown as TitleVariant) || TitleVariant.h2
              }
              fullHeight={false}
              description={
                <RichContentArea>
                  <div
                    {...makeContentItemAbTestIdAttribute(contentItem)}
                    dangerouslySetInnerHTML={makeHtml(
                      processRichText(contentItem?.attributes?.Description),
                    )}
                  />
                </RichContentArea>
              }
              showPaddingTop={
                (contentItem?.attributes?.showPaddingTop || false) as boolean
              }
              showPaddingBottom={
                (contentItem?.attributes?.showPaddingBottom || false) as boolean
              }
            />
          );
        })
      : undefined;

  return (
    <Container>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography variant={"h2"}>{props?.attributes?.Title}</Typography>
        <Typography variant={"h4"}>{props?.attributes?.SubTitle}</Typography>
      </Grid>
      {props?.attributes?.Icon.data?.attributes?.url && (
        <Grid item xs={12}>
          <div className={classes.iconWrapper}>
            <FHInlineSvg
              src={props?.attributes?.Icon.data?.attributes?.url}
              size="24px"
            />
          </div>
        </Grid>
      )}
      <Grid container spacing={isMobile ? 0 : 4} justifyContent="center">
        {props?.attributes?.ColumnOne !== "" && (
          <Grid item xs={12} md={columnSize}>
            <RichContentArea>
              <div
                dangerouslySetInnerHTML={makeHtml(
                  processRichText(props?.attributes?.ColumnOne),
                )}
              />
            </RichContentArea>
          </Grid>
        )}
        {props?.attributes?.ColumnTwo !== "" && (
          <Grid item xs={12} md={columnSize}>
            <RichContentArea>
              <div
                dangerouslySetInnerHTML={makeHtml(
                  processRichText(props?.attributes?.ColumnTwo),
                )}
              />
            </RichContentArea>
          </Grid>
        )}
        {descriptionCard && (
          <Grid
            item
            xs={12}
            md={columnSize}
            className={classes.descriptionCard}
          >
            {descriptionCard}
          </Grid>
        )}
      </Grid>
      {props?.attributes?.primary_call_to_action_link &&
        props?.attributes?.PrimaryCallToActionText && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ margin: "20px 0" }}
          >
            <Grid item xs={12} md={6} lg={4}>
              <FHButton
                href={updateAbsoluteLink(
                  props?.attributes?.primary_call_to_action_link?.data
                    ?.attributes?.PageRoute,
                )}
                fhStyle={"secondary"}
                fullWidth
              >
                {props?.attributes?.PrimaryCallToActionText}
              </FHButton>
            </Grid>
          </Grid>
        )}
    </Container>
  );
};

const WhatsIncludedContainerComponentTemplate = (props) => {
  const content = props?.content?.whatIncluded?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    WhatsIncludedContainerComponent,
    content,
    undefined,
    pageImgQuality,
  );
};
export default WhatsIncludedContainerComponentTemplate;
