import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren } from "react";
import { Grid, Typography, Hidden } from "@mui/material";
import { FHButton } from "../FHButton";
import { FHInlineSvg } from "../FHInlineSvg/index";
import { BrochureCardProps, BrochureCard } from "./BrochureCard";
import { FHSelectDropdown, FHDropdownOption } from "../FHSelectDropdown/index";
import { isBrowser } from "../../Utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  coordinatesContainer: {
    marginTop: theme.spacing(4),
  },
  featureList: {
    paddingInlineStart: "1.1em",
  },
  ctaContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(2, 0, 0, 0),
    "& fieldset": {
      "& legend": {
        lineHeight: "11px",
      },
    },
  },
  dropdownLabel: {
    width: "100%",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  activities: {
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  activityContainer: {
    display: "flex",
    flexDirection: "column",
  },
  activity: {
    display: "inline-block",
    "& svg": {
      color: theme.palette.text.primary,
    },
  },
  mobileContent: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  displayContent: {
    height: "100%",
  },
  specificationContainer: {
    backgroundColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
  },
  specification: {
    display: "flex",
    justifyContent: "space-around",
  },
  item: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0),
    },
  },
}));

export type CabinBrochureCardProps = {
  title: string;
  subtitle: string;
  activities?: { label: string; svgIconUrl: string }[];
  description?: string;
  features: (string | JSX.Element)[];
  ctaText?: string;
  specifications?: { label: string; svgIconUrl: string }[];
  onCtaClick?: (location?: string) => void;
  ctaLocationOptions?: FHDropdownOption<unknown>[];
  dropdownLabel?: string;
  children?: any;
} & BrochureCardProps;

export const CabinBrochureCard: React.FC<CabinBrochureCardProps> = ({
  imageGalleryCarouselProps,
  title,
  subtitle,
  activities,
  maxWidth,
  centered,
  children,
  description,
  features,
  ctaText,
  onCtaClick,
  specifications,
  ctaLocationOptions,
  dropdownLabel,
}: PropsWithChildren<CabinBrochureCardProps>) => {
  const classes = useStyles();
  const [browserMode, setBrowserMode] = React.useState(false);

  React.useEffect(() => {
    setBrowserMode(isBrowser());
  }, []);

  const renderDisplayContent = () => (
    <Grid container className={classes.displayContent}>
      <Grid item xs={12} className={classes.item}>
        <Grid
          container
          justifyContent="space-around"
          className={classes.specificationContainer}
          spacing={1}
        >
          {specifications &&
            specifications.map((spec, i) => (
              <Grid item key={`cabin-icon-${i}`}>
                <div>
                  <div className={classes.specification}>
                    <FHInlineSvg src={spec.svgIconUrl} size="40px" />
                  </div>
                  <Typography variant="body1" component="span">
                    {spec.label}
                  </Typography>
                </div>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.item}>
        <Grid
          container
          className={classes.activities}
          justifyContent="center"
          spacing={2}
        >
          {activities &&
            activities.map((activity, i) => (
              <Grid
                item
                key={`${activity.label}__${i}`}
                className={classes.activityContainer}
              >
                <div className={classes.activity}>
                  <FHInlineSvg src={activity.svgIconUrl} size="40px" />
                </div>
                <Typography variant="body1" component="span">
                  {activity.label}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.ctaContainer}>
        <>
          {ctaText && !ctaLocationOptions && (
            <FHButton
              fhStyle="secondary"
              onClick={() => onCtaClick && onCtaClick()}
            >
              {ctaText}
            </FHButton>
          )}
          {dropdownLabel && (
            <Typography className={classes.dropdownLabel}>
              {dropdownLabel}
            </Typography>
          )}

          {!browserMode && (
            <>
              {ctaLocationOptions &&
                ctaLocationOptions.map((locationDetails, i: number) => {
                  return (
                    <a
                      href={locationDetails.value}
                      key={`${locationDetails.value}__${i}`}
                    >
                      {locationDetails.label}
                    </a>
                  );
                })}
            </>
          )}

          {ctaLocationOptions && browserMode && (
            <FHSelectDropdown
              id="location-select"
              selectPlaceholder={ctaText}
              selectPlaceholderValue="unselected"
              fhStyle="secondary"
              defaultValue="unselected"
              options={ctaLocationOptions}
              centered
              customOnChange={(value) => {
                if (value !== "unselected") {
                  onCtaClick && onCtaClick(value);
                }
              }}
            />
          )}
        </>
      </Grid>
    </Grid>
  );

  return (
    <BrochureCard
      {...{ imageGalleryCarouselProps, maxWidth, centered }}
      className={classes.root}
    >
      <Grid item xs={12} md={6}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="subtitle1">{subtitle}</Typography>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={12} className={classes.mobileContent}>
              {renderDisplayContent()}
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            {children ? (
              children
            ) : (
              <Typography variant="body1">{description}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="div">
              <ul className={classes.featureList}>
                {features &&
                  features.map((feature, i) => (
                    <li key={`feature-${i}`}>{feature}</li>
                  ))}
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {browserMode ? (
        <Hidden mdDown>
          <Grid item xs={12} md={6}>
            {renderDisplayContent()}
          </Grid>
        </Hidden>
      ) : (
        <Grid item xs={12} md={6}>
          {renderDisplayContent()}
        </Grid>
      )}
    </BrochureCard>
  );
};
