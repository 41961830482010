import React from "react";
import { Container } from "@mui/material";
import { NavigationContainer } from "../../components/SemanticTheme/NavigationContainer";
import { SlidingContentCards } from "../../components/SemanticTheme/SlidingContentCard";
import CabinCardComponent from "./CabinCardTemplate";
import { withContent } from "../../utils/TemplateWithContent";
import { CabinsCardContainerEntity } from "src/graphql/generated-strapi/types";
import { updateAbsoluteLink } from "@components/Utils";

type CabinsCardContainerProps = {
  pageImgQuality?: number;
  additionalProps: { [key: string]: string | boolean };
} & CabinsCardContainerEntity;

const CabinsCardContainerComponent: React.FC<CabinsCardContainerProps> = (
  props: CabinsCardContainerProps,
): JSX.Element => {
  const content = props?.attributes;
  const title = content?.Title as string;
  const subtitle = content?.SubTitle as string;
  const callToActionText =
    (content?.PrimaryCallToActionText as string) ?? undefined;
  const callToActionUrl = content?.PrimaryCallToActionRef?.data?.attributes
    ?.PageRoute
    ? updateAbsoluteLink(
        content?.PrimaryCallToActionRef?.data?.attributes?.PageRoute,
      )
    : undefined;

  return (
    <Container>
      <NavigationContainer
        ctaText={callToActionText}
        ctaURL={callToActionUrl}
        title={title}
        subTitle={subtitle}
        titleUrl={callToActionUrl}
      >
        <SlidingContentCards>
          {content?.cards?.data.map((cards, i) => {
            return (
              <CabinCardComponent
                {...cards?.attributes}
                key={i}
                pageImgQuality={props?.pageImgQuality}
                containerImgQuality={content?.ImageQuality}
                locationPinOnLandingPageCards={
                  !!props?.additionalProps?.locationPinOnLandingPageCards
                }
              />
            );
          })}
        </SlidingContentCards>
      </NavigationContainer>
    </Container>
  );
};

const CabinsCardContainerTemplate = (props) => {
  const content =
    props?.content?.cabinCardContainer?.data?.[0] ||
    props?.content?.cabinCardContainer?.data;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;

  return withContent(
    CabinsCardContainerComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
  );
};
export default CabinsCardContainerTemplate;
