import React from "react";
import { pickValuesAsString, pickValuesAsBoolean } from "../../utils/common";
import { AbTestIdAndPaddingContainer } from "../../utils/AbTestIdAndPaddingContainer";
import { withContent } from "../../utils/TemplateWithContent";
import { DynamicFeefoProductReviewComponent } from "./DynamicComponents";
import { FeefoProductReviewEntity } from "src/graphql/generated-strapi/types";

const makeCmsData = (content: any) => ({
  ...pickValuesAsString(content, ["ABTestId", "Sku"]),
  ...pickValuesAsBoolean(content, ["showPaddingBottom", "showPaddingTop"]),
});

export type FeefoProductReviewCmsData = ReturnType<typeof makeCmsData>;

export const FeefoProductReviewComponent: React.FC<FeefoProductReviewEntity> = (
  props: FeefoProductReviewEntity
): JSX.Element => {
  const cmsLabels = makeCmsData(props?.attributes);

  return (
    <>
      {!!cmsLabels.Sku && (
        <AbTestIdAndPaddingContainer
          showPaddingBottom={cmsLabels?.showPaddingBottom || false}
          showPaddingTop={cmsLabels?.showPaddingTop || false}
          abTestId={cmsLabels.ABTestId || ""}
        >
          <div
            id="feefo-product-review-widgetId"
            className="feefo-review-widget-product"
            data-product-sku={cmsLabels.Sku}
          ></div>
        </AbTestIdAndPaddingContainer>
      )}
    </>
  );
};

const FeefoProductReviewTemplate = (props) => {
  const content = props?.content?.feefoProduct?.data;
  const pageImgQuality = props?.pageImgQuality;
  return withContent(
    DynamicFeefoProductReviewComponent,
    content,
    undefined,
    pageImgQuality
  );
};

export default FeefoProductReviewTemplate;
