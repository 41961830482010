import React, { PropsWithChildren } from "react";
import { ContentCard, ContentCardProps } from "../SlidingContentCard";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { RichContentCardProps } from "../RichContentCard";

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme) => ({
    root: {
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0
      ),
      "@media (max-width:600px)": {
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0
        ),
      },
    },
  }))();

export type CallToActionCardProps = RichContentCardProps & {
  ctaUrl: string;
  ctaLabel: string;
  showPaddingTop?: boolean;
  showPaddingBottom?: boolean;
};

export const CallToActionCard: React.FC<
  PropsWithChildren<CallToActionCardProps>
> = ({
  ctaUrl,
  ctaLabel,
  imgUrl,
  title,
  children,
  showPaddingTop = false,
  showPaddingBottom = false,
}: PropsWithChildren<CallToActionCardProps>) => {
  const classes = useStyles(showPaddingTop, showPaddingBottom);
  const contentCardProps: ContentCardProps = {
    media: { imgUrl, title },
    callToAction: { label: ctaLabel, url: ctaUrl },
  };
  return (
    <div className={classes.root}>
      <ContentCard {...contentCardProps}>
        {title && (
          <Typography variant="h4" data-testid="cardtitle" gutterBottom>
            {title}
          </Typography>
        )}
        {children}
      </ContentCard>
    </div>
  );
};
