export * from "./DesktopModalCard";
export * from "./MobileDrawerCard";

import React from "react";
import { Hidden, HiddenProps } from "@mui/material";
import {
  DesktopModalCardProps,
  MobileDrawerCardProps,
  DesktopModalCard,
  MobileDrawerCard,
} from "./";

export type ModalDrawerCardProps = {
  hiddenDesktopModal?: HiddenProps;
  hiddenMobileDrawer?: HiddenProps;
} & DesktopModalCardProps &
  MobileDrawerCardProps;

export const ModalDrawerCard: React.FC<ModalDrawerCardProps> = (
  props: ModalDrawerCardProps
) => {
  return (
    <>
      <Hidden
        {...(!!props.hiddenDesktopModal
          ? props.hiddenDesktopModal
          : { smDown: true })}
      >
        {/* TODO: only assign breakpoints on initialization (should not change on resize) */}
        <DesktopModalCard {...props} />
      </Hidden>
      <Hidden
        {...(!!props.hiddenMobileDrawer
          ? props.hiddenMobileDrawer
          : { smUp: true })}
      >
        <MobileDrawerCard {...props} />
      </Hidden>
    </>
  );
};
