import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = (titleColour: string) =>
  makeStyles((theme) => ({
    root: {
      padding: 0,
    },
    title: {
      color: titleColour ? titleColour : theme.palette.primary.main,
    },
    actions: {
      marginTop: theme.spacing(2),
      '& button': {
        marginRight: theme.spacing(1.5),
      },
    },
    description: {
      marginTop: theme.spacing(1),
    },
  }))();

export type ContentContainerProps = {
  title?: string;
  titleColour?: string;
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  description?: JSX.Element | string;
  children?: JSX.Element | JSX.Element[];
};

export const ContentContainer: React.FC<ContentContainerProps> = ({
  title,
  titleColour = '#22A672',
  titleVariant = 'h2',
  description,
  children,
}: PropsWithChildren<ContentContainerProps>) => {
  const classes = useStyles(titleColour);

  return (
    
      <div className={classes.root}>
        {title && (
          <Typography className={classes.title} variant={titleVariant}>
            {title}
          </Typography>
        )}
        {description && (
          <div className={classes.description}>{description}</div>
        )}
        {children && <div className={classes.actions}>{children}</div>}
      </div>
    
  );
};
export default ContentContainer;
