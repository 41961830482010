import React, { PropsWithChildren } from "react";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme: Theme) => ({
    container: {
      textAlign: "center",
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0
      ),
      "@media (max-width:600px)": {
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0
        ),
      },
    },
  }))();

export const makeContentItemAbTestIdFromValue = (
  value?: string | null | undefined
) => {
  if (value) {
    return {
      "data-abtestid": value,
    };
  } else return {};
};

export const VWODataReference: React.FC<{
  id: string | null | undefined;
  children: any;
}> = ({ id, children }): JSX.Element => (
  <div {...makeContentItemAbTestIdFromValue(id)}>{children}</div>
);

export type AbTestIdAndPaddingProps = {
  showPaddingTop: boolean;
  showPaddingBottom: boolean;
  abTestId?: string;
  children?: JSX.Element;
};

export const AbTestIdAndPaddingContainer: React.FC<AbTestIdAndPaddingProps> = ({
  showPaddingTop,
  showPaddingBottom,
  abTestId,
  children,
}: PropsWithChildren<AbTestIdAndPaddingProps>): JSX.Element => {
  const classes = useStyles(showPaddingTop, showPaddingBottom);

  return (
    <Container>
      <div className={classes.container}>
        <VWODataReference id={abTestId || ""}>{children}</VWODataReference>
      </div>
    </Container>
  );
};

export const makeContentItemAbTestIdAttribute = (content) => {
  return makeContentItemAbTestIdFromValue(content?.attributes?.ABTestId);
};
