import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import {
  LocationCardInformationText,
  LocationCardInformationTextProps,
} from "./LocationCardInformationText";
import {
  CoordinatesCardInformationText,
  CoordinatesCardInformationTextProps,
} from "./CoordinatesCardInformationText";
import { CardInformationText } from "./CardInformationText";
import { Card, Grid, CardContent, Hidden, Skeleton } from "@mui/material";
import { ImageModalLink } from "../ImageModalLink";
import { PreLoadImage } from "../PreLoadImage";
import { FHNextImage } from "../FHNextImage";

const useStyles = makeStyles((theme) => ({
  root: (props: LocationCardStyleProps) => ({
    width: "100%",
    maxWidth: props.maxWidth,
    margin: props.centered ? "0 auto" : "",
  }),
  cardContent: {
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  imageContainer: {
    "& > img": {
      verticalAlign: "-webkit-baseline-middle",
    },
  },
  desktopImageContainer: {
    position: "relative",
    paddingTop: "100%",
    height: 0,
  },
  img: {
    borderRadius: theme.shape.borderRadius,
    height: "100%",
    width: "100%",
    top: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "absolute",
  },
  imgXs: {
    borderRadius: theme.shape.borderRadius,
  },
  infoContainer: {
    height: "100%",
  },
  coordinatesContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  skeletonContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: theme.shape.borderRadius,
  },
  preLoadImageContainer: {
    height: "100%",
  },
  imgHover: {
    cursor: "pointer",
  },
}));

type ImgProps = {
  imageUrl: string;
  smallImageUrl: string;
};

type LocationCardStyleProps = {
  maxWidth?: string | number;
  centered?: boolean;
};

export type LocationCardProps = {
  images: ImgProps;
  AddressIcon?: React.FC;
  Address?: React.FC;
  informationItems?: LocationCardInformationTextProps[];
  gpsItems?: CoordinatesCardInformationTextProps[];
} & LocationCardStyleProps;

export const LocationCard: React.FC<PropsWithChildren<LocationCardProps>> = ({
  images,
  AddressIcon,
  Address,
  informationItems,
  gpsItems,
  maxWidth,
  centered = true,
  children,
}: PropsWithChildren<LocationCardProps>) => {
  const classes = useStyles({ maxWidth, centered });
  const [clickedImagePosition, setClickedImagePosition] = React.useState<
    null | number
  >(null);

  return (
    <Card data-testid="location-card-root" className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12} md={4} className={classes.imageContainer}>
            <ImageModalLink
              imageUrl={images?.imageUrl}
              clickedImagePosition={clickedImagePosition}
              setOnClickImage={() => {
                setClickedImagePosition(null);
              }}
            >
              <Hidden smUp>
                <FHNextImage
                  loading="lazy"
                  src={images?.smallImageUrl || ""}
                  alt="Location"
                  width="370"
                  height="270"
                  className={classes.imgXs}
                />
              </Hidden>
              <Hidden smDown>
                <div className={classes.desktopImageContainer}>
                  <PreLoadImage
                    url={images?.smallImageUrl}
                    fadeIn
                    className={classes.preLoadImageContainer}
                    loaderOverlay={
                      <Skeleton
                        width="100%"
                        height="100%"
                        variant="rectangular"
                        className={classes.skeletonContainer}
                      />
                    }
                  >
                    <FHNextImage
                      loading="lazy"
                      src={images?.smallImageUrl || ""}
                      className={classes.img}
                      layout="fill"
                      alt="Location"
                    />
                  </PreLoadImage>
                </div>
              </Hidden>
            </ImageModalLink>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid
              container
              justifyContent="space-between"
              className={classes.infoContainer}
            >
              {children ? (
                children
              ) : (
                <>
                  {Address && (
                    <Grid item xs={12} md={6} data-testid="address">
                      <CardInformationText Icon={AddressIcon}>
                        <Address />
                      </CardInformationText>
                    </Grid>
                  )}

                  {informationItems && (
                    <Grid item xs={12} md={6}>
                      {informationItems.map((props, i) => (
                        <LocationCardInformationText key={i} {...props} />
                      ))}
                    </Grid>
                  )}
                  {gpsItems && (
                    <Grid item xs={12} className={classes.coordinatesContainer}>
                      {gpsItems.map((props, i) => (
                        <CoordinatesCardInformationText
                          key={i}
                          {...props}
                          spacing={0}
                        />
                      ))}
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
